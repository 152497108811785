"use client";

import {
  useClientType,
  useClientVersion,
  useFeedbackToast,
  useActionServerFunction,
  useActionCallingRequestSetter,
  useUserBusinessCode,
  useClientInstallId,
  useUserRealmId,
} from "@easybiz/component-shared";
import { useCallback, useState } from "react";

function useActionCallback(onClick, onComplete, succeed) {
  const toast = useFeedbackToast();
  const client = useClientType();
  const clientVersion = useClientVersion();
  const installId = useClientInstallId();
  const httpsFunction = useActionServerFunction();
  const realmId = useUserRealmId();
  const businessCode = useUserBusinessCode();
  const [request, setRequest] = useState();
  const setBlockingRequest = useActionCallingRequestSetter();
  const [response, setResponse] = useState();

  const onSubmit = useCallback(
    async (params, blocking) => {
      try {
        if (blocking !== false) {
          setResponse(null);
        }

        const args = onClick(params);

        if (typeof args === "string") {
          toast.info(args);
        } else if (blocking === null) {
          // Params check
          return true;
        } else if (Array.isArray(args)) {
          const [group, type, data] = args;

          setRequest(data || {});

          if (blocking) {
            setBlockingRequest(data || {});
          }

          const request = {
            group,
            type,
            data: data || {},
            client,
            clientVersion,
            installId,
            realmId,
            businessCode,
            ...(process.env.NODE_ENV === "development" && { isDevMode: true }),
          };

          httpsFunction(request)
            .then((response) => {
              setResponse(response.data || {});

              if (typeof succeed === "string") {
                toast.success(succeed);
              } else if (typeof succeed === "function") {
                succeed(data, response.data);
              } else if (typeof response.data?.success === "string") {
                toast.success(response.data.success);
              } else if (typeof response.data?.info === "string") {
                toast.info(response.data.info);
              }

              if (onComplete) {
                onComplete(true, response.data, { ...data, type });
              }
            })
            .catch((error) => {
              console.info(`%c[${type} failed]`, `color: red`, error.message, request, JSON.stringify(error.details));

              setResponse({
                error: {
                  ...error.details,
                  message: error.message,
                },
              });

              if (error.code === "functions/internal") {
                toast.error("Network error, please checkout your internet connection.");
              } else if (error.message) {
                toast?.error(error.message, error.details);
              }

              if (onComplete) {
                onComplete(false, { ...error.details, message: error.message }, data);
              }
            })
            .finally(() => {
              setRequest(null);

              if (blocking) {
                setBlockingRequest(null);
              }
            });
        }
      } catch (error) {
        toast.error(error.message, error.code);
      }
    },
    [client, installId, clientVersion, realmId, businessCode, onClick, onComplete]
  );

  const onClear = useCallback(() => setResponse(null), []);

  return {
    request,
    response,
    loading: Boolean(request),
    succeed: Boolean(response && !response.error),
    onSubmit: onClick ? onSubmit : null,
    onAdjust: (updates) => setResponse((response) => ({ ...response, ...updates })),
    onClear,
  };
}

export default useActionCallback;
