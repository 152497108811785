import { useActionCallback } from "@easybiz/hook";
import { useFeedbackToast } from "@easybiz/component-shared";
import { OPERATION_GROUP_ORDER, ORDER_ACTION_CANCEL } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";

function useOrderCancel(callback) {
  const toast = useFeedbackToast();
  const intl = useIntl();

  const onSubmit = useCallback(({ orderId, note }) => {
    if (!note) {
      return intl.formatMessage({
        id: "please.leave.a.note",
        defaultMessage: "Please leave a note",
      });
    }

    return [
      OPERATION_GROUP_ORDER,
      ORDER_ACTION_CANCEL,
      {
        note,
        orderId,
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback, (request, response) =>
    toast.success(
      intl.formatMessage(
        {
          id: "order.x.has.been.cancelled",
          defaultMessage: "Order {orderNo} has been cancelled",
        },
        { orderNo: response.orderNo }
      )
    )
  );
}

export default useOrderCancel;
