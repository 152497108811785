import { CarOutlined, CloseOutlined, ShopOutlined } from "@ant-design/icons";
import { useClientState, useFeedbackToast } from "@easybiz/component-shared";
import { useRealmToDisplayName } from "@easybiz/component-shared";
import { BUSINESS_SECTOR_LAUNDRY } from "@easybiz/utils";
import { CollectionDatesCalendar, CollectionPlacesList, IconButton, JobSchedulePanel } from "@easybiz/web-admin";
import { Card, Segmented } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

const MODE_COLLECTION = "collection";
const MODE_DELIVERY = "delivery";

export default function ({
  service,
  businessCode,
  customer,
  address,
  returnSchedule,
  collectionPlace,
  onClose,
  onUpdate,
}) {
  const { collectionOptions } = useClientState();
  const toast = useFeedbackToast();
  const intl = useIntl();
  const toDisplayName = useRealmToDisplayName();
  const [mode, setMode] = useState(address ? MODE_DELIVERY : MODE_COLLECTION);
  const isLaundry = service?.sector === BUSINESS_SECTOR_LAUNDRY;

  return (
    <div className="flex flex-fill scroll-container">
      <div className="flex flex-row flex-space-between padding-half">
        <IconButton type="ghost" />
        <Segmented
          value={mode}
          onChange={setMode}
          size="large"
          options={[
            {
              label: <FormattedMessage defaultMessage={"Self collection"} />,
              value: MODE_COLLECTION,
              icon: <ShopOutlined />,
            },
            {
              label: <FormattedMessage defaultMessage={"Home delivery"} />,
              value: MODE_DELIVERY,
              icon: <CarOutlined />,
            },
          ]}
        />
        <IconButton icon={<CloseOutlined />} onClick={() => onClose(false)} />
      </div>
      <div className="padding-half flex-fill self-scroll">
        {mode === MODE_COLLECTION ? (
          (isLaundry || service?.storePickup) ? (
            <CollectionDatesCalendar
              collectionOptions={collectionOptions}
              schedule={!address ? returnSchedule : null}
              onSelect={(returnSchedule) => onUpdate({ returnSchedule, address: null })}
            />
          ) : (
            <Card title={<FormattedMessage defaultMessage={"Collection place"} />}>
              <CollectionPlacesList
                select={collectionPlace}
                onSelect={(collectionPlace) => {
                  toast.info(
                    intl.formatMessage(
                      { defaultMessage: "Order self collect at {location}" },
                      { location: toDisplayName(collectionPlace) || collectionPlace }
                    )
                  );
                  onUpdate({ collectionPlace });
                }}
              />
            </Card>
          )
        ) : (
          <JobSchedulePanel
            enableScheduleLater={!isLaundry}
            service={service}
            businessCode={businessCode}
            customer={customer}
            address={address}
            schedule={returnSchedule}
            onUpdate={({ schedule, ...others }) => onUpdate({ ...others, returnSchedule: schedule })}
          />
        )}
      </div>
    </div>
  );
}
