import { DateDisplay } from "@easybiz/component";
import { useCashDrawer } from "@easybiz/component-shared";
import { FormattedMessage } from "react-intl";

function CashDrawerEndAlert() {
  const cashDrawer = useCashDrawer();

  if (cashDrawer?.startTime) {
    return {
      title: <FormattedMessage defaultMessage={"Have you forgot to end your cash drawer?"} />,
      description: (
        <FormattedMessage
          defaultMessage={"Current cash drawer started at {time} by {operatorName}"}
          values={{
            time: <DateDisplay timestamp={cashDrawer?.startTime} />,
            operatorName: cashDrawer?.startBy?.name,
          }}
        />
      ),
    };
  }
}

export default CashDrawerEndAlert;
