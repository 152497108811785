import { EnvironmentOutlined, ShopOutlined } from "@ant-design/icons";
import { NameDisplay } from "@easybiz/component";
import { useAccessStoreIds, useRealmCollectionPoints } from "@easybiz/component-shared";
import { Checkbox, List } from "antd";
import { useMemo } from "react";
import Title from "../Title";

export default function ({ style, footer, header, select, onSelect }) {
  const places = useRealmCollectionPoints();
  const storeLicenses = useAccessStoreIds();

  const dataSource = useMemo(() => {
    return [
      ...(storeLicenses||[]).map((businessCode) => {
        return {
          key: businessCode,
          title: <NameDisplay nameKey={businessCode} />,
          icon: <ShopOutlined />,
        };
      }),
      ...(places||[]).map((place) => {
        return {
          key: place.name,
          title: place.name,
          icon: <EnvironmentOutlined />,
        };
      }),
    ];
  }, [places, storeLicenses]);

  return (
    <List
      header={header}
      style={style}
      dataSource={dataSource}
      renderItem={({ key, title, icon }) => {
        const selected = select === key;

        return (
          <List.Item
            className="selectable"
            onClick={(e) => onSelect(key, e)}
            actions={[<Checkbox checked={selected} />]}
          >
            <List.Item.Meta avatar={icon} title={<Title>{title}</Title>} />
          </List.Item>
        );
      }}
      footer={footer}
    />
  );
}
