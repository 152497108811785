import { useActionCallback } from "@easybiz/hook";
import { useFeedbackToast } from "@easybiz/component-shared";
import { ADMIN_ACTION_INVOICE_SUBMIT, OPERATION_GROUP_ADMIN } from "@easybiz/utils";
import { useIntl } from "react-intl";

function useAdminInvoiceSubmit(callback) {
  const toast = useFeedbackToast();
  const intl = useIntl();

  return useActionCallback(
    ({ hideDetails, invoice }) => {
      if (!invoice) {
        return intl.formatMessage({ defaultMessage: "Invoice preview not ready" });
      }

      return [
        OPERATION_GROUP_ADMIN,
        ADMIN_ACTION_INVOICE_SUBMIT,
        {
          ...(hideDetails && { hideDetails }),
          invoice,
        },
      ];
    },
    callback,
    (request, response) =>
      toast.success(
        intl.formatMessage(
          { defaultMessage: "Invoice {invoiceNo} created" },
          { invoiceNo: `#${response.invoice?.invoiceNo}` }
        )
      )
  );
}

export default useAdminInvoiceSubmit;
