import { CheckoutProductsProvider } from "@easybiz/component";
import {
  useCheckoutCustomer,
  useCheckoutParams,
  useCheckoutParamsUpdate,
  useCheckoutService,
  useClientState, useClientStateSetter, useUserBusinessCode,
} from "@easybiz/component-shared";
import {
  DISPLAY_MODE_CHECK_IN,
  DISPLAY_MODE_CHECK_OUT,
  DISPLAY_MODE_COLLECTION,
  DISPLAY_MODE_CONFIRM_ORDER,
  DISPLAY_MODE_PRODUCTION,
  DISPLAY_MODE_REDO,
  DISPLAY_MODE_SALES,
  DISPLAY_MODE_SCHEDULE,
} from "@easybiz/utils";
import { GridSelectionPanel } from "@easybiz/web-admin";
import React, { useCallback, useState } from "react";
import CheckInOutHandoverPanel from "./CheckInOutHandoverPanel";
import CollectionDeliverySchedulePanel from "./CollectionDeliverySchedulePanel";
import CollectionMonitorPanel from "./CollectionMonitorPanel";
import InvoicePanel from "./InvoicePanel";
import POSDaySalesPanel from "./POSDaySalesPanel";
import POSMainHeader from "./POSMainHeader";
import POSProductionMonitorPanel from "./POSProductionMonitorPanel";
import PendingPriceConfirmPanel from "./PendingPriceConfirmPanel";
import RedoItemsSelectionPanel from "./RedoItemsSelectionPanel";

export default function POSHomeLeftPanel() {
  const [search, setSearch] = useState();
  const { address, returnSchedule, collectionPlace } = useCheckoutParams();
  const updateCheckout = useCheckoutParamsUpdate();
  const service = useCheckoutService();
  const businessCode = useUserBusinessCode();
  const customer = useCheckoutCustomer();
  const { displayMode } = useClientState();
  const setState = useClientStateSetter();

  const onClose = useCallback(() => {
    setState({ displayMode: null });
  }, [setState]);

  switch (displayMode?.key) {
    case DISPLAY_MODE_SALES:
      return <POSDaySalesPanel date={displayMode?.date} selectOrderId={displayMode?.orderId} onClose={onClose} />;
    case DISPLAY_MODE_CONFIRM_ORDER:
      return <PendingPriceConfirmPanel onClose={onClose} />;
    case DISPLAY_MODE_CHECK_IN:
      return (
        <CheckInOutHandoverPanel
          displayMode={displayMode}
          onChange={(displayMode) => setState({ displayMode })}
          isCheckOut={false}
          onClose={onClose}
        />
      );
    case DISPLAY_MODE_CHECK_OUT:
      return (
        <CheckInOutHandoverPanel
          displayMode={displayMode}
          onChange={(displayMode) => setState({ displayMode })}
          isCheckOut={true}
          onClose={onClose}
        />
      );
    case DISPLAY_MODE_COLLECTION:
      return <CollectionMonitorPanel date={displayMode?.date} onClose={onClose} />;
    case DISPLAY_MODE_PRODUCTION:
      return <POSProductionMonitorPanel date={displayMode?.date?.format("YYYY-MM-DD")} onClose={onClose} />;
    case DISPLAY_MODE_SCHEDULE:
      return (
        <CollectionDeliverySchedulePanel
          service={service}
          businessCode={businessCode}
          customer={customer}
          address={address}
          returnSchedule={returnSchedule}
          collectionPlace={collectionPlace}
          onClose={onClose}
          onUpdate={(updates) => {
            updateCheckout(updates);
            onClose(false);
          }}
        />
      );
    case DISPLAY_MODE_REDO:
      if (displayMode.scheduleOpen) {
        return (
          <CollectionDeliverySchedulePanel
            service={displayMode.order.get("service")}
            businessCode={displayMode.order.get("business.id")}
            customer={displayMode.order.get("customer")}
            address={displayMode.address}
            returnSchedule={displayMode.returnSchedule}
            collectionPlace={displayMode.collectionPlace}
            onClose={() => setState({ displayMode: { ...displayMode, scheduleOpen: false } })}
            onUpdate={(updates) => {
              setState({ displayMode: { ...displayMode, ...updates, scheduleOpen: false } });
            }}
          />
        );
      } else {
        return <RedoItemsSelectionPanel onClose={onClose} />;
      }
    default:
      if (displayMode?.invoice) {
        return (
          <InvoicePanel
            invoice={displayMode?.invoice}
            onClose={onClose}
            setDisplayMode={(displayMode) => {
              setState({ displayMode });
            }}
          />
        );
      } else {
        return (
          <>
            <POSMainHeader search={search} onSearch={setSearch} />
            <CheckoutProductsProvider>
              <GridSelectionPanel search={search} onSearch={setSearch} />
            </CheckoutProductsProvider>
          </>
        );
      }
  }
}
