import { LoadingOutlined, PrinterOutlined, SettingOutlined } from "@ant-design/icons";
import { usePrinter } from "@easybiz/hook";
import { useClientRegisterDevice, usePrintingConnectPrinter, useUserDebugMode } from "@easybiz/component-shared";
import { PRINTER_TYPE_LABEL } from "@easybiz/utils";
import { Button, Space } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

export default function PrintLabelButton({ type, loading, printingParams }) {
  const printer = usePrinter();
  const registerDevice = useClientRegisterDevice();
  const platformMode = useUserDebugMode();
  const connectPrinter = usePrintingConnectPrinter();
  const [printing, setPrinting] = useState(false);

  const onPrint = async () => {
    try {
      setPrinting(true);
      await printer.print(printingParams);
    } finally {
      setPrinting(false);
    }
  };

  return (
    <Space>
      <Button
        type={type}
        disabled={!printingParams}
        loading={Boolean(printing || loading)}
        onClick={onPrint}
        icon={registerDevice || platformMode ? <PrinterOutlined /> : <LoadingOutlined />}
      >
        <FormattedMessage
          tagName="span"
          defaultMessage="Print labels ({count})"
          values={{
            count: printingParams?.encodeLabels
              ? printingParams.encodeLabels.length
              : printingParams?.labelOrder?.get("labels")?.length,
          }}
        />
      </Button>
      <Button
        icon={<SettingOutlined />}
        type="text"
        onClick={() => connectPrinter({ printerType: PRINTER_TYPE_LABEL, ...printingParams })}
      />
    </Space>
  );
}
