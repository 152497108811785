import { PriceListProvider, useCheckoutBusinessPricing, useCheckoutItems } from "@easybiz/component-shared";
import useProductOptionsWithSelections from "./useProductOptionsWithSelections";

function CheckoutProductsProvider({ children, flatMode }) {
  const rawPricingData = useCheckoutBusinessPricing();
  const items = useCheckoutItems();
  const pricingData = useProductOptionsWithSelections(rawPricingData, items, flatMode);

  return <PriceListProvider pricingData={pricingData}>{children}</PriceListProvider>;
}

export default CheckoutProductsProvider;
