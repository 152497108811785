import { useJobReschedule } from "@easybiz/api-job";
import { useOrderAddJob } from "@easybiz/api-order";
import { AddressDisplay, JobTitle } from "@easybiz/component";
import { useRealmIsServiceSlotReady } from "@easybiz/component-shared";
import { translate } from "@easybiz/utils";
import { useCustomerDoc } from "@easybiz/web-firebase";
import { Button, Collapse, DatePicker, List, Space, TimePicker, Typography, message } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CustomerAddressDialog from "../CustomerAddressDialog";
import CustomerAddressQuickEditButton from "../CustomerAddressQuickEditButton";
import Dialog from "../Dialog";
import QuickSwitchCalendar from "../QuickSwitchCalendar";
import ScheduleOptionsList from "../ScheduleOptionsList";
import SurchargeEditButton from "../SurchargeEditButton";

export default function ({
  taskId,
  orderId,
  customerId,
  businessCode,
  serviceId,
  select,
  scheduleType,
  startDate,
  open,
  onCancel,
  onSchedule,
  redoOrder,
  onCancelSchedule,
}) {
  const [draftSchedule, setDraftSchedule] = useState();
  const [activeKey, setActiveKey] = useState("date");
  const [editVisible, setEditVisible] = useState(false);
  const [newVisible, setNewVisible] = useState(false);
  const isSlotReady = useRealmIsServiceSlotReady(serviceId);
  const intl = useIntl();
  const { address, date, start, end, option } = draftSchedule || {};
  const customerDoc = useCustomerDoc(open && !address ? customerId : null);
  const { onSubmit: onReschedule, loading } = useJobReschedule((success) => success && onCancel());
  const { onSubmit: onNewJob, loading: loading2 } = useOrderAddJob((success) => success && onCancel());

  useEffect(() => {
    if (open) {
      setDraftSchedule(select);
    }
  }, [open]);

  useEffect(() => {
    if (customerDoc?.get("defaultAddress") && !address) {
      setDraftSchedule({ ...draftSchedule, address: customerDoc?.get("defaultAddress") });
    }
  }, [customerDoc]);

  return (
    <Dialog
      customerId={customerId}
      confirmLoading={loading || loading2}
      title={
        redoOrder ? (
          <FormattedMessage
            defaultMessage="Redo order {orderNo}"
            values={{ orderNo: `#${redoOrder.get("orderNo")}` }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="Schedule {scheduleType}"
            values={{ scheduleType: <JobTitle type={scheduleType} /> }}
          />
        )
      }
      open={open}
      onCancel={onCancel}
      onOk={() => {
        if (!address) return message.info(intl.formatMessage({ defaultMessage: "Please select address" }));
        if (!date) return message.info(intl.formatMessage({ defaultMessage: "Please select date" }));

        if (onSchedule) {
          onSchedule(draftSchedule);
        } else if (taskId) {
          // Reschedule
          onReschedule({
            schedule: {
              date,
              ...(start && { start }),
              ...(end && { end }),
            },
            address,
            ...(option && { option }),
            taskId,
          });
        } else if (orderId) {
          // New schedule
          onNewJob({
            orderId,
            schedule: {
              date,
              ...(start && { start }),
              ...(end && { end }),
            },
            address,
            ...(option && { option }),
            type: scheduleType,
          });
        }
      }}
    >
      <List.Item
        extra={
          <CustomerAddressQuickEditButton
            customerId={customerId}
            address={address}
            onSelect={(address, edit) => {
              if (address) {
                if (edit) {
                  setEditVisible(true);
                } else {
                  setDraftSchedule({ ...draftSchedule, address });
                }
              } else {
                setNewVisible(true);
              }
            }}
          />
        }
      >
        <List.Item.Meta
          title={
            address ? (
              <AddressDisplay address={address} />
            ) : (
              <Typography.Text type="danger">
                <FormattedMessage defaultMessage="No address" />
              </Typography.Text>
            )
          }
          description={<FormattedMessage defaultMessage="Address" />}
        />
      </List.Item>
      <List.Item>
        <List.Item.Meta
          title={
            <Space>
              <DatePicker
                value={date ? dayjs(date) : null}
                onChange={(date) =>
                  setDraftSchedule({
                    ...draftSchedule,
                    date: date.format("YYYY-MM-DD"),
                  })
                }
                allowClear={false}
              />
              <TimePicker.RangePicker
                disabled={!date}
                value={date && start && end ? [dayjs(`${date} ${start}`), dayjs(`${date} ${end}`)] : null}
                format="HH:mm"
                onChange={(dates) => {
                  if (dates) {
                    setDraftSchedule({
                      ...draftSchedule,
                      start: dates[0].format("HH:mm"),
                      end: dates[1].format("HH:mm"),
                    });
                  } else {
                    setDraftSchedule({
                      ...draftSchedule,
                      start: null,
                      end: null,
                    });
                  }
                }}
                allowClear
              />
            </Space>
          }
          description={<FormattedMessage defaultMessage="Date & Time" />}
        />
      </List.Item>
      {option && (
        <List.Item
          extra={
            <SurchargeEditButton
              surcharge={option.surcharge}
              onChange={(surcharge) => {
                setDraftSchedule({
                  ...draftSchedule,
                  option: { ...option, surcharge },
                });
              }}
            />
          }
        >
          <List.Item.Meta
            title={translate(option.title)}
            description={
              <FormattedMessage
                defaultMessage="{jobType} option"
                values={{ jobType: <JobTitle type={scheduleType} /> }}
              />
            }
          />
        </List.Item>
      )}
      <Collapse activeKey={activeKey} onChange={setActiveKey} bordered={false}>
        <Collapse.Panel
          key="date"
          header={
            isSlotReady ? (
              <FormattedMessage defaultMessage="Date options" />
            ) : (
              <FormattedMessage defaultMessage={"Schedule date"} />
            )
          }
        >
          {isSlotReady ? (
            <ScheduleOptionsList
              orderId={orderId}
              customerId={customerId}
              businessCode={businessCode}
              serviceId={serviceId}
              scheduleType={scheduleType}
              address={address}
              startDate={startDate}
              select={date ? { date, start, end } : null}
              onSelect={(schedule, option) => {
                setDraftSchedule({ ...draftSchedule, ...schedule, option: option || null });
                setActiveKey(null);
              }}
              redoOrderId={redoOrder?.id}
            />
          ) : (
            <QuickSwitchCalendar
              value={date ? dayjs(date) : null}
              minDate={dayjs()}
              onSelect={(date) => {
                setDraftSchedule({ ...draftSchedule, date: date.format("YYYY-MM-DD") });
                setActiveKey(null);
              }}
            />
          )}
        </Collapse.Panel>
      </Collapse>
      {onCancelSchedule && select && <br />}
      {onCancelSchedule && select && (
        <Button danger type="text" onClick={onCancelSchedule} block>
          <FormattedMessage defaultMessage={"Cancel schedule"} />
        </Button>
      )}
      <CustomerAddressDialog
        customerId={customerId}
        editAddress={editVisible ? address : null}
        open={editVisible || newVisible}
        onCancel={() => {
          setEditVisible(false);
          setNewVisible(false);
        }}
        onComplete={(address) => {
          setDraftSchedule({ ...draftSchedule, address });
          setEditVisible(false);
          setNewVisible(false);
        }}
      />
    </Dialog>
  );
}
