import { CheckOutlined, PrinterOutlined } from "@ant-design/icons";
import { useOrderDischargePanel } from "@easybiz/component";
import { BUSINESS_SECTOR_ALTERATION, BUSINESS_SECTOR_BAG_SERVICE, QR_CODE_TYPE_ITEM, translate } from "@easybiz/utils";
import { speak, USBScanner } from "@easybiz/component-web-business";
import { useCustomerDoc } from "@easybiz/web-firebase";
import { Button, Checkbox, Col, Drawer, message, Row, Space, Tabs } from "antd";
import React, { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CollectionNoticeButton from "../CollectionNoticeButton";
import CollectionReceiptsDrawer from "../CollectionReceiptDrawer";
import DischargeDialog from "../DischargeDialog";
import ItemDeliveryUpdateDrawer from "../ItemDeliveryUpdateDrawer";
import LineItemStatusDrawer from "../LineItemStatusDrawer";
import OrderLabelSelectList from "../OrderLabelSelectList";
import PackStorageInfo from "../PackStorageInfo";
import ReceiptView from "../ReceiptView";

export default function ({ placement, order }) {
  const customerDoc = useCustomerDoc(order?.get("customer.id"));
  const { selects, setSelects, pending, complete } = useOrderDischargePanel(order, customerDoc?.get("corporate"));
  const [scheduleItem, setScheduleItem] = useState();
  const [detailItem, setDetailItem] = useState();
  const [dischargeVisible, setDischargeVisible] = useState(false);
  const [collectionReceiptVisible, setCollectionReceiptVisible] = useState(false);
  const [receiptLines, setReceiptLines] = useState();
  const isBagAlter =
    [BUSINESS_SECTOR_ALTERATION, BUSINESS_SECTOR_BAG_SERVICE].indexOf(order?.get("service.sector")) >= 0;
  const intl = useIntl();

  const onDetected = useCallback(
    ({ data }) => {
      const labels = order?.get("labels");
      if (Array.isArray(labels)) {
        const index = labels.indexOf(data);
        if (index >= 0) {
          speak(intl.formatMessage({ defaultMessage: "check" }));
          setSelects([...(selects || []), index + 1]);
        } else {
          speak(intl.formatMessage({ defaultMessage: "Wrong item" }));
        }
      }
    },
    [order, selects]
  );

  return (
    <>
      <USBScanner enabled onDetected={onDetected} filter={QR_CODE_TYPE_ITEM} />
      <PackStorageInfo order={order} />
      <Tabs type="card">
        {pending && (
          <Tabs.TabPane key="pending" tab={pending.title}>
            <Space direction="vertical" className="full-width">
              <Row gutter={16} justify="end">
                <Col>
                  <CollectionNoticeButton order={order} selects={selects} />
                </Col>
                <Col>
                  <Button
                    disabled={pending.dischargeDisabled}
                    block
                    type="primary"
                    onClick={() => {
                      if (!selects?.length) {
                        return message.info(
                          intl.formatMessage({
                            defaultMessage: "Please select/scan discharge items",
                          })
                        );
                      }

                      setDischargeVisible(true);
                    }}
                    icon={<CheckOutlined />}
                  >
                    <span>{pending.dischargeBtn}</span>
                  </Button>
                </Col>
              </Row>
              <OrderLabelSelectList
                disabled={!pending.selectAll}
                header={
                  pending.selectAll && (
                    <Checkbox
                      checked={pending.selectAll.checked}
                      indeterminate={pending.selectAll.indeterminate}
                      onChange={(e) => pending.selectAll.onChange(e.target.checked)}
                    >
                      {pending.selectAll.title}
                    </Checkbox>
                  )
                }
                order={order}
                items={pending.items}
                placement={placement}
                onSelectChange={setSelects}
                selects={selects}
                multiple
                onSchedule={isBagAlter ? setScheduleItem : null}
                onDetails={isBagAlter ? setDetailItem : null}
              />
            </Space>
          </Tabs.TabPane>
        )}
        {complete && (
          <Tabs.TabPane key="discharged" tab={complete.title}>
            <Button onClick={() => setCollectionReceiptVisible(true)} icon={<PrinterOutlined />}>
              <FormattedMessage tagName="span" defaultMessage="Collection receipt" />
            </Button>
            <OrderLabelSelectList
              order={order}
              items={complete.items}
              placement={placement}
              onDetails={isBagAlter ? setDetailItem : null}
              onSubOptions={isBagAlter ? setDetailItem : null}
              disabled
            />
          </Tabs.TabPane>
        )}
      </Tabs>
      <ItemDeliveryUpdateDrawer
        placement={placement}
        order={order}
        item={scheduleItem}
        open={Boolean(scheduleItem)}
        onClose={() => setScheduleItem(null)}
      />
      <DischargeDialog
        order={order}
        selects={selects}
        open={dischargeVisible}
        onCancel={() => setDischargeVisible(false)}
        onDischarged={(receiptLines) => {
          setSelects([]);
          setDischargeVisible(false);
          setReceiptLines(receiptLines);
        }}
      />
      <CollectionReceiptsDrawer
        order={order}
        open={collectionReceiptVisible}
        placement={placement}
        onClose={() => setCollectionReceiptVisible(false)}
      />
      <Drawer
        placement={placement}
        width={470}
        title={<FormattedMessage tagName="span" defaultMessage="Collection Receipt" />}
        open={Boolean(receiptLines)}
        onClose={() => setReceiptLines(null)}
      >
        {receiptLines && <ReceiptView lines={receiptLines} printing />}
      </Drawer>
      <LineItemStatusDrawer
        orderDoc={order}
        open={Boolean(detailItem)}
        onClose={() => setDetailItem(null)}
        viewOrder={
          detailItem
            ? {
                orderNo: order?.get("orderNo"),
                businessCode: order?.get("business.id"),
                name: translate(detailItem?.product),
                index: detailItem?.itemIndex,
              }
            : null
        }
      />
    </>
  );
}
