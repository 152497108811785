import { useServerAction } from "@easybiz/component-shared";
import { SERVER_ACTION_POS_CLOCK_IN } from "@easybiz/lib-shared-auth";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

function useClockInDialog(onSucceed) {
  const [passcode, setPasscode] = useState("");
  const { loading, onCall } = useServerAction(SERVER_ACTION_POS_CLOCK_IN);

  useEffect(() => {
    if (passcode && `${passcode}`.length >= 4) {
      onCall({ passcode }, (response) => {
        setPasscode("");

        if (response.token) {
          onSucceed(response.token);
        }
      });
    }
  }, [passcode]);

  return {
    passcode,
    onChange: setPasscode,
    title: <FormattedMessage defaultMessage="Clock In" />,
    header: <FormattedMessage defaultMessage="Passcode" />,
    loadingTip: <FormattedMessage defaultMessage={"Clocking in..."} />,
    loading,
  };
}

export default useClockInDialog;
