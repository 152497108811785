import { NumberOutlined } from "@ant-design/icons";
import { useClientStateSetter, useFeedbackToast, useUserBusinessCode, useUserRealmId } from "@easybiz/component-shared";
import { DialPad, IconButton, Title } from "@easybiz/web-admin";
import { Button, Col, Popover, Row, Space, Typography } from "antd";
import { collection, getDocs, getFirestore, limit, query, where } from "firebase/firestore";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default function QuickSearchButton({ customer, search, inputFocused, onSearch }) {
  const intl = useIntl();
  const realmId = useUserRealmId();
  const businessCode = useUserBusinessCode();
  const [quickVisible, setQuickVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const setState = useClientStateSetter();
  const toast = useFeedbackToast();

  useEffect(() => {
    if (customer && search !== null) {
      onSearch(null);
    }
  }, [customer]);

  useEffect(() => {
    if (inputFocused && quickVisible) {
      setQuickVisible(false);
    }
  }, [inputFocused]);

  useEffect(() => {
    if (quickVisible) {
      onSearch("");
    }
  }, [quickVisible]);

  const onSearchOrderNo = useCallback(
    (e) => {
      if (e?.stopPropagation) {
        e?.stopPropagation();
      }

      if (!search) {
        return toast.info(intl.formatMessage({ defaultMessage: "Please enter order number" }));
      }

      setLoading(true);
      getDocs(
        query(
          collection(getFirestore(), `realms/${realmId}/orders`),
          where("business.id", "==", businessCode),
          where("orderNo", "==", search),
          limit(1)
        )
      )
        .then((orders) => {
          if (orders.empty) {
            toast.info(
              intl.formatMessage(
                { defaultMessage: "Order not found by searching order number {orderNo}" },
                {
                  orderNo: `#${search}`,
                }
              )
            );
          } else {
            setState({ openOrderId: orders.docs[0].id });
            onSearch(null);
            setQuickVisible(false);
          }
        })
        .finally(() => setLoading(false));
    },
    [search]
  );

  return (
    <Popover
      title={
        <Title>
          <FormattedMessage defaultMessage={"Quick search"} />
        </Title>
      }
      overlayStyle={{ width: 300 }}
      content={
        <Space direction="vertical" className="full-width" size={"middle"}>
          <Typography.Title level={4} style={{ minHeight: 28, textAlign: "center" }}>
            {search}
          </Typography.Title>
          <DialPad disableListener={!quickVisible} value={search} onChange={onSearch} onDone={onSearchOrderNo} />
          <Row gutter={8}>
            <Col span={12}>
              <Button block onClick={() => setQuickVisible(false)}>
                <FormattedMessage defaultMessage={"Close"} />
              </Button>
            </Col>
            <Col span={12}>
              <Button block type="primary" onClick={onSearchOrderNo} loading={loading}>
                <FormattedMessage defaultMessage={"Order No"} />
              </Button>
            </Col>
          </Row>
        </Space>
      }
      placement="leftBottom"
      trigger="click"
      open={quickVisible}
      onOpenChange={setQuickVisible}
      destroyTooltipOnHide
    >
      <IconButton icon={<NumberOutlined />} loading={loading} />
    </Popover>
  );
}
