"use client";

import {
  ACTION_COLLECT,
  ACTION_CONFIRM_PRICE,
  ACTION_PAYMENT,
  SALES_CHANNEL_TYPE_AGENT,
  CLIENT_MOBILE,
  isLogisticAction,
  ORDER_TAB_COLLECTION,
  ORDER_TAB_HISTORY,
  ORDER_TAB_LABEL,
  ORDER_TAB_LOGISTIC,
  ORDER_TAB_PAYMENT,
  ORDER_TAB_PHOTO,
  ORDER_TAB_PLANT_EDIT,
  ORDER_TAB_PROGRESS,
  ORDER_TAB_RECEIPT,
} from "@easybiz/utils";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useOrderPermissions from "../useOrderPermissions";
import { useClientType } from "@easybiz/component-shared";

function useOrderDetailTabPanel(order, initTab, isDayClosed) {
  const [activeTab, setActiveTab] = useState(initTab || ORDER_TAB_RECEIPT);
  const client = useClientType();
  const intl = useIntl();
  const permissions = useOrderPermissions(order, isDayClosed);

  useEffect(() => {
    if (initTab) {
      setActiveTab(initTab);
    }
  }, [initTab]);

  const orderTabs = useMemo(() => {
    const { awaits, completes, labels, paid, totalBalance, type } = order?.data() || {};
    const isPlant = type === SALES_CHANNEL_TYPE_AGENT;
    const pendingPayment = Boolean((awaits || []).find((action) => action === ACTION_PAYMENT));
    const pendingCollection = Boolean(!isPlant && (awaits || []).find((action) => action === ACTION_COLLECT));
    const jobAwaits = awaits?.filter(isLogisticAction);
    const haveLogistic = Boolean(jobAwaits?.length > 0 || (completes || []).find(({ a }) => isLogisticAction(a)));

    return [
      { key: ORDER_TAB_RECEIPT, icon: "receipt", label: <FormattedMessage id="receipt" defaultMessage="Receipt" /> },
      ...(Array.isArray(labels)
        ? [{ key: ORDER_TAB_LABEL, icon: "tag", label: <FormattedMessage id="label" defaultMessage="Label" /> }]
        : []),
      {
        key: ORDER_TAB_PAYMENT,
        danger: pendingPayment,
        icon: "credit-card-outline",
        label: <FormattedMessage id="payment" defaultMessage="Payment" />,
        ...(paid === false && { badge: `${intl.formatNumber(totalBalance, { minimumFractionDigits: 2 })}` }),
      },
      ...(haveLogistic && !isPlant
        ? [
            {
              key: ORDER_TAB_LOGISTIC,
              icon: "van-utility",
              label: <FormattedMessage id="logistic" defaultMessage="Logistic" />,
              ...(jobAwaits?.length > 0 && { badge: jobAwaits.length }),
            },
          ]
        : []),
      ...(pendingCollection || completes?.find(({ a }) => a === ACTION_COLLECT)
        ? [
            {
              key: ORDER_TAB_COLLECTION,
              icon: "store",
              label: <FormattedMessage id="collection" defaultMessage="Collection" />,
              danger: pendingCollection,
              ...(pendingCollection && {
                badge: intl.formatMessage({
                  id: "pending",
                  defaultMessage: "Pending",
                }),
              }),
            },
          ]
        : []),
      ...(isPlant
        ? [
            {
              key: ORDER_TAB_PLANT_EDIT,
              icon: "border-color",
              label: <FormattedMessage id="edit" defaultMessage="Edit" />,
            },
          ]
        : []),
      ...(Array.isArray(labels) && client !== CLIENT_MOBILE
        ? [{ key: ORDER_TAB_PHOTO, icon: "photo", label: <FormattedMessage id="photo" defaultMessage="Photo" /> }]
        : []),
      ...(isDayClosed !== null
        ? [
            {
              key: ORDER_TAB_PROGRESS,
              icon: "download-network",
              label: <FormattedMessage id="progress" tagName="span" defaultMessage="Progress" />,
            },
          ]
        : []),
      { key: ORDER_TAB_HISTORY, icon: "history", label: <FormattedMessage id="history" defaultMessage="History" /> },
    ];
  }, [order, client, isDayClosed]);

  useEffect(() => {
    if (activeTab && !orderTabs.find((tab) => tab.key === activeTab)) {
      if (activeTab === ORDER_TAB_COLLECTION && orderTabs.find((tab) => tab.key === ORDER_TAB_LOGISTIC)) {
        setActiveTab(ORDER_TAB_LOGISTIC);
      } else if (activeTab === ORDER_TAB_LOGISTIC && orderTabs.find((tab) => tab.key === ORDER_TAB_COLLECTION)) {
        setActiveTab(ORDER_TAB_COLLECTION);
      } else {
        setActiveTab(orderTabs[0].key);
      }
    }
  }, [activeTab, orderTabs]);

  return {
    orderTabs,
    activeTab,
    setActiveTab,
    permissions,
    ...(!order?.get("voided") &&
      (order?.get("awaits") || []).find((action) => action === ACTION_CONFIRM_PRICE) && {
        alertTitle: intl.formatMessage({
          id: "order.amount.pending.confirm",
          defaultMessage: "Order amount pending confirm",
        }),
      }),
  };
}

export default useOrderDetailTabPanel;
