import { useOrderHistoryFeeds } from "@easybiz/component";
import { LazyImage } from "@easybiz/component-web-business-ui";
import { useOrderHistoryDoc } from "@easybiz/web-firebase";
import { List, Typography } from "antd";
import React from "react";
import OrderIdIcon from "../OrderIdIcon";

export default ({ orderId }) => {
  const historyDoc = useOrderHistoryDoc(orderId);
  const { dataSource } = useOrderHistoryFeeds(historyDoc);

  return (
    <>
      <div className="flex flex-row flex-end">
        <OrderIdIcon orderId={orderId} />
      </div>
      <List
        dataSource={dataSource || []}
        loading={!dataSource}
        renderItem={(operation) => {
          const { operator, dateTime, description, imageUrl, error } = operation;
          return (
            <List.Item actions={imageUrl ? [<LazyImage src={imageUrl} />] : null}>
              <List.Item.Meta
                title={<Typography.Text type={error ? "danger" : null}>{description}</Typography.Text>}
                description={
                  <>
                    {dateTime}
                    {` `}
                    {operator}
                  </>
                }
              />
            </List.Item>
          );
        }}
      />
    </>
  );
};
