"use client";

import {
  useClientInstallId,
  useClientType,
  useFeedbackToast,
  useLicenseClientAuthDeviceId,
  useLicenseClientAuthTimestamp,
  useUser,
  useUserAuthTime,
  useUserDebugMode,
} from "@easybiz/component-shared";
import { SYSTEM_CLIENT_POS } from "@easybiz/lib-shared-utils";
import dayjs from "dayjs";
import { useEffect } from "react";

export default function SingleSignOnMonitor({ signOut, getAuth }) {
  const multiStation = useUser()?.multiStation;
  const lastAuthTimestamp = useLicenseClientAuthTimestamp();
  const lastAuthDeviceId = useLicenseClientAuthDeviceId();
  const authTime = useUserAuthTime();
  const client = useClientType();
  const installId = useClientInstallId();
  const toast = useFeedbackToast();
  const debugMode = useUserDebugMode();

  // Sign out if there is a new login session
  useEffect(() => {
    if (
      !debugMode &&
      (client !== SYSTEM_CLIENT_POS || multiStation === false) &&
      installId &&
      authTime &&
      lastAuthTimestamp &&
      lastAuthDeviceId !== installId &&
      lastAuthTimestamp > authTime
    ) {
      // Sign out
      signOut(getAuth()).then(() =>
        toast.info({
          title: "Login session expired",
          description: `Your account has been logged in another device ID:${lastAuthDeviceId} at ${dayjs
            .unix(lastAuthTimestamp)
            .format("HH:mm DD MMM YYYY")}, you can enable device whitelist to limit device access to your system.`,
          dialog: true,
          noCancel: true,
          okText: "OK",
        })
      );
    }
  }, [multiStation, installId, authTime, lastAuthTimestamp, lastAuthDeviceId, debugMode]);

  return null;
}
