import { useClientType, useFeedbackToast, useUserCurrency } from "@easybiz/component-shared";
import { CLIENT_POS, PROMO_TYPE_FLAT, PROMO_TYPE_PERCENT } from "@easybiz/utils";
import { Badge, message, Radio, Segmented, Space } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Dialog from "../Dialog";
import NumericUpdatePanel from "../NumericUpdatePanel";

export default ({ title, enableDiscount, open, initPrice, initDiscount, onCancel, onChange }) => {
  const clientType = useClientType();
  const currency = useUserCurrency();
  const [discountVisible, setDiscountVisible] = useState(false);
  const [price, setPrice] = useState();
  const [discount, setDiscount] = useState();
  const intl = useIntl();
  const toast = useFeedbackToast();

  useEffect(() => {
    setDiscountVisible(false);
    setDiscount(initDiscount);
    setPrice(initPrice);
  }, [open]);

  return (
    <Dialog
      title={title}
      open={open}
      onCancel={onCancel}
      onOk={() => {
        if (!price || isNaN(price)) {
          return message.info(intl.formatMessage({ defaultMessage: `Please enter the price` }));
        }

        let formatDiscount;
        if (discount?.value && !isNaN(discount?.value) && parseFloat(discount.value) > 0) {
          formatDiscount = {
            type: discount.type,
            value: parseFloat(discount.value),
          };
        }

        if (formatDiscount?.type === PROMO_TYPE_FLAT && formatDiscount?.value > parseFloat(price)) {
          return toast.info(intl.formatMessage({ defaultMessage: "Discount amount can not later than total amount" }));
        }

        onChange(parseFloat(price), formatDiscount);
      }}
    >
      <Space direction="vertical" className="full-width" size="middle">
        {enableDiscount && (
          <Segmented
            value={discountVisible}
            onChange={setDiscountVisible}
            size={clientType === CLIENT_POS ? "large" : null}
            block
            options={[
              {
                value: false,
                label: <FormattedMessage defaultMessage={"Sub service total"} />,
              },
              {
                value: true,
                label: (
                  <Badge
                    offset={[20, 0]}
                    count={
                      discount?.value
                        ? discount?.type === PROMO_TYPE_FLAT
                          ? `-${intl.formatNumber(discount.value, {
                              style: "currency",
                              currency,
                              currencyDisplay: "narrowSymbol",
                            })}`
                          : `-${discount?.value}%`
                        : null
                    }
                  >
                    <FormattedMessage defaultMessage={"Sub service discount"} />
                  </Badge>
                ),
              },
            ]}
          />
        )}
        {discountVisible && (
          <Radio.Group
            options={[
              {
                value: PROMO_TYPE_FLAT,
                label: (
                  <FormattedMessage tagName="span" defaultMessage="By value" description="By direct enter amount" />
                ),
              },
              {
                value: PROMO_TYPE_PERCENT,
                label: <FormattedMessage tagName="span" defaultMessage="By percentage" />,
              },
            ]}
            value={discount?.type || PROMO_TYPE_FLAT}
            onChange={(e) => setDiscount({ ...discount, type: e.target.value })}
          />
        )}
        <NumericUpdatePanel
          onChange={(price) => {
            if (discountVisible) {
              if (price && !isNaN(price) && parseFloat(price) > 0) {
                setDiscount({ type: discount?.type || PROMO_TYPE_FLAT, value: price });
              } else {
                setDiscount(null);
              }
            } else {
              setPrice(price);
            }
          }}
          prefix={discountVisible ? "-" : null}
          type={discountVisible ? "danger" : null}
          value={discountVisible ? discount?.value : price}
          isCurrency={!discountVisible || discount?.type !== PROMO_TYPE_PERCENT}
          suffix={discountVisible && discount?.type === PROMO_TYPE_PERCENT ? "%" : null}
          buttonText={<FormattedMessage tagName="span" defaultMessage="OK" />}
          disableListener={discountVisible}
        />
      </Space>
    </Dialog>
  );
};
