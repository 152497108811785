export default function firestoreGetDocs(
  { getDocs, query, collection, getFirestore, where, orderBy, limit },
  collectionPath,
  args
) {
  if (typeof getDocs === "function") {
    // Client SDK
    return getDocs(
      query(
        collection(getFirestore(), collectionPath),
        ...[
          ...(args?.wheres || []).map(([field, operator, value]) => where(field, operator, value)),
          ...(args?.orders || []).map(([field, direction]) => orderBy(field, direction)),
          limit(args.limit),
        ]
      )
    );
  } else {
    // Admin SDK
    let firestoreQuery = getFirestore().collection(collectionPath);
    (args?.wheres || []).forEach(([field, operator, value]) => {
      firestoreQuery = firestoreQuery.where(field, operator, value);
    });
    (args?.orders || []).forEach(([field, direction]) => {
      firestoreQuery = firestoreQuery.orderBy(field, direction);
    });
    return firestoreQuery.limit(args.limit).get();
  }
}
