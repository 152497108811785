"use client";

import {
  DATA_ACCESS_ORDER_DATA,
  PERMISSION_LEVEL_BLOCKED,
  PERMISSION_LEVEL_TIME_LIMITED,
  PERMISSION_TIME_SAME_DAY,
  PERMISSION_TIME_WITHIN_X_DAY,
} from "@easybiz/lib-shared-auth";
import { useAccessData, useAccessLimitBusinessIds, useAccessSelfAgentId, useUserRealmId } from "@easybiz/component-shared";
import dayjs from "dayjs";
import {
  collection,
  endBefore,
  getFirestore,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";

export default (args) => {
  const realmId = useUserRealmId();
  const limitCodes = useAccessLimitBusinessIds();
  const agentId = useAccessSelfAgentId();
  let {
    search,
    orderNoOnly,
    awaitAction,
    awaitActions,
    customerId,
    orderType,
    startTime,
    endTime,
    assend,
    date,
    deliveryDate,
    businessCode,
    collectionPlace,
    businessCodes,
    supplierId,
    creditNoteOnly,
    startAfter: startDoc,
    endBefore: endDoc,
    limit: limitCount,
    lastPayTime,
  } = args || {};
  const [orders, setOrders] = useState(null);
  const [loading, setLoading] = useState();
  const [{ level, timeWindow, days }] = useAccessData(DATA_ACCESS_ORDER_DATA);

  if (level === PERMISSION_LEVEL_TIME_LIMITED) {
    const today = dayjs();

    if (timeWindow === PERMISSION_TIME_SAME_DAY) {
      startTime = Math.max(startTime || 0, today.startOf("day").unix());
      endTime = Math.min(endTime || 0, today.endOf("day").unix());
    } else if (timeWindow === PERMISSION_TIME_WITHIN_X_DAY && days) {
      const startDay = today.subtract(parseInt(days), "day").startOf("day");

      startTime = Math.max(startTime || 0, startDay.unix());
      assend = true;
    }
  }

  useEffect(() => {
    if (level === PERMISSION_LEVEL_BLOCKED) {
      setOrders([]);
      return;
    }

    if (realmId && args) {
      let orderQuery = query(collection(getFirestore(), `realms/${realmId}/orders`));

      if (search) {
        if (orderNoOnly) {
          orderQuery = query(orderQuery, where("orderNo", "==", `${search}`.trim()));
        } else {
          orderQuery = query(
            orderQuery,
            where(
              "searchables",
              "array-contains",
              `${search}`.length === 20 ? search : `${search}`.trim().toLowerCase()
            )
          );
        }
      } else if (creditNoteOnly) {
        setLoading(true);
        return onSnapshot(query(orderQuery, orderBy("creditNotes", "desc"), limit(20)), (result) => {
          setOrders(result.docs);
          setLoading(false);
        });
      } else if (awaitAction) {
        orderQuery = query(orderQuery, where("awaits", "array-contains", awaitAction));
      } else if (Array.isArray(awaitActions)) {
        if (awaitActions.length === 0) {
          orderQuery = query(orderQuery, where("awaits", "==", []));
        } else {
          orderQuery = query(orderQuery, where("awaits", "array-contains-any", awaitActions));
        }
      }

      if (customerId) {
        orderQuery = query(orderQuery, where("customer.id", "==", customerId));
      } else if (typeof orderType === "string") {
        orderQuery = query(orderQuery, where("type", "==", orderType));
      } else if (Array.isArray(orderType)) {
        orderQuery = query(orderQuery, where("type", "in", orderType));
      }

      if (agentId) {
        orderQuery = query(orderQuery, where("supplier.id", "==", agentId));
      }
      if (collectionPlace) {
        orderQuery = query(orderQuery, where(`collectionPlace`, "==", collectionPlace));
      } else if (businessCode) {
        orderQuery = query(orderQuery, where("business.id", "==", businessCode));
      } else if (supplierId) {
        orderQuery = query(orderQuery, where(`supplier.id`, "==", supplierId));
      } else if (Array.isArray(businessCodes) && businessCodes.length > 0) {
        if (Array.isArray(limitCodes) && limitCodes.length > 0) {
          orderQuery = query(
            orderQuery,
            where("business.id", "in", businessCodes.filter((code) => limitCodes.indexOf(code) >= 0).slice(0, 10))
          );
        } else {
          orderQuery = query(orderQuery, where("business.id", "in", businessCodes.slice(0, 10)));
        }
      } else if (Array.isArray(limitCodes) && limitCodes.length > 0) {
        orderQuery = query(orderQuery, where("business.id", "in", limitCodes.slice(0, 10)));
      }

      if (typeof startTime === "number" && typeof endTime === "number") {
        orderQuery = query(orderQuery, where("timestamp", ">=", startTime), where("timestamp", "<=", endTime));
      } else if (typeof startTime === "number") {
        orderQuery = query(orderQuery, where("timestamp", assend ? ">=" : "<=", startTime));
      } else if (typeof endTime === "number") {
        orderQuery = query(orderQuery, where("timestamp", "<=", endTime));
      }

      if (deliveryDate) {
        orderQuery = query(orderQuery, where("delivery.date", "==", deliveryDate));
      } else if (date) {
        orderQuery = query(orderQuery, where("date", "==", date));
      } else if (lastPayTime) {
        orderQuery = query(orderQuery, orderBy("lastPayTime", assend ? "asc" : "desc"));
      } else {
        orderQuery = query(orderQuery, orderBy("timestamp", assend ? "asc" : "desc"));
      }

      if (startDoc) {
        orderQuery = query(orderQuery, startAfter(startDoc), limit(limitCount || 20));
      } else if (endDoc) {
        orderQuery = query(orderQuery, endBefore(endDoc), limitToLast(limitCount || 20));
      } else {
        orderQuery = query(orderQuery, limit(limitCount || 20));
      }

      setLoading(true);
      return onSnapshot(orderQuery, (result) => {
        setOrders(result.docs);
        setLoading(false);
      });
    } else {
      setOrders(null);
    }
  }, [
    realmId,
    assend,
    search,
    orderNoOnly,
    awaitAction,
    JSON.stringify(awaitActions),
    customerId,
    orderType,
    startTime,
    endTime,
    businessCode,
    collectionPlace,
    supplierId,
    JSON.stringify(businessCodes),
    agentId,
    startDoc,
    endDoc,
    limitCount,
    creditNoteOnly,
    lastPayTime,
    date,
    deliveryDate,
    level,
    timeWindow,
    days
  ]);

  return [orders, loading];
};
