import { useAccessShiftManagement, useUser } from "@easybiz/component-shared";
import { GeoLocationBlocker } from "@easybiz/web-admin";
import { Input, Space } from "antd";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import CustomerScreenButton from "./CustomerScreenButton";
import NetworkStatusIcon from "./NetworkStatusIcon";
import POSMainMenuButton from "./POSMainMenuButton";
import SelectServiceButton from "./SelectServiceButton";
import ShopDrawer from "./ShopDrawer";
import StaffClockInOutButton from "./StaffClockInOutButton";

export default function ({ search, onSearch }) {
  const shiftEnabled = useAccessShiftManagement();
  const user = useUser();
  const [menuVisible, setMenuVisible] = useState(false);
  const intl = useIntl();

  return (
    <div className="flex flex-row padding-half">
      <Space>
        <POSMainMenuButton onClick={() => setMenuVisible(true)} />
        {shiftEnabled && <StaffClockInOutButton />}
        {user?.multiStation && <GeoLocationBlocker />}
      </Space>
      <div className="flex-fill padding-horizontal-half">
        <Input.Search
          placeholder={intl.formatMessage({ defaultMessage: "Search product" })}
          value={search}
          onChange={(e) => onSearch(e.target.value)}
        />
      </div>
      <Space>
        <NetworkStatusIcon />
        <SelectServiceButton />
        <CustomerScreenButton />
      </Space>
      <ShopDrawer open={menuVisible} onClose={() => setMenuVisible(false)} />
    </div>
  );
}
