import { CreditCardOutlined } from "@ant-design/icons";
import { usePOSIsDayClosed } from "@easybiz/hook";
import {
  useCashDrawerIsPendingStart,
  useCheckoutBusiness,
  useCheckoutClear,
  useCheckoutCustomer,
  useCheckoutEditItemSetter,
  useCheckoutItems,
  useCheckoutLineItemsSteps,
  useCheckoutOutstanding,
  useCheckoutParams,
  useCheckoutService,
  useClientCustomerScreen, useClientStateSetter, useRealmMemberSettings,
} from "@easybiz/component-shared";
import {
  BUSINESS_SECTOR_LAUNDRY,
  CUSTOMER_SCREEN_ORDER,
  DISPLAY_MODE_CASH_DRAWER,
  ORDER_SOURCE_POS,
  checkMissingStep,
  formatPointsPayment,
} from "@easybiz/utils";
import { PaymentDrawer } from "@easybiz/web-admin";
import { usePointsWalletDoc } from "@easybiz/web-firebase";
import { Button, message } from "antd";
import { collection, doc, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default function NewOrderPaymentButton() {
  const customer = useCheckoutCustomer();
  const businessCode = useCheckoutBusiness();
  const service = useCheckoutService();
  const pendingStart = useCashDrawerIsPendingStart();
  const dayClosed = usePOSIsDayClosed();
  const setEditItem = useCheckoutEditItemSetter();
  const clearCheckout = useCheckoutClear();
  const checkoutParams = useCheckoutParams();
  const customerScreen = useClientCustomerScreen();
  const setState = useClientStateSetter();
  const { returnSchedule, pricingTBD } = checkoutParams;
  const items = useCheckoutItems();
  const lineItemsSteps = useCheckoutLineItemsSteps();
  const intl = useIntl();
  const walletDoc = usePointsWalletDoc(customer?.id);
  const { pointToMoneyExRate } = useRealmMemberSettings();
  const totalBalance = useCheckoutOutstanding();
  const pointsOption = formatPointsPayment(pointToMoneyExRate, walletDoc?.get("point"), totalBalance);
  const [newOrder, setNewOrder] = useState();
  const newOrderOpened = Boolean(newOrder);
  const customerScreenPaired = customerScreen?.exists();

  useEffect(() => {
    if (customerScreenPaired) {
      updateDoc(customerScreen.ref, { mode: newOrderOpened ? CUSTOMER_SCREEN_ORDER : null });
    }
  }, [customerScreenPaired, newOrderOpened]);

  const onSubmit = () => {
    if (pendingStart) {
      message.info(
        intl.formatMessage({
          defaultMessage: `You haven't start cash drawer yet, please check your starting cash and process start drawer`,
        })
      );

      setState({ displayMode: { key: DISPLAY_MODE_CASH_DRAWER } });
      return;
    }

    if (!customer) return message.info(intl.formatMessage({ defaultMessage: "Please select customer" }));
    if (service?.sector === BUSINESS_SECTOR_LAUNDRY && !returnSchedule) {
      if (returnSchedule?.address) {
        return message.info(intl.formatMessage({ defaultMessage: "Please select delivery date" }));
      } else {
        return message.info(
          intl.formatMessage({
            defaultMessage: "Please select collection date",
          })
        );
      }
    }

    if (!pricingTBD) {
      const missing = checkMissingStep(service?.sector, lineItemsSteps, items);

      if (missing) {
        return setEditItem(missing);
      }
    }

    setNewOrder({
      totalBalance,
      orderId: doc(collection(getFirestore(), `orders`)).id,
      businessCode,
      serviceId: service.id,
      customer,
      checkoutParams,
      source: ORDER_SOURCE_POS,
    });
  };

  return (
    <>
      <Button block size="large" icon={<CreditCardOutlined />} type="primary" onClick={onSubmit} disabled={dayClosed}>
        <FormattedMessage tagName={"span"} defaultMessage="Payment" />
      </Button>
      <PaymentDrawer
        pointsOption={pointsOption}
        open={Boolean(newOrder)}
        newOrder={newOrder}
        customer={newOrder?.customer}
        onCancel={() => setNewOrder(null)}
        onSucceed={({ newOrder }) => {
          setNewOrder(null);
          clearCheckout(true);
          setState({ openOrderId: newOrder.id });
          if (customerScreen?.get("linking")) {
            setDoc(customerScreen.ref, { linking: customerScreen?.get("linking") });
          }
        }}
      />
    </>
  );
}
