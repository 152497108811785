"use client";

import {
  useClientInstallId,
  useClientStateSetter,
  useClientType,
  useClientVersion,
  useServerAction,
} from "@easybiz/component-shared";
import { SERVER_ACTION_DEVICE_REGISTER } from "@easybiz/lib-shared-auth";

// Init device register (Grant permissions)
export default function useServerActionRegisterDevice(signInWithCustomToken, getAuth, getDeviceInfo) {
  const installId = useClientInstallId();
  const setAppState = useClientStateSetter();
  const client = useClientType();
  const clientVersion = useClientVersion();
  const { loading, onCall } = useServerAction(SERVER_ACTION_DEVICE_REGISTER);

  return {
    loading,
    onSubmit: () => {
      console.log("RUN REGISTER");
      onCall(
        {
          ...(typeof getDeviceInfo === "function" ? getDeviceInfo() : {}),
          installId,
          client,
          clientVersion,
          betaRelease: Boolean(process.env.NEXT_PUBLIC_partial_release),
        },
        (response) => {
          const { token, notice, intercom } = response;

          if (token) {
            signInWithCustomToken(getAuth(), token);
          }

          if (notice) {
            setAppState({ notice });
          }

          // TODO: REMOVE INTERCOM
          if (intercom && typeof window !== "undefined" && window.Intercom) {
            window.Intercom("boot", {
              ...intercom,
              custom_launcher_selector: `#intercomLauncher`,
              hide_default_launcher: true,
            });
          }
        }
      );
    },
  };
}
