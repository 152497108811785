"use client";

import { coinRounding } from "@easybiz/lib-shared-order";
import { formatPrice } from "@easybiz/utils";
import { useMemo, useState } from "react";

function usePaymentDrawerState(newOrder, topUpOption, fullAmount, rounding) {
  const [payment, setPayment] = useState(undefined);
  const [pointsPayment, setPointsPayment] = useState(undefined);

  return useMemo(() => {
    let priceOverview;

    if (newOrder) {
      const totalBalance =
        (payment?.rounding
          ? coinRounding(newOrder.totalBalance || 0, { ...newOrder.checkoutParams?.rounding, ...payment.rounding })
          : newOrder.totalBalance) || 0;
      const totalPayable = pointsPayment ? formatPrice(totalBalance - pointsPayment.amount) : totalBalance;
      const totalOutstanding = payment?.amount ? formatPrice(totalPayable - payment?.amount) : totalPayable;

      priceOverview = {
        totalAmount: totalBalance,
        totalPayable,
        totalOutstanding,
      };
    } else if (topUpOption) {
      priceOverview = {
        totalPayable: topUpOption.amount,
        totalAmount: topUpOption.amount,
        totalOutstanding: formatPrice(topUpOption.amount - (payment?.amount || 0)),
      };
    } else {
      const totalPayable = payment?.rounding
        ? coinRounding(fullAmount || 0, { ...rounding, ...payment.rounding })
        : fullAmount;
      priceOverview = {
        totalPayable: totalPayable,
        totalAmount: totalPayable,
        totalOutstanding: formatPrice(totalPayable - (payment?.amount || 0)),
      };
    }

    return {
      ...priceOverview,
      payment,
      pointsPayment,
      setPayment,
      setPointsPayment,
    };
  }, [newOrder, topUpOption, fullAmount, payment, pointsPayment]);
}

export default usePaymentDrawerState;
