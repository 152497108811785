"use client";

import { useUserRealmId } from "@easybiz/component-shared";
import { collection, getFirestore, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";

export default (customerId, limitCount) => {
  const realmId = useUserRealmId();
  const [addresses, setAddresses] = useState(null);

  useEffect(() => {
    setAddresses(null);
    if (realmId && customerId) {
      return onSnapshot(
        query(
          collection(getFirestore(), `realms/${realmId}/addresses`),
          where("customer.id", "==", customerId),
          orderBy("sortIndex"),
          limit(limitCount || 10)
        ),
        (result) => setAddresses(result.docs)
      );
    }
  }, [realmId, customerId]);

  return addresses;
};
