export const TEMPLATE_CONTENT_TYPE_ORDER = "o";
export const TEMPLATE_CONTENT_TYPE_ITEM = "i";
export const TEMPLATE_CONTENT_TYPE_PACKAGE = "p";
export const TEMPLATE_CONTENT_TYPE_JOB = "J";
export const TEMPLATE_CONTENT_TYPE_CREDIT = "c";
export const TEMPLATE_CONTENT_TYPE_MEMBER = "m";
export const TEMPLATE_CONTENT_TYPE_REPORT = "r";
export const TEMPLATE_CONTENT_TYPE_HANDOVER = "ho";

// TipTap Node
export const NODE_TYPE_MENTION = "mention";
export const NODE_TYPE_TEXT = "text";
export const NODE_TYPE_PARAGRAPH = "paragraph";
export const MARK_TYPE_LINK = "link";

// Data set names
export const PLACEHOLDER_DATA_SET_COMPANY = "company";
export const PLACEHOLDER_DATA_SET_CUSTOMER = "customer";
export const PLACEHOLDER_DATA_SET_SALES_CHANNEL = "sales_channel";
export const PLACEHOLDER_DATA_SET_ORDER = "order";
export const PLACEHOLDER_DATA_SET_TRANSACTION = "transaction";
export const PLACEHOLDER_DATA_SET_JOB = "job";
export const PLACEHOLDER_DATA_SET_CREDIT = "credit";
export const PLACEHOLDER_DATA_SET_REWARD_POINTS = "reward_points";
export const PLACEHOLDER_DATA_SET_MEMBER = "member";
export const PLACEHOLDER_DATA_SET_OTP = "otp";
export const PLACEHOLDER_SUB_DATA_SET_LINE_ITEM = "order_line_item";
export const PLACEHOLDER_SUB_DATA_SET_PACKAGE = "order_package";
export const PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_SUB_SERVICE_OPTION = "order_line_item_sub_service_option";
export const PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_DETAIL = "order_line_item_detail";
export const PLACEHOLDER_SUB_DATA_SET_ADJUSTMENT_ITEM = "order_adjustment_item";

// Order
export const PLACEHOLDER_ORDER_ID = `${PLACEHOLDER_DATA_SET_ORDER}_id`;
export const PLACEHOLDER_ORDER_NO = `${PLACEHOLDER_DATA_SET_ORDER}_no`;
export const PLACEHOLDER_ORDER_BUSINESS_UNIT_NAME = `${PLACEHOLDER_DATA_SET_ORDER}_business_unit_name`;
export const PLACEHOLDER_ORDER_BUSINESS_UNIT_CODE = `${PLACEHOLDER_DATA_SET_ORDER}_business_unit_code`;
export const PLACEHOLDER_ORDER_BUSINESS_UNIT_ID = `${PLACEHOLDER_DATA_SET_ORDER}_business_unit_id`;
export const PLACEHOLDER_ORDER_SALES_CHANNEL_ID = `${PLACEHOLDER_DATA_SET_ORDER}_sales_channel_id`;
export const PLACEHOLDER_ORDER_SUBTOTAL = `${PLACEHOLDER_DATA_SET_ORDER}_subtotal`;
export const PLACEHOLDER_ORDER_TOTAL = `${PLACEHOLDER_DATA_SET_ORDER}_total`;
export const PLACEHOLDER_ORDER_TOTAL_WITH_OUT_TAX = `${PLACEHOLDER_DATA_SET_ORDER}_total_without_tax`;
export const PLACEHOLDER_ORDER_TAX_TYPE = `${PLACEHOLDER_DATA_SET_ORDER}_tax_type`;
export const PLACEHOLDER_ORDER_TAX_RATE = `${PLACEHOLDER_DATA_SET_ORDER}_tax_rate`;
export const PLACEHOLDER_ORDER_TAX_AMOUNT = `${PLACEHOLDER_DATA_SET_ORDER}_tax_amount`;
export const PLACEHOLDER_ORDER_AMOUNT_DUE = `${PLACEHOLDER_DATA_SET_ORDER}_balance_due`;
export const PLACEHOLDER_ORDER_AMOUNT_DUE_RAW = `${PLACEHOLDER_DATA_SET_ORDER}_balance_due_raw`;
export const PLACEHOLDER_ORDER_AMOUNT_PAID = `${PLACEHOLDER_DATA_SET_ORDER}_amount_paid`;
export const PLACEHOLDER_ORDER_BOOKING_SURCHARGE = `${PLACEHOLDER_DATA_SET_ORDER}_booking_surcharge`;
export const PLACEHOLDER_ORDER_LAST_PAYMENT_DATE = `${PLACEHOLDER_DATA_SET_ORDER}_last_payment_date`;
export const PLACEHOLDER_ORDER_STATUS = `${PLACEHOLDER_DATA_SET_ORDER}_status`;
export const PLACEHOLDER_ORDER_TOTAL_UNITS = `${PLACEHOLDER_DATA_SET_ORDER}_total_units`;
export const PLACEHOLDER_ORDER_TOTAL_PCS = `${PLACEHOLDER_DATA_SET_ORDER}_total_pcs`;
export const PLACEHOLDER_ORDER_TOTAL_PACKAGES = `${PLACEHOLDER_DATA_SET_ORDER}_total_packages`;
export const PLACEHOLDER_ORDER_MADE_DATE = `${PLACEHOLDER_DATA_SET_ORDER}_made_date`;
export const PLACEHOLDER_ORDER_MADE_WEEKDAY = `${PLACEHOLDER_DATA_SET_ORDER}_made_weekday`;
export const PLACEHOLDER_ORDER_MADE_WEEKDAY_INDEX = `${PLACEHOLDER_DATA_SET_ORDER}_made_weekday_index`;
export const PLACEHOLDER_ORDER_MADE_TIME = `${PLACEHOLDER_DATA_SET_ORDER}_made_time`;
export const PLACEHOLDER_ORDER_MADE_BY = `${PLACEHOLDER_DATA_SET_ORDER}_made_by`;
export const PLACEHOLDER_ORDER_CONFIRMED_AT = `${PLACEHOLDER_DATA_SET_ORDER}_confirmed_at`;
export const PLACEHOLDER_ORDER_CONFIRMED_BY = `${PLACEHOLDER_DATA_SET_ORDER}_confirmed_by`;
export const PLACEHOLDER_ORDER_REDO_ORDER_NO = `${PLACEHOLDER_DATA_SET_ORDER}_redo_order_no`;
export const PLACEHOLDER_ORDER_STORE_PICKUP_DATE = `${PLACEHOLDER_DATA_SET_ORDER}_store_pickup_date`;
export const PLACEHOLDER_ORDER_STORE_PICKUP_WEEKDAY = `${PLACEHOLDER_DATA_SET_ORDER}_store_pickup_weekday`;
export const PLACEHOLDER_ORDER_INVOICE_DATE = `${PLACEHOLDER_DATA_SET_ORDER}_invoice_date`;
export const PLACEHOLDER_ORDER_DUE_DATE = `${PLACEHOLDER_DATA_SET_ORDER}_due_date`;
export const PLACEHOLDER_ORDER_RETURN_TYPE = `${PLACEHOLDER_DATA_SET_ORDER}_return_type`;
export const PLACEHOLDER_ORDER_PICKUP_DATE = `${PLACEHOLDER_DATA_SET_ORDER}_pickup_date`;
export const PLACEHOLDER_ORDER_PICKUP_TIME = `${PLACEHOLDER_DATA_SET_ORDER}_pickup_time`;
export const PLACEHOLDER_ORDER_PICKUP_WEEKDAY = `${PLACEHOLDER_DATA_SET_ORDER}_pickup_weekday`;
export const PLACEHOLDER_ORDER_PICKUP_COMPLETE_AT = `${PLACEHOLDER_DATA_SET_ORDER}_pickup_complete_at`;
export const PLACEHOLDER_ORDER_PICKUP_OPTION_NAME = `${PLACEHOLDER_DATA_SET_ORDER}_pickup_option_name`;
export const PLACEHOLDER_ORDER_PICKUP_LEAD_TIME = `${PLACEHOLDER_DATA_SET_ORDER}_pickup_lead_time`;
export const PLACEHOLDER_ORDER_PICKUP_ADDRESS = `${PLACEHOLDER_DATA_SET_ORDER}_pickup_address`;
export const PLACEHOLDER_ORDER_PICKUP_WORKER = `${PLACEHOLDER_DATA_SET_ORDER}_pickup_worker`;
export const PLACEHOLDER_ORDER_PICKUP_NOTE = `${PLACEHOLDER_DATA_SET_ORDER}_pickup_note`;
export const PLACEHOLDER_ORDER_DELIVERY_DATE = `${PLACEHOLDER_DATA_SET_ORDER}_delivery_date`;
export const PLACEHOLDER_ORDER_DELIVERY_TIME = `${PLACEHOLDER_DATA_SET_ORDER}_delivery_time`;
export const PLACEHOLDER_ORDER_DELIVERY_WEEKDAY = `${PLACEHOLDER_DATA_SET_ORDER}_delivery_weekday`;
export const PLACEHOLDER_ORDER_DELIVERY_COMPLETE_AT = `${PLACEHOLDER_DATA_SET_ORDER}_delivery_complete_at`;
export const PLACEHOLDER_ORDER_DELIVERY_OPTION_NAME = `${PLACEHOLDER_DATA_SET_ORDER}_delivery_option_name`;
export const PLACEHOLDER_ORDER_DELIVERY_LEAD_TIME = `${PLACEHOLDER_DATA_SET_ORDER}_delivery_lead_time`;
export const PLACEHOLDER_ORDER_DELIVERY_ADDRESS = `${PLACEHOLDER_DATA_SET_ORDER}_delivery_address`;
export const PLACEHOLDER_ORDER_DELIVERY_WORKER = `${PLACEHOLDER_DATA_SET_ORDER}_delivery_worker`;
export const PLACEHOLDER_ORDER_DELIVERY_NOTE = `${PLACEHOLDER_DATA_SET_ORDER}_delivery_note`;
export const PLACEHOLDER_ORDER_PAYNOW_IMAGE_URL = `${PLACEHOLDER_DATA_SET_ORDER}_paynow_image_url`;
export const PLACEHOLDER_ORDER_PAYNOW_QR_CONTENT = `${PLACEHOLDER_DATA_SET_ORDER}_paynow_qr_content`;
// Order package
export const PLACEHOLDER_ORDER_PACKAGES = `${PLACEHOLDER_SUB_DATA_SET_PACKAGE}s`;
export const PLACEHOLDER_ORDER_PACKAGE_ID = `${PLACEHOLDER_SUB_DATA_SET_PACKAGE}_id`;
export const PLACEHOLDER_ORDER_PACKAGE_DATE = `${PLACEHOLDER_SUB_DATA_SET_PACKAGE}_date`;
export const PLACEHOLDER_ORDER_PACKAGE_ITEM_INDEXES = `${PLACEHOLDER_SUB_DATA_SET_PACKAGE}_item_indexes`;
export const PLACEHOLDER_ORDER_PACKAGE_LOCATION = `${PLACEHOLDER_SUB_DATA_SET_PACKAGE}_location`;
export const PLACEHOLDER_ORDER_PACKAGE_INDEX = `${PLACEHOLDER_SUB_DATA_SET_PACKAGE}_index`;
// Order line items
export const PLACEHOLDER_ORDER_LINE_ITEMS = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}s`;
export const PLACEHOLDER_ORDER_LINE_ITEM_ID = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}_id`;
export const PLACEHOLDER_ORDER_LINE_ITEM_PCS_INDEX = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}_pcs_index`;
export const PLACEHOLDER_ORDER_LINE_ITEM_UNIT_INDEX = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}_unit_index`;
export const PLACEHOLDER_ORDER_LINE_ITEM_TOTAL_PCS_PER_SET = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}_total_pcs_per_set`;
export const PLACEHOLDER_ORDER_LINE_ITEM_SET_PCS_INDEX = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}_set_pcs_index`;
export const PLACEHOLDER_ORDER_LINE_ITEM_NAME = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}_name`;
export const PLACEHOLDER_ORDER_LINE_ITEM_INDEXES = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}_indexes`;
export const PLACEHOLDER_ORDER_LINE_ITEM_SERVICE_OPTION_NAME = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}_service_option_name`;
export const PLACEHOLDER_ORDER_LINE_ITEM_SERVICE_OPTION_CODE = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}_service_option_code`;
export const PLACEHOLDER_ORDER_LINE_ITEM_QTY = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}_qty`;
export const PLACEHOLDER_ORDER_LINE_ITEM_UNIT = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}_unit`;
export const PLACEHOLDER_ORDER_LINE_ITEM_UNIT_PRICE = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}_unit_price`;
export const PLACEHOLDER_ORDER_LINE_ITEM_TOTAL = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}_total`;
export const PLACEHOLDER_ORDER_LINE_ITEM_COMPLETION_DATE = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}_completion_date`;
export const PLACEHOLDER_ORDER_LINE_ITEM_ADD_ONS = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}_add_ons`;
export const PLACEHOLDER_ORDER_LINE_ITEM_ADD_ON_NAMES = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}_add_on_names`;
export const PLACEHOLDER_ORDER_LINE_ITEM_ADD_ON_CODES = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}_add_on_codes`;
export const PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORIES = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}_accessories`;
export const PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_NAMES = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}_accessory_names`;
export const PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_CODES = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}_accessory_codes`;
export const PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_INDEX = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}_accessory_index`;
export const PLACEHOLDER_ORDER_LINE_ITEM_PHOTO = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}_photo`;
export const PLACEHOLDER_ORDER_LINE_ITEM_NOTE = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM}_note`;
export const PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTIONS = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_SUB_SERVICE_OPTION}s`;
export const PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_NAME = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_SUB_SERVICE_OPTION}_name`;
export const PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_CODE = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_SUB_SERVICE_OPTION}_code`;
export const PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_PRICE = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_SUB_SERVICE_OPTION}_price`;
export const PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_DISCOUNT = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_SUB_SERVICE_OPTION}_discount`;
export const PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_NOTE = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_SUB_SERVICE_OPTION}_note`;
export const PLACEHOLDER_ORDER_LINE_ITEM_DETAILS = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_DETAIL}s`;
export const PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_NAME = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_DETAIL}_name`;
export const PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_CODE = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_DETAIL}_code`;
export const PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_CONTENT = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_DETAIL}_content`;
export const PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_NOTE = `${PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_DETAIL}_note`;
export const PLACEHOLDER_ORDER_ADJUSTMENT_ITEMS = `${PLACEHOLDER_SUB_DATA_SET_ADJUSTMENT_ITEM}s`;
export const PLACEHOLDER_ORDER_ADJUSTMENT_ITEM_DESCRIPTION = `${PLACEHOLDER_SUB_DATA_SET_ADJUSTMENT_ITEM}_description`;
export const PLACEHOLDER_ORDER_ADJUSTMENT_ITEM_AMOUNT = `${PLACEHOLDER_SUB_DATA_SET_ADJUSTMENT_ITEM}_amount`;
export const PLACEHOLDER_ORDER_PICKUP_STORE_NAME = `${PLACEHOLDER_DATA_SET_ORDER}_pickup_store_name`;
export const PLACEHOLDER_ORDER_PICKUP_STORE_ADDRESS = `${PLACEHOLDER_DATA_SET_ORDER}_pickup_store_address`;
export const PLACEHOLDER_ORDER_PICKUP_STORE_PHONE_NUMBER = `${PLACEHOLDER_DATA_SET_ORDER}_pickup_store_phone`;
export const PLACEHOLDER_ORDER_PICKUP_STORE_OPENING_HOURS = `${PLACEHOLDER_DATA_SET_ORDER}_pickup_store_opening_hours`;
export const PLACEHOLDER_ORDER_PAYMENTS = `${PLACEHOLDER_DATA_SET_ORDER}_payments`;
export const PLACEHOLDER_ORDER_NOTE = `${PLACEHOLDER_DATA_SET_ORDER}_note`;

// Handover
export const PLACEHOLDER_HANDOVER_DATE = "handover_date";
export const PLACEHOLDER_HANDOVER_TIME = "handover_time";
export const PLACEHOLDER_HANDOVER_TYPE = "handover_type";
export const PLACEHOLDER_HANDOVER_BY = "handover_by";
export const PLACEHOLDER_HANDOVER_TOTAL_ORDERS = "handover_total_orders";
export const PLACEHOLDER_HANDOVER_TOTAL_PCS = "handover_total_pcs";
export const PLACEHOLDER_HANDOVER_TOTAL_AMOUNT = "handover_total_amount";
export const PLACEHOLDER_HANDOVER_ORDERS = `handover_orders`;

// Payment & Refund
export const PLACEHOLDER_TRANSACTION_ID = `${PLACEHOLDER_DATA_SET_TRANSACTION}_id`;
export const PLACEHOLDER_TRANSACTION_DESCRIPTION = `${PLACEHOLDER_DATA_SET_TRANSACTION}_description`;
export const PLACEHOLDER_TRANSACTION_AMOUNT = `${PLACEHOLDER_DATA_SET_TRANSACTION}_amount`;
export const PLACEHOLDER_TRANSACTION_DATE = `${PLACEHOLDER_DATA_SET_TRANSACTION}_date`;
export const PLACEHOLDER_TRANSACTION_TIME = `${PLACEHOLDER_DATA_SET_TRANSACTION}_time`;
export const PLACEHOLDER_TRANSACTION_METHOD = `${PLACEHOLDER_DATA_SET_TRANSACTION}_method`;
export const PLACEHOLDER_TRANSACTION_MADE_BY = `${PLACEHOLDER_DATA_SET_TRANSACTION}_made_by`;
export const PLACEHOLDER_TRANSACTION_NOTE = `${PLACEHOLDER_DATA_SET_TRANSACTION}_note`;
export const PLACEHOLDER_TRANSACTION_BALANCE_BEFORE = `${PLACEHOLDER_DATA_SET_TRANSACTION}_balance_before`;
export const PLACEHOLDER_TRANSACTION_BALANCE_AFTER = `${PLACEHOLDER_DATA_SET_TRANSACTION}_balance_after`;
// Job
export const PLACEHOLDER_JOB_ID = `${PLACEHOLDER_DATA_SET_JOB}_id`;
export const PLACEHOLDER_JOB_TIME = `${PLACEHOLDER_DATA_SET_JOB}_time`;
export const PLACEHOLDER_JOB_ADDRESS = `${PLACEHOLDER_DATA_SET_JOB}_address`;
export const PLACEHOLDER_JOB_TYPE = `${PLACEHOLDER_DATA_SET_JOB}_type`;
export const PLACEHOLDER_JOB_WORKER = `${PLACEHOLDER_DATA_SET_JOB}_worker`;
export const PLACEHOLDER_JOB_SERVICE_TYPES = `${PLACEHOLDER_DATA_SET_JOB}_service_types`;
export const PLACEHOLDER_JOB_NOTE = `${PLACEHOLDER_DATA_SET_JOB}_note`;
// Sales channel
export const PLACEHOLDER_SALES_CHANNEL_NAME = `${PLACEHOLDER_DATA_SET_SALES_CHANNEL}_name`;
export const PLACEHOLDER_SALES_CHANNEL_CODE = `${PLACEHOLDER_DATA_SET_SALES_CHANNEL}_code`;
export const PLACEHOLDER_SALES_CHANNEL_ADDRESS = `${PLACEHOLDER_DATA_SET_SALES_CHANNEL}_address`;
export const PLACEHOLDER_SALES_CHANNEL_PHONE_NUMBER = `${PLACEHOLDER_DATA_SET_SALES_CHANNEL}_phone_number`;
export const PLACEHOLDER_SALES_CHANNEL_OPENING_HOURS = `${PLACEHOLDER_DATA_SET_SALES_CHANNEL}_opening_hours`;
export const PLACEHOLDER_SALES_CHANNEL_DOMAIN = `${PLACEHOLDER_DATA_SET_SALES_CHANNEL}_domain`;
// Loyalty
export const PLACEHOLDER_CREDIT_BALANCE = `${PLACEHOLDER_DATA_SET_CREDIT}_balance`;
export const PLACEHOLDER_REWARD_POINTS_BALANCE = `${PLACEHOLDER_DATA_SET_REWARD_POINTS}_balance`;
export const PLACEHOLDER_MEMBER_TITLE = `${PLACEHOLDER_DATA_SET_MEMBER}_title`;
export const PLACEHOLDER_MEMBER_BENEFITS = `${PLACEHOLDER_DATA_SET_MEMBER}_benefits`;
export const PLACEHOLDER_MEMBER_JOIN_DATE = `${PLACEHOLDER_DATA_SET_MEMBER}_join_date`;
export const PLACEHOLDER_MEMBER_DURATION = `${PLACEHOLDER_DATA_SET_MEMBER}_duration`;
export const PLACEHOLDER_MEMBER_EXPIRY_DATE = `${PLACEHOLDER_DATA_SET_MEMBER}_expiry_date`;
export const PLACEHOLDER_MEMBER_FEE = `${PLACEHOLDER_DATA_SET_MEMBER}_payment_fee`;
// Customer
export const PLACEHOLDER_CUSTOMER_ID = `${PLACEHOLDER_DATA_SET_CUSTOMER}_id`;
export const PLACEHOLDER_CUSTOMER_INDEX = `${PLACEHOLDER_DATA_SET_CUSTOMER}_index`;
export const PLACEHOLDER_CUSTOMER_NAME = `${PLACEHOLDER_DATA_SET_CUSTOMER}_name`;
export const PLACEHOLDER_CUSTOMER_EMAIL = `${PLACEHOLDER_DATA_SET_CUSTOMER}_email`;
export const PLACEHOLDER_CUSTOMER_PHONE_NUMBER = `${PLACEHOLDER_DATA_SET_CUSTOMER}_phone_number`;
export const PLACEHOLDER_CUSTOMER_PRIVATE_NOTE = `${PLACEHOLDER_DATA_SET_CUSTOMER}_private_note`;
export const PLACEHOLDER_CUSTOMER_ADDRESS = `${PLACEHOLDER_DATA_SET_CUSTOMER}_address`;
// Company
export const PLACEHOLDER_COMPANY_BRAND_NAME = `${PLACEHOLDER_DATA_SET_COMPANY}_brand_name`;
export const PLACEHOLDER_COMPANY_LEGAL_NAME = `${PLACEHOLDER_DATA_SET_COMPANY}_legal_name`;
export const PLACEHOLDER_COMPANY_LOGO = `${PLACEHOLDER_DATA_SET_COMPANY}_logo`;
export const PLACEHOLDER_COMPANY_ADDRESS = `${PLACEHOLDER_DATA_SET_COMPANY}_address`;
export const PLACEHOLDER_COMPANY_EMAIL = `${PLACEHOLDER_DATA_SET_COMPANY}_email`;
export const PLACEHOLDER_COMPANY_WEBSITE = `${PLACEHOLDER_DATA_SET_COMPANY}_website`;
export const PLACEHOLDER_COMPANY_PHONE_NUMBER = `${PLACEHOLDER_DATA_SET_COMPANY}_phone_number`;
export const PLACEHOLDER_COMPANY_REGISTRATION_NUMBER = `${PLACEHOLDER_DATA_SET_COMPANY}_registration_number`;
export const PLACEHOLDER_COMPANY_THEME = `${PLACEHOLDER_DATA_SET_COMPANY}_theme`;
// OTP
export const PLACEHOLDER_OTP = `${PLACEHOLDER_DATA_SET_OTP}`;
export const PLACEHOLDER_OTP_PREFIX = `${PLACEHOLDER_DATA_SET_OTP}_prefix`;
// Others
export const PLACEHOLDER_SIGNATURE = `signature`;
export const PLACEHOLDER_CURRENT_YEAR = "current_year";
export const PLACEHOLDER_PAGE_NUMBER = "page_number";
export const PLACEHOLDER_TOTAL_PAGES = "total_pages";

// Content assert
export const CONDITION_TYPE_IS = "is";
export const CONDITION_TYPE_IS_ANY_OF = "is_any_of";
export const CONDITION_TYPE_IS_GREATER_THAN = "is_greater_than";
export const CONDITION_TYPE_IS_LESS_THAN = "is_less_than";

export const WHATSAPP_EXAMPLE_TEXT_PLACEHOLDER_MAP = {
  John: PLACEHOLDER_CUSTOMER_NAME,
  "your email address": PLACEHOLDER_CUSTOMER_EMAIL,
  "Good Brand": PLACEHOLDER_COMPANY_BRAND_NAME,
};

export const DISPLAY_STATUS_PENDING_CONFIRM = "PENDING CONFIRM";
export const DISPLAY_STATUS_PAID = "PAID";
export const DISPLAY_STATUS_UNPAID = "UNPAID";
export const DISPLAY_STATUS_CANCELLED = "CANCELLED";
export const DISPLAY_STATUS_REDO = "REDO";
export const DISPLAY_TYPE_HOME_DELIVERY = "Home delivery";
export const DISPLAY_TYPE_STORE_PICKUP = "Store pickup";

export const ORDER_PLACEHOLDERS = [
  PLACEHOLDER_ORDER_NO,
  PLACEHOLDER_ORDER_ID,
  PLACEHOLDER_ORDER_BUSINESS_UNIT_NAME,
  PLACEHOLDER_ORDER_BUSINESS_UNIT_CODE,
  PLACEHOLDER_ORDER_MADE_DATE,
  PLACEHOLDER_ORDER_MADE_TIME,
  PLACEHOLDER_ORDER_MADE_WEEKDAY,
  PLACEHOLDER_ORDER_MADE_WEEKDAY_INDEX,
  PLACEHOLDER_ORDER_MADE_BY,
  PLACEHOLDER_ORDER_CONFIRMED_AT,
  PLACEHOLDER_ORDER_CONFIRMED_BY,
  PLACEHOLDER_ORDER_INVOICE_DATE,
  PLACEHOLDER_ORDER_DUE_DATE,
  PLACEHOLDER_ORDER_RETURN_TYPE,
  PLACEHOLDER_ORDER_STATUS,
  PLACEHOLDER_ORDER_SUBTOTAL,
  PLACEHOLDER_ORDER_TOTAL,
  PLACEHOLDER_ORDER_TOTAL_WITH_OUT_TAX,
  PLACEHOLDER_ORDER_TAX_TYPE,
  PLACEHOLDER_ORDER_TAX_RATE,
  PLACEHOLDER_ORDER_TAX_AMOUNT,
  PLACEHOLDER_ORDER_AMOUNT_PAID,
  PLACEHOLDER_ORDER_AMOUNT_DUE,
  PLACEHOLDER_ORDER_TOTAL_UNITS,
  PLACEHOLDER_ORDER_TOTAL_PCS,
  PLACEHOLDER_ORDER_TOTAL_UNITS,
  PLACEHOLDER_ORDER_TOTAL_PACKAGES,
  PLACEHOLDER_ORDER_NOTE,
  PLACEHOLDER_ORDER_STORE_PICKUP_DATE,
  PLACEHOLDER_ORDER_STORE_PICKUP_WEEKDAY,
  PLACEHOLDER_ORDER_PICKUP_STORE_NAME,
  PLACEHOLDER_ORDER_PICKUP_STORE_ADDRESS,
  PLACEHOLDER_ORDER_PICKUP_STORE_PHONE_NUMBER,
  PLACEHOLDER_ORDER_PICKUP_DATE,
  PLACEHOLDER_ORDER_PICKUP_TIME,
  PLACEHOLDER_ORDER_PICKUP_WEEKDAY,
  PLACEHOLDER_ORDER_PICKUP_COMPLETE_AT,
  PLACEHOLDER_ORDER_PICKUP_ADDRESS,
  PLACEHOLDER_ORDER_PICKUP_WORKER,
  PLACEHOLDER_ORDER_PICKUP_NOTE,
  PLACEHOLDER_ORDER_DELIVERY_DATE,
  PLACEHOLDER_ORDER_DELIVERY_TIME,
  PLACEHOLDER_ORDER_DELIVERY_WEEKDAY,
  PLACEHOLDER_ORDER_DELIVERY_COMPLETE_AT,
  PLACEHOLDER_ORDER_DELIVERY_OPTION_NAME,
  PLACEHOLDER_ORDER_DELIVERY_ADDRESS,
  PLACEHOLDER_ORDER_DELIVERY_WORKER,
  PLACEHOLDER_ORDER_DELIVERY_NOTE,
  PLACEHOLDER_ORDER_REDO_ORDER_NO,
];

export const HANDOVER_PLACEHOLDERS = [
  PLACEHOLDER_HANDOVER_DATE,
  PLACEHOLDER_HANDOVER_TIME,
  PLACEHOLDER_HANDOVER_TYPE,
  PLACEHOLDER_HANDOVER_BY,
  PLACEHOLDER_HANDOVER_TOTAL_ORDERS,
  PLACEHOLDER_HANDOVER_TOTAL_PCS,
  PLACEHOLDER_HANDOVER_TOTAL_AMOUNT
];

export const LABEL_ITEM_PLACEHOLDERS = [
  PLACEHOLDER_ORDER_LINE_ITEM_NAME,
  PLACEHOLDER_ORDER_LINE_ITEM_SERVICE_OPTION_NAME,
  PLACEHOLDER_ORDER_LINE_ITEM_SERVICE_OPTION_CODE,
  PLACEHOLDER_ORDER_LINE_ITEM_UNIT,
  PLACEHOLDER_ORDER_LINE_ITEM_COMPLETION_DATE,
  PLACEHOLDER_ORDER_LINE_ITEM_ADD_ON_NAMES,
  PLACEHOLDER_ORDER_LINE_ITEM_ADD_ON_CODES,
  PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_NAMES,
  PLACEHOLDER_ORDER_LINE_ITEM_NOTE,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_NAME,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_CODE,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_NOTE,
  PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_NAME,
  PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_CODE,
  PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_CONTENT,
  PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_NOTE,
];

export const LINE_ITEM_PLACEHOLDERS = [
  PLACEHOLDER_ORDER_LINE_ITEM_NAME,
  PLACEHOLDER_ORDER_LINE_ITEM_INDEXES,
  PLACEHOLDER_ORDER_LINE_ITEM_SERVICE_OPTION_NAME,
  PLACEHOLDER_ORDER_LINE_ITEM_SERVICE_OPTION_CODE,
  PLACEHOLDER_ORDER_LINE_ITEM_QTY,
  PLACEHOLDER_ORDER_LINE_ITEM_UNIT,
  PLACEHOLDER_ORDER_LINE_ITEM_UNIT_PRICE,
  PLACEHOLDER_ORDER_LINE_ITEM_TOTAL,
  PLACEHOLDER_ORDER_LINE_ITEM_COMPLETION_DATE,
  PLACEHOLDER_ORDER_LINE_ITEM_ADD_ONS,
  PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORIES,
  PLACEHOLDER_ORDER_LINE_ITEM_PHOTO,
  PLACEHOLDER_ORDER_LINE_ITEM_NOTE,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_NAME,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_CODE,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_PRICE,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_DISCOUNT,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_NOTE,
  PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_NAME,
  PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_CODE,
  PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_CONTENT,
  PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_NOTE,
];

export const CUSTOMER_PLACEHOLDERS = [
  PLACEHOLDER_CUSTOMER_NAME,
  PLACEHOLDER_CUSTOMER_INDEX,
  PLACEHOLDER_CUSTOMER_EMAIL,
  PLACEHOLDER_CUSTOMER_PHONE_NUMBER,
  PLACEHOLDER_CUSTOMER_PRIVATE_NOTE,
  PLACEHOLDER_CUSTOMER_ADDRESS,
];

export const SALES_CHANNEL_PLACEHOLDERS = [
  PLACEHOLDER_SALES_CHANNEL_NAME,
  PLACEHOLDER_SALES_CHANNEL_CODE,
  PLACEHOLDER_SALES_CHANNEL_ADDRESS,
  PLACEHOLDER_SALES_CHANNEL_PHONE_NUMBER,
  PLACEHOLDER_SALES_CHANNEL_OPENING_HOURS,
];
