import { CloseOutlined } from "@ant-design/icons";
import { useCheckoutClear, useCheckoutEditOrder, useCheckoutIsDirty, useCheckoutEditOrderSetter } from "@easybiz/component-shared";
import { IconButton, Title } from "@easybiz/web-admin";
import { Popconfirm } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

export default () => {
  const isDirty = useCheckoutIsDirty();
  const onClearCheckout = useCheckoutClear();
  const editOrder = useCheckoutEditOrder();
  const setConfirmOrder = useCheckoutEditOrderSetter();
  const [confirmVisible, setConfirnmVisible] = useState(false);

  return (
    <Popconfirm
      open={confirmVisible}
      onOpenChange={setConfirnmVisible}
      title={
        <Title>
          <FormattedMessage
            defaultMessage={`Order {orderNo} have unsaved changes, do you want to discard?`}
            values={{ orderNo: `#${editOrder?.get("orderNo")}` }}
          />
        </Title>
      }
      cancelButtonProps={{ size: "large" }}
      okButtonProps={{ size: "large", danger: true }}
      okText={<FormattedMessage defaultMessage={"Discard changes"} />}
      onConfirm={() => {
        onClearCheckout();
        setConfirnmVisible(false);
        setConfirmOrder(null);
      }}
    >
      <IconButton
        size="large"
        icon={<CloseOutlined />}
        danger
        onClick={() => {
          if (isDirty) {
            setConfirnmVisible(true);
          } else {
            setConfirmOrder(null);
          }
        }}
      />
    </Popconfirm>
  );
};
