import { LogoutOutlined } from "@ant-design/icons";
import { useServerAction } from "@easybiz/component-shared";
import { SERVER_ACTION_POS_CLOCK_OUT } from "@easybiz/lib-shared-auth";
import { useUser } from "@easybiz/component-shared";
import { Button } from "antd";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import React from "react";
import { FormattedMessage } from "react-intl";

export default ({ block, iconOnly }) => {
  const user = useUser();
  const { loading, onCall } = useServerAction(SERVER_ACTION_POS_CLOCK_OUT);

  return (
    <Button
      size="large"
      disabled={!user?.checkInStaff}
      icon={<LogoutOutlined />}
      block={block}
      danger
      loading={loading}
      onClick={() => {
        onCall({}, (response) => {
          if (response.token) {
            signInWithCustomToken(getAuth(), response.token);
          }
        });
      }}
    >
      {iconOnly ? null : <FormattedMessage tagName="span" defaultMessage="Clock out" />}
    </Button>
  );
};
