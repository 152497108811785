import { LoadingOutlined, PrinterOutlined, SettingOutlined } from "@ant-design/icons";
import { usePrinter } from "@easybiz/hook";
import { useClientRegisterDevice, useFeedbackToast, usePrintingConnectPrinter, useUserDebugMode } from "@easybiz/component-shared";
import { PRINTER_TYPE_RECEIPT } from "@easybiz/utils";
import { Button, Space } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

export default ({ disabled, lines, type, mutiLines, hideSetting, text }) => {
  const printer = usePrinter();
  const registerDevice = useClientRegisterDevice();
  const platformMode = useUserDebugMode();
  const connectPrinter = usePrintingConnectPrinter();
  const [printing, setPrinting] = useState(false);

  const toast = useFeedbackToast();

  const onPrint = async () => {
    try {
      setPrinting(true);

      await printer.print({ lines, mutiLines });
    } catch (err) {
      toast.error(err);
    } finally {
      setPrinting(false);
    }
  };

  const printButton = (
    <Button
      disabled={disabled}
      type={type}
      loading={printing}
      icon={registerDevice || platformMode ? <PrinterOutlined /> : <LoadingOutlined />}
      onClick={onPrint}
    >
      {text || <FormattedMessage tagName="span" defaultMessage="Print" />}
      {Array.isArray(mutiLines) ? `(${mutiLines.length})` : null}
    </Button>
  );

  return hideSetting ? (
    printButton
  ) : (
    <Space>
      {printButton}
      <Button
        icon={<SettingOutlined />}
        type="text"
        onClick={() => connectPrinter({ printerType: PRINTER_TYPE_RECEIPT, lines })}
      />
    </Space>
  );
};
