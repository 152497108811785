export const BUSINESS_SECTOR_LAUNDRY = "LAUNDRY";
export const BUSINESS_SECTOR_ALTERATION = "ALTERATION";
export const BUSINESS_SECTOR_BAG_SERVICE = "BAG SERVICE";
export const BUSINESS_SECTOR_AIRCON_SERVICE = "AIRCON SERVICE";
export const BUSINESS_SECTOR_CLEANING = "HOME CLEANING";
export const BUSINESS_SECTOR_HAIR_SALON = "HAIR SALON";
export const BUSINESS_SECTOR_MASSAGE = "MASSAGE";
export const BUSINESS_SECTOR_BEAUTY_SHOP = "BEAUTY SHOP";
export const BUSINESS_SECTOR_YOGA_FITNESS = "YOGA FITNESS";
export const BUSINESS_SECTOR_PEST_CONTROL = "PEST CONTROL";
export const BUSINESS_SECTOR_HANDYMAN = "HANDYMAN";