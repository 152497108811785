import {
  PROCEDURE_DISCHARGED,
  PROCEDURE_DRIVER_COLLECTED,
  PROCEDURE_PENDING_COLLECTION,
  PROCEDURE_PLANT_CHECK_IN,
  PROCEDURE_PLANT_CHECK_OUT,
  PROCEDURE_PLANT_CONFIRM_ORDER,
  PROCEDURE_PLANT_LABELING,
  PROCEDURE_PLANT_PACKING,
  PROCEDURE_PLANT_SERVICE_COMPLETE,
  PROCEDURE_PLANT_SERVICING,
  PROCEDURE_STORE_CHECK_OUT,
  PROCEDURE_STORE_CHECK_IN,
  PROCEDURE_STORE_RECEIVED,
} from "./constants/procedure.js";

export default (procedure) => {
  switch (procedure) {
    case PROCEDURE_PENDING_COLLECTION:
      return "Door collected";
    case PROCEDURE_DRIVER_COLLECTED:
      return "Door collected";
    case PROCEDURE_STORE_RECEIVED:
      return "Store received";
    case PROCEDURE_PLANT_LABELING:
      return "Labeled";
    case PROCEDURE_STORE_CHECK_OUT:
      return "Store check out";
    case PROCEDURE_PLANT_CHECK_IN:
      return "Production check in";
    case PROCEDURE_PLANT_CONFIRM_ORDER:
      return "Cofirm items";
    case PROCEDURE_PLANT_SERVICING:
      return "Processed";
    case PROCEDURE_PLANT_SERVICE_COMPLETE:
      return "Service complete";
    case PROCEDURE_PLANT_PACKING:
      return "Packed";
    case PROCEDURE_PLANT_CHECK_OUT:
      return "Production check out";
    case PROCEDURE_STORE_CHECK_IN:
      return "Store check in";
    case PROCEDURE_DISCHARGED:
      return "Discharge to customer";
    default:
      return null;
  }
};
