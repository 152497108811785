import { CalendarOutlined, CheckOutlined } from "@ant-design/icons";
import { DateDisplay, useCustomerForm } from "@easybiz/component";
import { useRealmPOSSettings } from "@easybiz/component-shared";
import { ACCOUNT_FIELD_ADDRESS, ACCOUNT_FIELD_BIRTHDAY, ACCOUNT_FIELD_EMAIL, ACCOUNT_FIELD_NAME, ACCOUNT_FIELD_PHONE_NUMBER, ACCOUNT_FIELD_PRIVATE_NOTE, ACCOUNT_FIELD_REFERRAL_SOURCE } from "@easybiz/utils";
import { useCustomerDoc } from "@easybiz/web-firebase";
import { Alert, Button, Checkbox, Form, Input, Space } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import ContactsInput from "../ContactsInput";
import BirthdayInputDialog from "./BirthdayInputDialog";

export default ({ customerId, editOrderId, onSaved }) => {
  const customerDoc = useCustomerDoc(customerId);
  const [birthdayVisible, setBirthdayVisible] = useState(false);
  const {
    profile,
    onChange,
    name,
    tel,
    email,
    nameChangeAlert,
    birthday,
    whereFindUs,
    privateNote,
    submitButton,
    loading,
    onSubmit,
  } = useCustomerForm(customerDoc, onSaved, editOrderId);
  const { accountFields } = useRealmPOSSettings();

  return (
    <>
      <Form layout="horizontal" labelCol={{ span: 5 }} wrapperCol={{ span: 18 }}>
        {
          accountFields?.map(field => {
            switch(field.id) {
              case ACCOUNT_FIELD_NAME:
                return (
                  <Form.Item label={name?.label}>
                    <Space direction="vertical" className="full-width">
                      <Input
                        size="large"
                        autoFocus
                        disabled={loading}
                        value={profile?.name}
                        onChange={(e) => onChange({ name: e.target.value })}
                      />
                      {nameChangeAlert && <Alert type="warning" showIcon={false} message={nameChangeAlert} />}
                    </Space>
                  </Form.Item>
                );
              case ACCOUNT_FIELD_PHONE_NUMBER:
                return (
                  <Form.Item label={tel?.label}>
                    <ContactsInput disabled={loading} tels={profile?.tels} onChange={(tels) => onChange({ tels })} />
                  </Form.Item>
                );
              case ACCOUNT_FIELD_EMAIL:
                return (
                  <Form.Item label={email?.label}>
                    <Input
                      size="large"
                      type="email"
                      disabled={loading}
                      placeholder={email.placeholder}
                      value={profile?.email}
                      onChange={(e) => onChange({ email: e.target.value.trim() })}
                      suffix={
                        email.verified && (
                          <span style={{ color: "green" }}>
                            <CheckOutlined style={{ color: "green" }} />{" "}
                            <FormattedMessage tagName="span" defaultMessage="Verified" />
                          </span>
                        )
                      }
                    />
                  </Form.Item>
                );
              case ACCOUNT_FIELD_ADDRESS:
                return (
                  <Form.Item label={<FormattedMessage tagName="span" defaultMessage="Address" />}>
                    <Input
                      size="large"
                      value={profile?.address}
                      disabled={loading}
                      onChange={(e) => onChange({ address: e.target.value })}
                    />
                  </Form.Item>
                );
              case ACCOUNT_FIELD_PRIVATE_NOTE:
                return (
                  <Form.Item key="remark" label={privateNote?.label}>
                    <Input.TextArea
                      size="large"
                      disabled={loading}
                      value={profile?.remarks}
                      onChange={(e) => onChange({ remarks: e.target.value })}
                      placeholder={privateNote?.placeholder}
                    />
                  </Form.Item>
                );
              case ACCOUNT_FIELD_BIRTHDAY:
                return (
                  <Form.Item label={birthday?.label} span={2}>
                    <Button
                      size="large"
                      disabled={loading}
                      onClick={() => setBirthdayVisible(true)}
                      icon={<CalendarOutlined />}
                    >
                      {profile?.birthday ? (
                        <span>
                          <DateDisplay date={profile?.birthday} />
                        </span>
                      ) : (
                        <FormattedMessage tagName="span" defaultMessage="Set" />
                      )}
                    </Button>
                  </Form.Item>
                );
              case ACCOUNT_FIELD_REFERRAL_SOURCE:
                return (
                  <Form.Item label={whereFindUs?.label} span={2}>
                    <Space wrap size={"middle"}>
                      {whereFindUs?.options.map((option) => {
                        const checked = profile?.knownSource === option;
        
                        return (
                          <Checkbox
                            key={option}
                            value={option}
                            checked={checked}
                            disabled={loading}
                            onChange={() => onChange({ knownSource: checked ? null : option })}
                          >
                            {option}
                          </Checkbox>
                        );
                      })}
                    </Space>
                  </Form.Item>
                );
            }
          })
        }
      </Form>
      <div className="padding-right">
        <Button type="primary" block onClick={onSubmit} loading={loading}>
          {submitButton}
        </Button>
      </div>
      <BirthdayInputDialog
        birthday={profile?.birthday}
        open={birthdayVisible}
        onCancel={() => setBirthdayVisible(false)}
        onSave={(birthday) => {
          onChange({ birthday });
          setBirthdayVisible(false);
        }}
      />
    </>
  );
};
