import { NameDisplay } from "@easybiz/component";
import { useClientState, useClientStateSetter, useRealmSupplierIds, useUserBusinessCode } from "@easybiz/component-shared";
import { ProductionMonitorPanel } from "@easybiz/web-admin";
import { Segmented } from "antd";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import PanelHeader from "./PanelHeader";

export default function ({ date, onClose }) {
  const businessCode = useUserBusinessCode();
  const supplierIds = useRealmSupplierIds();
  const { displayMode } = useClientState();
  const setState = useClientStateSetter();
  const supplierId = displayMode?.supplierId;

  useEffect(() => {
    if (supplierIds && supplierIds[0] && (!supplierId || supplierIds.indexOf(supplierId) < 0)) {
      setState({ displayMode: { ...displayMode, supplierId: supplierIds[0] } });
    }
  }, [displayMode, supplierId, supplierIds]);

  return (
    <>
      <PanelHeader
        title={
          <span>
            <FormattedMessage defaultMessage={"Production Monitor"} />
            {supplierIds?.length === 1 ? (
              <b>
                {" "}
                (<NameDisplay nameKey={supplierId} />)
              </b>
            ) : null}
          </span>
        }
        onClose={onClose}
      />
      {supplierIds?.length > 1 && (
        <div className="padding-half">
          <Segmented
            block
            value={supplierId}
            onChange={(supplierId) => setState({ displayMode: { ...displayMode, supplierId } })}
            options={supplierIds.map((supplierId) => ({
              label: <NameDisplay nameKey={supplierId} />,
              value: supplierId,
            }))}
          />
        </div>
      )}
      <div className="flex flex-fill self-scroll padding-horizontal-half">
        <ProductionMonitorPanel date={date} supplierId={supplierId} businessCode={businessCode} />
      </div>
    </>
  );
}
