import { useCheckoutEditItemSetter, useCheckoutService } from "@easybiz/component-shared";
import { BUSINESS_SECTOR_ALTERATION, BUSINESS_SECTOR_BAG_SERVICE, BUSINESS_SECTOR_LAUNDRY } from "@easybiz/utils";
import React from "react";
import BagAlterProductSelectPanel from "../BagAlterProductSelectPanel";
import SubTypeProductSelectPanel from "../SubTypeProductSelectPanel";

export default function ProductSelectPanel({ item, scrollHeight, onAddOther }) {
  const setItemItem = useCheckoutEditItemSetter();
  const service = useCheckoutService();

  switch (service?.sector) {
    case BUSINESS_SECTOR_ALTERATION:
    case BUSINESS_SECTOR_BAG_SERVICE:
      return <BagAlterProductSelectPanel item={item} />;
    case BUSINESS_SECTOR_LAUNDRY:
      return (
        <SubTypeProductSelectPanel
          scrollHeight={scrollHeight}
          productId={item.productId}
          subTypeId={item.subTypeId}
          selected={Number.isInteger(item.itemIndex)}
          onClose={() => setItemItem(null)}
          onAddOther={onAddOther}
        />
      );
    default:
      return null;
  }
}
