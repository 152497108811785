"use client";

import { useChatConversationStateSetter, useUserRealmId } from "@easybiz/component-shared";
import { STATUSES_KEY_CONVERSATION_COUNTS } from "@easybiz/lib-shared-utils";
import { useEffect } from "react";

export default function ChatConversationMonitor({ onSnapshot, doc, getFirestore }) {
  const realmId = useUserRealmId();
  const setConversationState = useChatConversationStateSetter();

  useEffect(() => {
    if (realmId) {
      return onSnapshot(
        doc(getFirestore(), `realms/${realmId}/statuses/${STATUSES_KEY_CONVERSATION_COUNTS}`),
        (snapshot) => setConversationState(snapshot.data() || {})
      );
    }
  }, [realmId]);
}
