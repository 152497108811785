export const ORDER_SOURCE_POS = "pos";
export const ORDER_SOURCE_WEBSITE = "website";
export const ORDER_SOURCE_WEBAPP = "webapp";
export const ORDER_SOURCE_IOS = "ios";
export const ORDER_SOURCE_FACTORY = "factory";
export const ORDER_SOURCE_ANDROID = "android";
export const ORDER_SOURCE_RECURRING = "recurring";
export const ORDER_SOURCE_ADMIN = "admin";
export const ORDER_SOURCE_WHATSAPP = "whatsapp";

// Item collection
// TODO: IMPROVE
export const PROCEDURE_PENDING_COLLECTION = 'PENDING COLLECTION';
export const PROCEDURE_DRIVER_COLLECTED = 'DRIVER COLLECTED';
export const PROCEDURE_STORE_RECEIVED = 'SHOP RECEIVED';
export const PROCEDURE_STORE_CHECK_OUT = 'SHOP COLLECTED';
export const PROCEDURE_STORE_CHECK_IN = 'SHOP DELIVERED';

// Item processing
// TODO: IMPROVE
export const PROCEDURE_PLANT_CHECK_IN = 'FACTORY RECEIVED';
export const PROCEDURE_PLANT_CONFIRM_ORDER = 'FACTORY CONFIRM';
export const PROCEDURE_PLANT_LABELING = 'TAGGED';
export const PROCEDURE_PLANT_SERVICING = 'WASHING';
export const PROCEDURE_PRODUCTION_CALENDAR = 'PRODUCTION CALENDAR';
export const PROCEDURE_PLANT_SERVICE_COMPLETE = 'BAGGING';
export const PROCEDURE_PLANT_PACKING = 'PACKED';
export const PROCEDURE_STORAGE = 'STORAGE';
export const PROCEDURE_PLANT_CHECK_OUT = 'FACTORY COLLECTED';
export const PROCEDURE_DISCHARGED = 'CUSTOMER DELIVERED';

// Adjustment
export const ADJUSTMENT_MODE_SURCHARGE = "surcharge";
export const ADJUSTMENT_MODE_DISCOUNT = "discount";

// Benefit
export const BENEFIT_TYPE_DISCOUNT = "DISCOUNT";
export const BENEFIT_TYPE_CASH_BACK = "CASH_BACK";
export const BENEFIT_TYPE_POINTS = "POINTS";
export const BENEFIT_TYPE_MONTHLY_FOC = "MONTHLY FOC";
export const BENEFIT_TYPE_FLAT = "flat";
export const BENEFIT_TYPE_CUSTOM = "custom";

// Promotion
export const PROMO_TYPE_FOC = "foc"; // TODO: Remove
export const PROMO_TYPE_PERCENT = "percentage";
export const PROMO_TYPE_FLAT = "flat";
export const PROMO_TYPE_POINTS = "points";
export const PROMO_TYPE_CREDIT_BACK = "credits";

// Units
export const UNIT_PCS = "pcs";
export const UNIT_PAIR = "pair";
export const UNIT_SET = "set";
export const UNIT_BUNDLE = "bundle";
export const UNIT_KG = "kg";
export const UNIT_SQFT = "sqft";
export const UNIT_FT = "ft";
export const UNIT_INCH = "inch";
export const UNIT_BAG = "bag";
export const UNIT_HOUR = "hour";

// Actions
export const ACTION_DEPOSIT = 'deposit';
export const ACTION_SCHEDULE_PICKUP = 'schedule_pickup';
export const ACTION_SCHEDULE_DELIVER = 'schedule_deliver';
export const ACTION_CONFIRM_PRICE = 'confirm_price';
export const ACTION_ASSIGN = 'assign';
export const ACTION_SERVICE = 'service';
export const ACTION_SUB_SERVICE = 'sub_service';
export const ACTION_PACK = 'pack';
export const ACTION_PAYMENT = 'payment';
export const ACTION_PICK_UP = 'pickup';
export const ACTION_DELIVER = 'deliver';
export const ACTION_COLLECT = 'collect';
export const ACTION_STORE_OUT = 'store_out';
export const ACTION_STORE_IN = 'store_in';
export const ACTION_PLANT_OUT = 'plant_out';
export const ACTION_PLANT_IN = 'plant_in';
// Use for customer reconfirmation only
export const ACTION_AGREE_PRICE = 'agree_price';
export const ACTION_ACK_PRICE = 'ack_price';
// Internal action
export const ACTION_COMPLETE = 'complete';
export const ACTION_CANCEL = 'cancel';

// Events
export const EVENT_ORDER_CREATED = "order_created";

// Rounding
export const ROUNDING_METHOD_HALF_UP = "half_up";
export const ROUNDING_METHOD_HALF_DOWN = "half_down";
export const ROUNDING_METHOD_HALF_TO_EVEN = "half_to_even";

export const ROUNDING_DIRECTION_NEAREST = "nearest";
export const ROUNDING_DIRECTION_UP = "up";
export const ROUNDING_DIRECTION_DOWN = "down";