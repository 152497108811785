import { useAdminBatchPayment } from "@easybiz/api-admin";
import { useCustomerCreditTopUp } from "@easybiz/api-customer";
import { useOrderAdminCreate, useOrderPayment } from "@easybiz/api-order";
import { usePaymentDrawerState } from "@easybiz/component";
import { usePrinter } from "@easybiz/hook";
import { useClientCustomerScreen, useRealmCompanies, useUserBusinessCode } from "@easybiz/component-shared";
import { CUSTOMER_SCREEN_ORDER, PAYMENT_ID_CASH } from "@easybiz/utils";
import { textToImageData } from "@easybiz/component-web-business";
import { useOrderRunningNumber } from "@easybiz/web-firebase";
import { Drawer, Space } from "antd";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import CashierPanel from "../CashierPanel";
import ChangeCalculatorPanel from "../ChangeCalculatorPanel";
import PaymentMethodSelecter from "../PaymentMethodSelecter";
import PaymentOverviewPanel from "../PaymentOverviewPanel";
import ReturnListenerButton from "../ReturnListenerButton";
import SignatureStatusBadge from "../SignatureStatusBadge";
import { updateDoc } from "firebase/firestore";

export default function ({
  pointsOption,
  orderId,
  orderIds,
  newOrder,
  invoiceId,
  topUpOption,
  currentBalance,
  customer,
  fullAmount,
  isDeposit,
  open,
  onCancel,
  onSucceed,
  rounding
}) {
  const businessCode = useUserBusinessCode();
  const companys = useRealmCompanies();
  const company = companys?.find(c => c.codes?.includes(businessCode));
  const nextOrderNo = useOrderRunningNumber(businessCode, true, open ? newOrder?.serviceId : null);
  const [paymentFocused, setPaymentFocused] = useState(false);
  const [signature, setSignature] = useState();
  const { totalAmount, totalOutstanding, totalPayable, payment, pointsPayment, setPayment, setPointsPayment } =
    usePaymentDrawerState(newOrder, topUpOption, fullAmount, rounding || company?.rounding);

  const customerScreen = useClientCustomerScreen();
  const printer = usePrinter();
  const onCallback = useCallback((succeed, response, request) => {
    if (succeed && !response.paymentIntent) {
      if (onSucceed) {
        onSucceed(response);
      } else {
        onCancel();
      }

      if (request?.paymentOption === PAYMENT_ID_CASH) {
        // Open cash drawer
        printer.openDrawer(true);
      }
    }
  }, []);
  const {
    onSubmit: onCreate,
    loading: createLoading,
    response: createResponse,
    onClear: onClearCreate,
  } = useOrderAdminCreate(onCallback, textToImageData);
  const {
    onSubmit: onPayment,
    loading: paymentLoading,
    onClear: onClearPayment,
    response: paymentResponse,
  } = useOrderPayment(onCallback);
  const {
    onSubmit: onTopUp,
    loading: topUpLoading,
    onClear: onClearTopUp,
    response: topUpResponse,
  } = useCustomerCreditTopUp(onCallback);
  const {
    onSubmit: onBatchPayment,
    loading: batchPaymentLoading,
    onClear: onClearBatch,
    response: batchResponse,
  } = useAdminBatchPayment(onCallback);
  const paymentIntent =
    createResponse?.paymentIntent ||
    paymentResponse?.paymentIntent ||
    topUpResponse?.paymentIntent ||
    batchResponse?.paymentIntent;
  const loading = createLoading || paymentLoading || topUpLoading || batchPaymentLoading;
  const enablePartial = Boolean(newOrder) || Boolean(!topUpOption && !orderIds && !invoiceId);

  useEffect(() => {
    if (open) {
      onClearCreate();
      onClearPayment();
      onClearTopUp();
      onClearBatch();
      setPayment(undefined);
      setPointsPayment(undefined);
      setSignature(null);
    }
  }, [open]);

  useEffect(() => {
    if (customerScreen?.exists()) {
      setSignature(customerScreen?.get("signature"));
    }
  }, [customerScreen?.get("signature")]);

  return (
    <Drawer
      width={470}
      title={
        newOrder ? (
          <FormattedMessage defaultMessage={"New Order"} />
        ) : (
          <FormattedMessage tagName="span" defaultMessage="Make Payment" />
        )
      }
      open={open}
      onClose={onCancel}
      bodyStyle={{ padding: 16 }}
      maskClosable={!loading}
      destroyOnClose
    >
      {paymentIntent ? (
        <CashierPanel
          paymentIntent={paymentIntent}
          onSucceed={(response) => onCallback(true, response)}
          onCancel={() => {
            onClearCreate();
            onClearPayment();
            onClearTopUp();
            onClearBatch();
            if (customerScreen?.exists()) {
              updateDoc(customerScreen.ref, { mode: CUSTOMER_SCREEN_ORDER });
            }
          }}
        />
      ) : (
        <Space className="full-width" direction="vertical" size="middle">
          <PaymentOverviewPanel
            pointsOption={pointsOption}
            totalAmount={totalAmount}
            totalPayable={totalPayable}
            pricingTBD={newOrder?.pricingTBD}
            onFocusChange={setPaymentFocused}
            totalOutstanding={totalOutstanding}
            totalPayableAction={
              <SignatureStatusBadge signature={signature} disabled={loading} onSigned={setSignature} />
            }
            payment={payment}
            onPaymentChange={setPayment}
            pointsPayment={pointsPayment}
            onPointsPaymentChange={setPointsPayment}
            enablePartial={enablePartial}
            disabled={loading}
          />
          {payment !== undefined ? (
            <>
              <ReturnListenerButton
                block
                loading={loading}
                type={"primary"}
                listenerDisabled={paymentFocused}
                onClick={() => {
                  if (newOrder) {
                    onCreate({
                      ...newOrder,
                      checkoutParams: {
                        ...newOrder.checkoutParams,
                        signature,
                        payment,
                        pointsPayment,
                      },
                    });
                  } else if (orderId) {
                    onPayment({
                      orderId,
                      businessCode,
                      payment,
                      signature,
                    });
                  } else if (topUpOption) {
                    onTopUp({
                      topUpOptionId: topUpOption.id,
                      currentBalance,
                      customerId: customer.id,
                      payment,
                      businessCode,
                      signature,
                    });
                  } else if (orderIds || invoiceId) {
                    onBatchPayment({
                      customer,
                      payment,
                      invoiceId,
                      orderIds,
                      signature,
                    });
                  }
                }}
              >
                {newOrder ? (
                  <FormattedMessage
                    tagName="span"
                    defaultMessage="Create order {orderNo}"
                    values={{ orderNo: <b>#{nextOrderNo}</b> }}
                  />
                ) : (
                  <FormattedMessage tagName="span" defaultMessage="Make payment" />
                )}
              </ReturnListenerButton>
              <ChangeCalculatorPanel
                disabled={loading}
                disableListener={paymentFocused}
                payment={payment}
                onChange={setPayment}
              />
            </>
          ) : (
            <PaymentMethodSelecter
              className={"padding-horizontal-half"}
              customerId={customer?.id}
              payment={payment}
              rounding={rounding || newOrder?.checkoutParams?.rounding}
              isBatchPayment={Array.isArray(orderIds) || Boolean(invoiceId)}
              disableCredit={Boolean(topUpOption)}
              disablePoints={Boolean(newOrder || topUpOption)}
              enableUnpaid={Boolean(newOrder)}
              onSelect={(payment) => setPayment(payment && isDeposit ? { ...payment, deposit: true } : payment)}
              amount={totalPayable}
              enablePartial={enablePartial}
            />
          )}
        </Space>
      )}
    </Drawer>
  );
}
