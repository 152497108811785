import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { ACTION_APPLY_DISCOUNT, PERMISSION_LEVEL_BLOCKED } from "@easybiz/lib-shared-auth";
import { useAccessAction } from "@easybiz/component-shared";
import { ADJUSTMENT_MODE_DISCOUNT, ADJUSTMENT_MODE_SURCHARGE } from "@easybiz/utils";
import { Button, Popover, Space } from "antd";
import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import AdjustmentOptionsList from "../AdjustmentOptionsList";
import PromoCodeButton from "../PromoCodeButton";
import Title from "../Title";

export default ({ disabled, serviceId, selects, onChange, onPromoCodeApplied }) => {
  const [discountVisible, setDiscountVisible] = useState(false);
  const [surchargeVisible, setSurchargeVisible] = useState(false);
  const [{ level }] = useAccessAction(ACTION_APPLY_DISCOUNT);
  const discountEnabled = level !== PERMISSION_LEVEL_BLOCKED;

  const onSelectAdjustment = useCallback(
    (item) => {
      const index = Array.isArray(selects)
        ? selects.findIndex((added) => added.id === item.id)
        : -1;
      if (index >= 0) {
        onChange([...selects.slice(0, index), ...selects.slice(index + 1)]);
      } else {
        onChange([...(selects || []), item]);
      }

      setDiscountVisible(false);
      setSurchargeVisible(false);
    },
    [selects]
  );

  return (
    <Space>
      <PromoCodeButton onApplied={onPromoCodeApplied} disabled={disabled} />
      <Popover
        trigger={"click"}
        placement="left"
        open={discountEnabled ? discountVisible : false}
        onOpenChange={setDiscountVisible}
        title={
          <Title>
            <FormattedMessage defaultMessage={"Add Discounts"} />
          </Title>
        }
        content={
          <AdjustmentOptionsList
            selects={selects}
            serviceId={serviceId}
            mode={ADJUSTMENT_MODE_DISCOUNT}
            onSelect={onSelectAdjustment}
          />
        }
      >
        <Button icon={<MinusOutlined />} disabled={disabled || !discountEnabled}>
          <FormattedMessage tagName="span" defaultMessage="Discount" />
        </Button>
      </Popover>
      <Popover
        trigger={"click"}
        placement="left"
        open={surchargeVisible}
        onOpenChange={setSurchargeVisible}
        title={
          <Title>
            <FormattedMessage defaultMessage={"Add Surcharges"} />
          </Title>
        }
        content={
          <AdjustmentOptionsList
            selects={selects}
            serviceId={serviceId}
            mode={ADJUSTMENT_MODE_SURCHARGE}
            onSelect={onSelectAdjustment}
          />
        }
      >
        <Button icon={<PlusOutlined />} disabled={disabled}>
          <FormattedMessage tagName="span" defaultMessage="Surcharge" />
        </Button>
      </Popover>
    </Space>
  );
};
