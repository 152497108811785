import { useEmailStatusMessage } from "@easybiz/component";
import { useClientState, useClientStateSetter } from "@easybiz/component-shared";
import {
  EMAIL_STATUS_BOUNCE,
  EMAIL_STATUS_CLICK,
  EMAIL_STATUS_DEFERRED,
  EMAIL_STATUS_DELIVERED,
  EMAIL_STATUS_DROPPED,
  EMAIL_STATUS_ERROR,
  EMAIL_STATUS_OPEN,
  EMAIL_STATUS_SPAM,
} from "@easybiz/utils";
import { useEmailDoc } from "@easybiz/web-firebase";
import { message } from "antd";
import { useEffect } from "react";

export default function () {
  const { emailId } = useClientState();
  const setState = useClientStateSetter();
  const email = useEmailDoc(emailId);
  const statusMessage = useEmailStatusMessage(email);

  useEffect(() => {
    if (statusMessage) {
      switch (email?.get("status")) {
        case EMAIL_STATUS_DELIVERED:
        case EMAIL_STATUS_OPEN:
        case EMAIL_STATUS_CLICK:
          setState({ emailId: null });
          message.success({
            key: emailId,
            duration: 3,
            content: statusMessage,
          });
          break;
        case EMAIL_STATUS_BOUNCE:
        case EMAIL_STATUS_DROPPED:
        case EMAIL_STATUS_DEFERRED:
        case EMAIL_STATUS_SPAM:
        case EMAIL_STATUS_ERROR:
          setState({ emailId: null });
          message.error({
            key: emailId,
            duration: 10,
            content: statusMessage,
            onClick: () => {
              message.destroy(emailId);
              setState({ emailId: null });
            },
            onClose: () => setState({ emailId: null }),
          });
          break;
        default:
          message.loading({
            key: emailId,
            duration: 0,
            content: statusMessage,
            onClick: () => {
              message.destroy(emailId);
              setState({ emailId: null });
            },
          });
      }
    }
  }, [email?.get("status")]);

  return null;
}
