import { GlobalOutlined, HistoryOutlined, StopOutlined, UserOutlined } from "@ant-design/icons";
import { NameDisplay } from "@easybiz/component";
import { useClientPrimaryColor, useClientSecondaryColor } from "@easybiz/component-shared";
import {
  BOOKING_SLOT_BADGE_ECO,
  BOOKING_SLOT_BADGE_FULL,
  BOOKING_SLOT_BADGE_PAST,
  BOOKING_SLOT_BADGE_RESERVED,
} from "@easybiz/utils";
import { Badge, Button, Tooltip, Typography } from "antd";
import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default ({ start, end, enabled, badge, orderNo, selected, onSelect, workerId, note }) => {
  const intl = useIntl();
  const secondary = useClientSecondaryColor();
  const primary = useClientPrimaryColor();

  const { icon, title, color, strong } = useMemo(() => {
    switch (badge) {
      case BOOKING_SLOT_BADGE_FULL:
        return {
          icon: <StopOutlined color="error" />,
          title: intl.formatMessage({ defaultMessage: "Full" }),
          color: secondary,
        };
      case BOOKING_SLOT_BADGE_PAST:
        return {
          icon: <HistoryOutlined />,
          title: intl.formatMessage({ defaultMessage: "Expired" }),
          color: secondary,
        };
      case BOOKING_SLOT_BADGE_ECO:
        return {
          icon: <GlobalOutlined color="success" />,
        };
      case BOOKING_SLOT_BADGE_RESERVED:
        return {
          title: intl.formatMessage(
            {
              defaultMessage: "Reserved {orderNo}",
            },
            {
              orderNo: `#${orderNo || ""}`,
            }
          ),
          color: primary,
          strong: true,
        };
      default:
        return {};
    }
  }, [badge]);

  return (
    <Badge count={title} color={color}>
      <Button
        shape="round"
        icon={enabled ? icon : <StopOutlined />}
        disabled={!enabled}
        type={selected ? "primary" : "default"}
        onClick={() => {
          if (onSelect) {
            onSelect({
              start,
              end: end || null,
            });
          }
        }}
      >
        <Typography.Text strong={strong} style={{ color: selected ? "white" : null }}>
          {`${start}${end && end !== start ? `~${end}` : ""}`}
          {note ? `(${note})` : ""}
        </Typography.Text>
        {workerId && (
          <Tooltip
            title={
              <FormattedMessage
                defaultMessage="Auto assign to {name}"
                values={{ name: <NameDisplay nameKey={workerId} /> }}
              />
            }
          >
            <UserOutlined />
          </Tooltip>
        )}
      </Button>
    </Badge>
  );
};
