import { useClientState, useClientStateSetter } from "@easybiz/component-shared";
import { PhotoSwipeGallery } from "@easybiz/component-web-business";
import { Space } from "antd";
import DeletePhotoButton from "../DeletePhotoButton";

export default function () {
  const { gallery } = useClientState();
  const setState = useClientStateSetter();
  const { photos, viewIndex, orderId } = gallery || {};

  return gallery ? (
    <PhotoSwipeGallery
      photos={photos}
      viewIndex={viewIndex}
      onRenderCaption={(currentImage) => (
        <Space>
          {photos[currentImage]?.title}
          {orderId && photos[currentImage]?.id && (
            <DeletePhotoButton
              orderId={orderId}
              photoId={photos[currentImage]?.id}
              onDeleted={(photoId) => {
                const remainingPhotos = (photos || []).filter((photo) => photo.id !== photoId);

                if (remainingPhotos.length > 0) {
                  setState({ gallery: { ...gallery, photos: remainingPhotos } });
                } else {
                  setState({ gallery: null });
                }
              }}
            />
          )}
        </Space>
      )}
      onClose={() => setState({ gallery: null })}
    />
  ) : null;
}
