export const OWNER_LICENSE_ID = "owner";

// Permission groups (Match UI)
export const GROUP_MANAGE_SALES_CHANNELS = "manage_sales_channels";
export const GROUP_MANAGE_SYSTEM_SETTINGS = "manage_system_settings";
export const GROUP_MANAGE_CHAT = "manage_chat";

// Domain permission scopes
export const SCOPE_MANAGE_POS = "manage_pos";
export const SCOPE_MANAGE_ONLINE_STORE = "manage_online_store";
export const SCOPE_MANAGE_B2B = "manage_b2b";
export const SCOPE_MANAGE_LOGISTIC = "manage_logistic";
export const SCOPE_MANAGE_PRODUCTS_PRICING = "manage_products_pricing";
export const SCOPE_MANAGE_BOOKINGS = "manage_bookings";
export const SCOPE_MANAGE_OPERATIONS = "manage_operations";
export const SCOPE_MANAGE_TEMPLATES = "manage_templates";
export const SCOPE_MANAGE_AUTOMATION = "manage_automation";
export const SCOPE_MANAGE_BUSINESS_UNITS = "manage_business_units";
export const SCOPE_MANAGE_BUSINESS_PORTFOLIOS = "manage_business_portfolios";
export const SCOPE_MANAGE_BILLING = "manage_billing";
export const SCOPE_MANAGE_TEAMMATES = "manage_teammates";
export const SCOPE_MANAGE_REPORTS = "manage_reports";
export const SCOPE_MANAGE_CUSTOMERS = "manage_customers";
export const SCOPE_MANAGE_MARKETING = "manage_marketing";
export const SCOPE_CHAT_ASSIGNED = "chat_assigned";
export const SCOPE_CHAT_UNASSIGNED = "chat_unassigned";
export const SCOPE_CHAT_CLOSED = "chat_closed";
export const SCOPE_CHAT_OPEN = "chat_open";

// Data query
export const DATA_ACCESS_CUSTOMER_DATA = "customer_query";
export const DATA_ACCESS_ORDER_DATA = "order_query";
export const DATA_ACCESS_SALES_DATA = "sales_query";

// Data export
export const DATA_EXPORT_CUSTOMER_DATA = "customer_export";
export const DATA_EXPORT_ORDER_DATA = "order_export";
export const DATA_EXPORT_SALES_DATA = "sales_export";

// Actions
export const ACTION_CANCEL_ORDER = "cancel_order";
export const ACTION_CONFIRM_ORDER = "confirm_order";
export const ACTION_MAKE_PAYMENT = "make_payment";
export const ACTION_CANCEL_PAYMENT = "cancel_payment";
export const ACTION_OVERWRITE_PRICE = "overwrite_price";
export const ACTION_SALES_RETURN = "sales_return";
export const ACTION_APPLY_DISCOUNT = "apply_discount";
export const ACTION_OPEN_CASHIER = "open_cashier";
export const ACTION_CREATE_JOB = "create_job";
export const ACTION_DISCHARGE_ITEM = "discharge_item";

export const PERMISSION_LEVEL_FULL = "full";
export const PERMISSION_LEVEL_REQUIRES_APPROVAL = "requires_approval";
export const PERMISSION_LEVEL_PAID_ONLY = "paid_only";
export const PERMISSION_LEVEL_ASSIGNED_ONLY = "assigned_only";
export const PERMISSION_LEVEL_UNPAID_ONLY = "unpaid_only";
export const PERMISSION_LEVEL_TIME_LIMITED = "time_limited";
export const PERMISSION_LEVEL_AMOUNT_LIMITED = "amount_limited";
export const PERMISSION_LEVEL_BLOCKED = "blocked";

export const PERMISSION_TIME_SAME_DAY = "same_day";
export const PERMISSION_TIME_WITHIN_X_DAY = "within_x_day";

// Auth Server actions
export const SERVER_ACTIONS_AUTH = "auth";
export const SERVER_ACTION_USER_IDENTIFY = `${SERVER_ACTIONS_AUTH}UserIdentify`;
export const SERVER_ACTION_DEVICE_REGISTER = `${SERVER_ACTIONS_AUTH}DeviceRegister`;
export const SERVER_ACTION_POS_TOKEN_GENERATE = `${SERVER_ACTIONS_AUTH}POSTokenGenerate`;
export const SERVER_ACTION_POS_CLOCK_IN = `${SERVER_ACTIONS_AUTH}POSClockIn`;
export const SERVER_ACTION_POS_CLOCK_OUT = `${SERVER_ACTIONS_AUTH}POSClockOut`;
export const SERVER_ACTION_OTP_SEND = `${SERVER_ACTIONS_AUTH}OTPSend`;
export const SERVER_ACTION_OTP_VERIFY = `${SERVER_ACTIONS_AUTH}OTPVerify`;
export const SERVER_ACTION_PASSWORD_RESET = `${SERVER_ACTIONS_AUTH}PasswordReset`;
export const SERVER_ACTION_EMAIL_UPDATE = `${SERVER_ACTIONS_AUTH}EmailUpdate`;
export const SERVER_ACTION_NAME_UPDATE = `${SERVER_ACTIONS_AUTH}NameUpdate`;
export const SERVER_ACTION_PERMISSION_UPDATE = `${SERVER_ACTIONS_AUTH}PermissionUpdate`;
