"use client";

import {
  AccessProvider,
  ActionProvider,
  AppDrawerProvider,
  AsyncUploadProvider,
  ChatProvider,
  FeedbackProvider,
  KeyboardProvider,
  KVStorageProvider,
  LicenseProvider,
  PrintingProvider,
  RealmProvider,
  useClientCustomerScreenSetter,
  useClientInstallId,
  useClientInstallIdSetter,
  useClientRegisterDeviceSetter,
  useClientType,
  UserProvider,
} from "@easybiz/component-shared";
import { OWNER_LICENSE_ID } from "@easybiz/lib-shared-auth";
import { SYSTEM_CLIENT_MOBILE } from "@easybiz/lib-shared-utils";
import { useCallback, useEffect, useState } from "react";
import AccessLoadingScreen from "./AccessLoadingScreen";
import ChatConversationMonitor from "./ChatConversationMonitor";
import ChatUnreadMonitor from "./ChatUnreadMonitor";
import ClientDateMonitor from "./ClientDateMonitor";
import DeviceRegister from "./DeviceRegister";
import SingleSignOnMonitor from "./SingleSignOnMonitor";

export default function WorkflowApp({
  app,
  signInScreen,
  children,
  localStorage,
  uploadStorage,
  getDeviceInfo,
  toaster,
  // Printing
  ESCEncoder,
  usbIO,
  bluetoothIO,
  connectPrinter,
  // Firebase: Installations
  getId,
  getInstallations,
  onIdChange,
  // Firebase: Auth
  onIdTokenChanged,
  signInWithCustomToken,
  signOut,
  getAuth,
  // Firebase: Firestore
  onSnapshot,
  runTransaction,
  arrayUnion,
  doc,
  getFirestore,
  setDoc,
  deleteDoc,
  // Firebase: Functions
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
  // Auth state ready
  onAuthStateReady,
  // Components
  Button,
  Text,
  View,
  Image,
  imageSource,
}) {
  const client = useClientType();
  const installId = useClientInstallId();
  const setInstallId = useClientInstallIdSetter();
  const setRegisterDevice = useClientRegisterDeviceSetter();
  const setConnectDevice = useClientCustomerScreenSetter();
  const [user, setUser] = useState();
  const [realm, setRealm] = useState();
  const [license, setLicense] = useState();
  const realmId = user?.realmId;
  const pendingSignIn = Boolean(user && !user.uid);
  const licenseId = user?.licenseId;
  const customerScreenId = user?.connectDeviceId || installId;
  const isPendingSignIn = user ? !user?.uid : false;
  const accessReady = user?.easybussuper
    ? true
    : Boolean(!isPendingSignIn && (user?.clientAccess || licenseId === OWNER_LICENSE_ID));

  useEffect(() => {
    if (!installId) {
      getId(getInstallations()).then(setInstallId);

      if (onIdChange) {
        return onIdChange(getInstallations(), setInstallId);
      }
    }
  }, [installId]);

  useEffect(() => {
    return onIdTokenChanged(getAuth(), async (user) => {
      // Sign in
      const idToken = await user?.getIdTokenResult();

      setUser((current) => {
        if (!current && onAuthStateReady) {
          onAuthStateReady();
        }

        if (!user) {
          return {};
        }

        const { claims } = idToken || {};

        if (claims.installId) {
          setInstallId(claims.installId);
        }

        return {
          // ID
          uid: user.uid,
          name: user.displayName,
          email: user.email,
          emailVerified: user.emailVerified,
          passwordUpdatedAt: user?.reloadUserInfo?.passwordUpdatedAt,
          ...(user.photoURL && { avatarUrl: user.photoURL }),
          // Auth
          authTime: claims.auth_time,
          realmId: claims.realmId,
          licenseId: claims.licenseId,
          clientAccess: claims[`${client}Access`],
          dataAccess: claims.dataAccess,
          actionAccess: claims.actionAccess,
          accessVersion: claims.accessVersion,
          isTestMode: claims.isTestMode ? true : false,
          ...(claims.clientVersion && { clientVersion: claims.clientVersion }),
          ...(claims.ebAccess && { debugMode: claims.ebAccess }),
          ...(claims.easybussuper && { easybussuper: claims.easybussuper }),
          ...((claims.stripeAccount || claims.realmConfig?.stripeAccount) && {
            stripeAccount: claims.stripeAccount || claims.realmConfig?.stripeAccount,
          }), // TODO: Remove stripeAccount from user claims
          // Location
          bucket: claims.bucket,
          timeZone: claims.timeZone,
          currency: claims.currency || claims.realmConfig?.currency,
          countryCode: claims.countryCode || claims.realmConfig?.countryCode,
          dialCode: claims.dialCode || claims.realmConfig?.dialCode,
          locationName: claims.locationName || claims.realmConfig?.city,
          locationCenter:
            claims.latitude && claims.longitude
              ? { lat: claims.latitude, lng: claims.longitude }
              : claims.realmConfig?.locationCenter,
          // POS
          ...(claims.businessCode && { businessCode: claims.businessCode }),
          ...(typeof claims.multiStation === "boolean" && { multiStation: claims.multiStation }),
          ...(claims.shiftManagement && { shiftManagement: true }),
          ...(claims.storeLocation && { storeLocation: claims.storeLocation }),
          ...(claims.clients && { clients: claims.clients }),
          ...(claims.connectDeviceId && { connectDeviceId: claims.connectDeviceId }),
          ...(claims.staffId && {
            checkInStaff: {
              id: claims.staffId,
              name: claims.staffName || "",
              ...(claims.clockInTime && { clockInTime: claims.clockInTime }),
            },
          }),
        };
      });
    });
  }, [client]);

  // Monitor realm
  useEffect(() => {
    if (realmId) {
      return onSnapshot(doc(getFirestore(), `realms/${realmId}`), (snapshot) =>
        setRealm({ ...snapshot.data(), realmId: snapshot.id }),
      );
    }
  }, [realmId]);

  // Monitor license
  useEffect(() => {
    if (realmId && licenseId) {
      return onSnapshot(doc(getFirestore(), `billings/${realmId}/licences/${licenseId}`), (snapshot) =>
        setLicense(snapshot.data() || {}),
      );
    }
  }, [realmId, licenseId]);

  // Monitor registered device
  useEffect(() => {
    if (installId && client && realmId) {
      return onSnapshot(
        doc(getFirestore(), `realms/${realmId}/devices/${client}/installs/${installId}`),
        setRegisterDevice,
      );
    }
  }, [installId, realmId, client]);

  // Monitor connected device (POS secondary screen)
  useEffect(() => {
    if (realmId && customerScreenId) {
      return onSnapshot(
        doc(getFirestore(), `realms/${realmId}/customer_screens/${customerScreenId}`),
        setConnectDevice,
      );
    }
  }, [realmId, customerScreenId]);

  // Monitor unauthorized device (for admin controlled auto sign-in)
  useEffect(() => {
    if (installId && pendingSignIn) {
      return onSnapshot(doc(getFirestore(), `devices/${installId}`), (device) => {
        if (device.get("token")) {
          signInWithCustomToken(getAuth(), device.get("token")).then(() => {
            deleteDoc(device.ref);
          });
        }
      });
    }
  }, [installId, pendingSignIn]);

  const callable = useCallback((params, functionName) => {
    const functions = getFunctions();

    if (process.env.NODE_ENV === "development" && `${functionName}`.startsWith(process.env.NEXT_PUBLIC_DEV || "logistic")) {
      connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    }

    if (typeof functionName !== "string") {
      let isStaging;

      if (typeof window !== "undefined") {
        isStaging = window.location.hostname.includes("staging");
      }

      functionName = isStaging ? "stagingOperationCreate" : "operationCreate";
    }

    return httpsCallable(functions, functionName)(params);
  }, []);

  const onSignOut = useCallback(() => {
    const user = getAuth()?.currentUser;

    if (user) {
      setDoc(
        doc(getFirestore(), `realms/${realmId}/push_tokens/${user.uid}`),
        {
          [client === SYSTEM_CLIENT_MOBILE ? `apppush` : `webpush`]: null,
        },
        { merge: true },
      );

      signOut(getAuth());
    }
  }, [client]);

  return (
    <LicenseProvider license={license}>
      <UserProvider user={user} setUser={setUser} onSignOut={onSignOut} isPendingSignIn={isPendingSignIn}>
        <RealmProvider realm={realm}>
          <AccessProvider accessReady={accessReady}>
            <ActionProvider
              callable={callable}
              firestore={{
                onSnapshot,
                runTransaction,
                arrayUnion,
                doc,
                getFirestore,
                deleteDoc,
                setDoc,
              }}
            >
              <PrintingProvider
                ESCEncoder={ESCEncoder}
                usbIO={usbIO}
                bluetoothIO={bluetoothIO}
                connectPrinter={connectPrinter}
              >
                <FeedbackProvider toaster={toaster}>
                  <AppDrawerProvider>
                    <AsyncUploadProvider uploadStorage={uploadStorage}>
                      <KVStorageProvider localStorage={localStorage}>
                        <KeyboardProvider>
                          <ChatProvider>
                            {/* app is the main application, pages from NextJS or Screens from Native navigation */}
                            {pendingSignIn && signInScreen ? (
                              signInScreen
                            ) : !user || (!pendingSignIn && !accessReady) ? ( // Show loading screen only if client user not loaded or pending access register
                              <AccessLoadingScreen
                                client={client}
                                getDeviceInfo={getDeviceInfo}
                                signInWithCustomToken={signInWithCustomToken}
                                getAuth={getAuth}
                                Button={Button}
                                Text={Text}
                                View={View}
                                Image={Image}
                                imageSource={imageSource}
                              />
                            ) : (
                              app
                            )}
                            {/* Children are additional components from web/native Apps */}
                            {children}
                            <ChatConversationMonitor onSnapshot={onSnapshot} doc={doc} getFirestore={getFirestore} />
                            <ChatUnreadMonitor onSnapshot={onSnapshot} doc={doc} getFirestore={getFirestore} />
                          </ChatProvider>
                        </KeyboardProvider>
                      </KVStorageProvider>
                    </AsyncUploadProvider>

                    <DeviceRegister
                      getDeviceInfo={getDeviceInfo}
                      signInWithCustomToken={signInWithCustomToken}
                      getAuth={getAuth}
                    />
                    <SingleSignOnMonitor signOut={signOut} getAuth={getAuth} />
                    <ClientDateMonitor />
                  </AppDrawerProvider>
                </FeedbackProvider>
              </PrintingProvider>
            </ActionProvider>
          </AccessProvider>
        </RealmProvider>
      </UserProvider>
    </LicenseProvider>
  );
}
