import { DeleteOutlined } from "@ant-design/icons";
import { DateDisplay, NameDisplay } from "@easybiz/component";
import { useCheckoutSupplierId } from "@easybiz/component-shared";
import {
  ITEM_STEP_ACCESSORY,
  ITEM_STEP_COLLECTION_DATE,
  ITEM_STEP_NOTE,
  ITEM_STEP_SERVICE_TYPES,
  STEP_TYPE_FREE_INPUT,
  STEP_TYPE_MULTI_SELECT,
  STEP_TYPE_SINGLE_SELECT,
} from "@easybiz/utils";
import { Button, Input, Space } from "antd";
import dayjs from "dayjs";
import React from "react";
import { FormattedMessage } from "react-intl";
import AccessorySelectPanel from "../AccessorySelectPanel";
import ProductionCalendar from "../ProductionCalendar";
import StepItemNotePanel from "../StepItemNotePanel";
import StepOptionsPanel from "../StepOptionsPanel";
import SubOptionsSelectPanel from "../SubOptionsSelectPanel";
import Title from "../Title";

export default function ProductSelectStep({
  step,
  stepValue,
  productId,
  date,
  subOptions,
  accessories,
  freeNote,
  commons,
  onChange,
  onValueChange,
}) {
  const supplierId = useCheckoutSupplierId();

  switch (step?.type) {
    case ITEM_STEP_SERVICE_TYPES:
      return (
        <SubOptionsSelectPanel
          productId={productId}
          subOptions={subOptions}
          onChange={(subOptions) => onChange({ subOptions })}
        />
      );
    case ITEM_STEP_COLLECTION_DATE:
      return (
        <Space className="full-width" direction="vertical">
          <div className="padding-horizontal-half">
            {date ? (
              <Space>
                <Title type={"success"}>
                  <DateDisplay date={date} weekday="long" />
                </Title>
                <Button type="text" icon={<DeleteOutlined />} danger onClick={() => onChange({ date: null })}>
                  <FormattedMessage tagName={"span"} defaultMessage="Clear" />
                </Button>
              </Space>
            ) : (
              <Title type={"danger"}>
                <FormattedMessage defaultMessage={"Not set (Click on calendar to set date)"} />
              </Title>
            )}
          </div>
          <ProductionCalendar
            supplierId={supplierId}
            bySupplier
            value={date ? dayjs(date) : null}
            selects={subOptions}
            onSelect={(newDate) => onChange({ date: newDate.format("YYYY-MM-DD") })}
            title={
              <Title type={"success"}>
                <NameDisplay nameKey={supplierId} />
              </Title>
            }
          />
        </Space>
      );
    case ITEM_STEP_ACCESSORY:
      return <AccessorySelectPanel selects={accessories} onChange={(accessories) => onChange({ accessories })} />;
    case ITEM_STEP_NOTE:
      return <StepItemNotePanel freeNote={freeNote} commons={commons} onChange={onChange} />;
    // Custom steps
    case STEP_TYPE_MULTI_SELECT:
    case STEP_TYPE_SINGLE_SELECT: {
      const multiple = step.type === STEP_TYPE_MULTI_SELECT;

      return <StepOptionsPanel step={step} multiple={multiple} selects={stepValue} onChange={onValueChange} />;
    }
    case STEP_TYPE_FREE_INPUT:
      return (
        <div className="padding-left-half">
          <Input.TextArea autoFocus={!stepValue} value={stepValue} onChange={(e) => onValueChange(e.target.value)} />
        </div>
      );
    default:
      return null;
  }
}
