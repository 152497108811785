import { CloseOutlined, CreditCardOutlined, HistoryOutlined, WalletOutlined } from "@ant-design/icons";
import { AmountDisplay } from "@easybiz/component";
import { usePOSIsDayClosed } from "@easybiz/hook";
import { useCheckoutCustomer, useClientTheme, useFeedbackToast } from "@easybiz/component-shared";
import { CustomerTopUpOptionList, IconButton, PaymentDrawer, ReceiptView, TopUpOverviewList } from "@easybiz/web-admin";
import { useTopUpWalletDoc } from "@easybiz/web-firebase";
import { Alert, Avatar, Button, Card, Col, Drawer, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CreditHistoryDrawer from "./CreditHistoryDrawer";
import TopupReportButton from "./TopupReportButton";

export default function MainActionAdvanceCreditPanel() {
  const customer = useCheckoutCustomer();
  const [topUpOption, setTopUpOption] = useState(null);
  const [historyVisible, setHistoryVisible] = useState(false);
  const [topUpVisible, setTopUpVisible] = useState();
  const [receipt, setReceipt] = useState();
  const dayClosed = usePOSIsDayClosed();
  const wallet = useTopUpWalletDoc(customer?.id);
  const secondaryContainer = useClientTheme()?.secondaryContainer;
  const intl = useIntl();
  const message = useFeedbackToast();

  useEffect(() => {
    if (topUpOption && !customer) {
      setTopUpOption(null);
    }
  }, [Boolean(customer)]);

  return (
    <div className="flex flex-fill padding-half scroll-container">
      <div className="flex flex-fill scroll-body">
        <Typography.Title level={5}>
          <FormattedMessage defaultMessage={"Advance credit"} />
        </Typography.Title>
        {topUpOption ? (
          <Card
            title={<FormattedMessage defaultMessage={"Top Up Preview"} />}
            extra={<IconButton icon={<CloseOutlined />} onClick={() => setTopUpOption(null)} />}
            actions={[
              <div className="padding-horizontal">
                <Button
                  block
                  type="primary"
                  disabled={dayClosed || !wallet}
                  icon={<CreditCardOutlined />}
                  onClick={() => {
                    if (!customer)
                      return message.info(
                        intl.formatMessage({
                          defaultMessage: "Please select top up customer",
                        })
                      );

                    if (!topUpOption)
                      return message.info(
                        intl.formatMessage({
                          defaultMessage: "Please select a top up option",
                        })
                      );

                    setTopUpVisible({
                      currentBalance: wallet.get("credit"),
                    });
                  }}
                >
                  <FormattedMessage tagName={"span"} defaultMessage={"Top up payment"} />
                </Button>
              </div>,
            ]}
          >
            <TopUpOverviewList wallet={wallet} customer={customer} topUpOption={topUpOption} />
          </Card>
        ) : (
          <>
            {customer ? (
              <Card
                title={
                  <FormattedMessage
                    defaultMessage={"{customerName}'s balance"}
                    values={{ customerName: customer?.name }}
                  />
                }
                loading={!wallet}
                extra={
                  <Button icon={<HistoryOutlined />} onClick={() => setHistoryVisible(true)}>
                    <FormattedMessage tagName={"span"} defaultMessage={"Credit history"} />
                  </Button>
                }
              >
                <Card.Meta
                  avatar={<Avatar icon={<WalletOutlined />} style={{ backgroundColor: secondaryContainer }} />}
                  title={
                    <Typography.Title level={3} style={{ margin: 0 }}>
                      <AmountDisplay value={wallet?.get("credit")} />
                    </Typography.Title>
                  }
                />
              </Card>
            ) : (
              <div className="padding-half">
                <Alert
                  type="info"
                  message={
                    <FormattedMessage
                      defaultMessage={
                        "Search & select customer account to check customer credit history or process top up"
                      }
                    />
                  }
                />
              </div>
            )}
            <CustomerTopUpOptionList
              header={<FormattedMessage defaultMessage={"Top up options"} />}
              customerId={customer?.id}
              onSelect={customer ? setTopUpOption : null}
            />
          </>
        )}
      </div>
      <Row gutter={8}>
        <Col span={12}>
          <TopupReportButton />
        </Col>
        <Col span={12}></Col>
      </Row>
      <CreditHistoryDrawer
        placement={"right"}
        open={historyVisible}
        onCancel={() => setHistoryVisible(false)}
        customer={customer}
      />
      <PaymentDrawer
        customer={customer}
        topUpOption={topUpOption}
        currentBalance={topUpVisible?.currentBalance}
        open={Boolean(topUpVisible)}
        onCancel={() => setTopUpVisible(null)}
        onSucceed={(receipt) => {
          setTopUpVisible(null);
          setTopUpOption(null);
          setReceipt(receipt);
        }}
      />
      <Drawer
        title={<FormattedMessage tagName="span" defaultMessage="Top Up Receipt" />}
        open={Boolean(receipt)}
        onClose={() => setReceipt(null)}
        width={470}
      >
        {receipt && <ReceiptView {...receipt} printing />}
      </Drawer>
    </div>
  );
}
