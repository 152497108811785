import { DownloadOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import React, { lazy, Suspense } from "react";
import { FormattedMessage } from "react-intl";

const ReceiptPDFDownloadProvider = lazy(() =>
  import(`@easybiz/component-web-business/src/ReceiptPDFDownloadProvider`)
);

export default function ReceiptDownloadButton({ lines, fileName }) {
  return (
    <Suspense fallback={<Spin />}>
      <ReceiptPDFDownloadProvider lines={lines} fileName={fileName}>
        {({ loading }) => (
          <Button icon={<DownloadOutlined />} loading={loading}>
            <FormattedMessage tagName="span" defaultMessage="Download" />
          </Button>
        )}
      </ReceiptPDFDownloadProvider>
    </Suspense>
  );
}
