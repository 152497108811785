import { useCheckoutState } from "@easybiz/checkout";
import { CheckoutProvider, useRealmServices, useUserRealmId } from "@easybiz/component-shared";
import { useAddLineItemStepQuery, useCheckoutPromotions, useServicePricingData } from "@easybiz/web-firebase";
import { deleteDoc, doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { useCallback } from "react";

export default function ({
  children,
  newOrderType,
  editOrder: propEditOrder,
  businessCode: propBusinessCode,
  service: propService,
  serviceId: propServiceId,
  customer: propCustomer,
  pricingData: initPricingData,
  bookingDoc,
  date,
}) {
  const realmId = useUserRealmId();
  const businesses = useRealmServices();
  const { editOrder, businessCode, service, customer, setBusinessCode, setService, setCustomer, setEditOrder } =
    useCheckoutState(
      propBusinessCode,
      propService || (propServiceId && businesses?.find((service) => service.id === propServiceId)),
      propCustomer,
      propEditOrder
    );
  const pricingData = useServicePricingData(editOrder ? editOrder.get("service") : initPricingData ? null : service);
  const promotions = useCheckoutPromotions(editOrder ? null : businessCode, service?.id, customer?.id, date); // No need to load promotion during order editing
  const lineItemsSteps = useAddLineItemStepQuery(service, true);

  const onDraft = useCallback(
    (draftId, setEditParams, saving) => {
      if (realmId) {
        if (setEditParams) {
          if (saving) {
            setEditParams((initParams) => {
              if (Array?.isArray(initParams?.items)) {
                const { items, ...params } = initParams || {};
                setDoc(doc(getFirestore(), `realms/${realmId}/shopping_carts/${draftId}`), {
                  items: items.length > 0 ? items : null,
                });

                return params;
              } else {
                return initParams;
              }
            });
          } else {
            getDoc(doc(getFirestore(), `realms/${realmId}/shopping_carts/${draftId}`)).then((shoppingCart) => {
              const items = shoppingCart.get("items");
              if (Array.isArray(items) && items.length > 0) {
                setEditParams((params) => {
                  if (!Array.isArray(params?.items) || params.items.length === 0) {
                    return { ...params, items };
                  } else {
                    return params;
                  }
                });
              }
            });
          }
        } else {
          deleteDoc(doc(getFirestore(), `realms/${realmId}/shopping_carts/${draftId}`));
        }
      }
    },
    [realmId]
  );

  return (
    <CheckoutProvider
      editOrder={editOrder}
      setEditOrder={setEditOrder}
      businessCode={businessCode}
      service={service}
      customer={customer}
      promotions={promotions}
      lineItemsSteps={lineItemsSteps}
      pricingData={initPricingData || pricingData}
      bookingDoc={bookingDoc}
      newOrderType={newOrderType}
      onSwitchBusiness={setBusinessCode}
      onSwitchService={setService}
      onSwitchCustomer={setCustomer}
      onDraft={realmId ? onDraft : null}
    >
      {children}
    </CheckoutProvider>
  );
}
