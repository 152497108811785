import {
  PLACEHOLDER_ORDER_ADJUSTMENT_ITEM_AMOUNT,
  PLACEHOLDER_ORDER_ADJUSTMENT_ITEM_DESCRIPTION,
} from "./constants/content.js";
import { PROMO_TYPE_FLAT, PROMO_TYPE_PERCENT } from "./constants/index.js";
import formatNumber from "./formatNumber.js";

export default function formatTemplateAdjustmentItems(
  {
    currency,
    expressAmount,
    delivery,
    taxName,
    gstAmount,
    gstRate,
    promotions,
    adjustments,
    creditNotes,
    roundingAmount,
  },
  currencyDisplay
) {
  const lineItems = [];

  // Express
  if (expressAmount > 0) {
    const { surcharge, title } = (delivery && delivery.option) || {};
    const chargeRate = surcharge && surcharge.type === PROMO_TYPE_PERCENT && surcharge.value;

    lineItems.push({
      [PLACEHOLDER_ORDER_ADJUSTMENT_ITEM_DESCRIPTION]: `Express surcharge${
        title || chargeRate
          ? ` (${[title, chargeRate ? `${chargeRate} surcharge` : null].filter(Boolean).join(" | ")})`
          : ""
      }`,
      [PLACEHOLDER_ORDER_ADJUSTMENT_ITEM_AMOUNT]: formatNumber(expressAmount, currency, true, currencyDisplay),
    });
  }

  promotions?.forEach(({ title, type, value, lineTotal, applied }) => {
    if (applied && [PROMO_TYPE_PERCENT, PROMO_TYPE_FLAT].includes(type)) {
      lineItems.push({
        [PLACEHOLDER_ORDER_ADJUSTMENT_ITEM_DESCRIPTION]: [
          title,
          ...(type === PROMO_TYPE_PERCENT ? [`(${value}%)`] : []),
        ]
          .filter(Boolean)
          .join(" "),
        [PLACEHOLDER_ORDER_ADJUSTMENT_ITEM_AMOUNT]: formatNumber(-lineTotal, currency, true, currencyDisplay),
      });
    }
  });

  // Adjustments
  adjustments?.forEach(({ title, type, value, lineTotal }) => {
    if (typeof lineTotal === "number" && lineTotal !== 0) {
      lineItems.push({
        [PLACEHOLDER_ORDER_ADJUSTMENT_ITEM_DESCRIPTION]: [title, ...(type === PROMO_TYPE_PERCENT ? [`${value}%`] : [])]
          .filter(Boolean)
          .join(" "),
        [PLACEHOLDER_ORDER_ADJUSTMENT_ITEM_AMOUNT]: formatNumber(lineTotal, currency, true, currencyDisplay),
      });
    }
  });

  if (typeof gstAmount === "number" && gstAmount > 0) {
    lineItems.push({
      adjust: true,
      [PLACEHOLDER_ORDER_ADJUSTMENT_ITEM_DESCRIPTION]: `${taxName || `Sales Tax`}${gstRate ? ` ${gstRate}%` : ""}`,
      [PLACEHOLDER_ORDER_ADJUSTMENT_ITEM_AMOUNT]: formatNumber(gstAmount, currency, true, currencyDisplay),
    });
  }

  if (Array.isArray(creditNotes)) {
    creditNotes.forEach(({ v, n }) => {
      if (typeof v === "number") {
        lineItems.push({
          [PLACEHOLDER_ORDER_ADJUSTMENT_ITEM_DESCRIPTION]: `${n || ""}`,
          [PLACEHOLDER_ORDER_ADJUSTMENT_ITEM_AMOUNT]: formatNumber(-v, currency, true, currencyDisplay),
        });
      }
    });
  }

  if (typeof roundingAmount === "number" && roundingAmount !== 0) {
    lineItems.push({
      [PLACEHOLDER_ORDER_ADJUSTMENT_ITEM_DESCRIPTION]: `Rounding`,
      [PLACEHOLDER_ORDER_ADJUSTMENT_ITEM_AMOUNT]: formatNumber(roundingAmount, currency, true, currencyDisplay),
    });
  }

  return lineItems;
}
