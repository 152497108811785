"use client";

import { useClientLang } from "@easybiz/component-shared";
import clsx from "clsx";
// import Link from "next/link";

export default function LocaleLink({ href, className, children, bold, primary, button, ...props }) {
  const locale = useClientLang();

  return (
    <a
      {...props}
      className={clsx(
        "focus:outline-none",
        bold ? "font-semibold" : "font-medium",
        primary ? "text-secondary-500" : "",
        button ? "bg-primary-900 hover:bg-primary-700 focus-visible:outline-secondary-500" : "",
        className
      )}
      href={
        href?.startsWith("http")
          ? href
          : `${href}`.startsWith(`/${locale}`)
          ? href
          : `${href}`.startsWith("/")
          ? `/${locale}${href}`
          : href
      }
      tabIndex={"-1"}
    >
      {children}
    </a>
  );
}
