"use client";

import {
  useClientOTACheck,
  useClientOTAUpdate,
  useClientVersion,
  useUser,
  useUserSignOut,
} from "@easybiz/component-shared";
import { SYSTEM_CLIENT_MOBILE, SYSTEM_CLIENT_POS } from "@easybiz/lib-shared-utils";
import { clsx } from "clsx";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import useServerActionRegisterDevice from "./useServerActionRegisterDevice";

export default function AccessLoadingScreen({
  client,
  getDeviceInfo,
  signInWithCustomToken,
  getAuth,
  Button,
  Text,
  View,
  Image,
  imageSource,
}) {
  const { loading, onSubmit } = useServerActionRegisterDevice(signInWithCustomToken, getAuth, getDeviceInfo);
  const otaUpdate = useClientOTAUpdate();
  const otaCheck = useClientOTACheck();
  const clientVersion = useClientVersion();
  const onSignOut = useUserSignOut();
  const [start] = useState(Date.now());
  const [updating, setUpdating] = useState(false);
  const [current, setCurrent] = useState(Date.now());
  const user = useUser();
  const spanSeconds = Math.floor((current - start) / 1000);
  const tooLong = spanSeconds >= 30;

  useEffect(() => {
    const handler = setInterval(() => {
      setCurrent(Date.now());
    }, 5000);

    return () => {
      clearInterval(handler);
    };
  }, []);

  return (
    <View className="flex flex-col h-screen justify-center items-center bg-gray-50">
      <View className="max-w-xs flex flex-col gap-4">
        <View className="flex flex-row gap-4 items-center self-center animate-pulse">
          <Image
            {...(imageSource ? { source: imageSource } : { src: "/images/platform_logo.webp" })}
            className="size-24 rounded-md drop-shadow-lg"
          />
          <Text
            className={clsx(
              "uppercase font-extrabold top-0 right-0 drop-shadow-lg",
              client === SYSTEM_CLIENT_MOBILE ? "text-gray-900" : "bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500",
              client === SYSTEM_CLIENT_POS ? "text-5xl" : "text-4xl"
            )}
          >
            {client === SYSTEM_CLIENT_MOBILE ? "WORKFLOW" : client}
          </Text>
        </View>
        <Text className="text-base text-gray-500 animate-pulse text-center">
          {user ? (
            <FormattedMessage id="Registering device…" defaultMessage="Registering device…" />
          ) : (
            <FormattedMessage id="authenticating…" defaultMessage="Authenticating…" />
          )}
        </Text>
        {spanSeconds >= 10 && (
          <>
            <Text className={clsx("text-sm text-gray-500 text-center", tooLong ? "" : "animate-pulse")}>
              (
              {tooLong ? (
                <FormattedMessage
                  id="splash.screen.fallback.help"
                  defaultMessage="Sorry, we are having trouble connecting. Please check your internet connection."
                />
              ) : (
                <FormattedMessage
                  id="splash.screen.long.time.help"
                  defaultMessage="Sorry, this is taking longer than usual. Please bear with us."
                />
              )}
              )
            </Text>
            {tooLong && (
              <View className="flex flex-row gap-4">
                <Button className="flex-1" danger onClick={onSignOut}>
                  <FormattedMessage id="sign.out" defaultMessage="Sign out" />
                </Button>
                <Button
                  className="flex-1"
                  onClick={() => (user?.uid ? onSubmit() : window.location.reload(true))}
                  loading={loading}
                >
                  {user?.uid ? (
                    <FormattedMessage id="retry" defaultMessage="Retry" />
                  ) : (
                    <FormattedMessage id="reload" defaultMessage="Reload" />
                  )}
                </Button>
              </View>
            )}
          </>
        )}
        <Button
          type={otaUpdate ? "primary" : "text"}
          onClick={
            typeof otaUpdate === "function"
              ? () => {
                  otaUpdate();
                  setUpdating(true);
                }
              : otaCheck
          }
          loading={updating}
        >
          {`v${clientVersion}`}
          {otaUpdate && (
            <>
              {` (`}
              <FormattedMessage id="update.available" defaultMessage="Click to update" />
              {`)`}
            </>
          )}
        </Button>
      </View>
    </View>
  );
}
