export default function formatPhotoUrl(photoId, realmId, size) {
  if (`${photoId}`.includes("_")) {
    const [paths, id] = `${photoId}`.split("_");
    const [region] = `${realmId}`.includes("_") ? `${realmId}`.split("_") : ["sg"];
    return `https://firebasestorage.googleapis.com/v0/b/easybiz-${region}/o/${encodeURIComponent(
      `${paths.split("").join("/")}/${realmId}/${id}/${size || "default"}`
    )}?alt=media`;
  } else if (photoId) {
    // Old format
    return `https://storage.googleapis.com/easybus-img/${photoId}`;
  }
}
