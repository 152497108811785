import { useUserRealmId } from "@easybiz/component-shared";
import { STATUSES_KEY_ORDER_STATE } from "@easybiz/utils";
import { Spin, Tag } from "antd";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";

export default function DailyOrderStateTag({ date, businessCode, awaitAction }) {
  const realmId = useUserRealmId();
  const [orders, setOrders] = useState();

  useEffect(() => {
    if (realmId && businessCode && date) {
      return onSnapshot(
        doc(getFirestore(), `realms/${realmId}/statuses/${STATUSES_KEY_ORDER_STATE}/keys/${date}-${businessCode}`),
        (snapshot) => {
          setOrders(Object.keys(snapshot.get("data") || {}).map((id) => ({ ...snapshot.get(`data.${id}`), id })));
        }
      );
    }
  }, [realmId, businessCode, date]);

  if (!orders) {
    return <Spin spinning />;
  } else if (orders.length > 0) {
    const processed = orders.filter((order) => !order.awaits?.includes(awaitAction)).length;

    return (
      <Tag color={processed === orders.length ? "success" : "processing"}>
        {processed}/{orders.length}
      </Tag>
    );
  } else {
    return null;
  }
}
