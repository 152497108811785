import { CustomerDisplay, DateDisplay } from "@easybiz/component";
import { useCheckoutCustomer, useClientState, useClientTheme, useUserRealmId } from "@easybiz/component-shared";
import { formatMediaUrl } from "@easybiz/lib-shared-utils";
import { translate } from "@easybiz/utils";
import { FormattedMessage } from "react-intl";

function useMainActionMembershipPanel(customerDoc) {
  const customer = useCheckoutCustomer();
  const members = customerDoc?.get("members");
  const secondaryContainer = useClientTheme()?.secondaryContainer;
  const realmId = useUserRealmId();
  const { date } = useClientState();

  return {
    header: <FormattedMessage defaultMessage={"Member"} />,
    selectAccountAlert: !customer && (
      <FormattedMessage
        defaultMessage={`Select customer account to view customer's member state and join membership`}
      />
    ),
    ...(Array.isArray(members) && members.length > 0
      ? {
          members: members.map(({ id, title, photoId, expireDate, benefits }) => {
            let expired = expireDate ? date > expireDate : false;

            return {
              id,
              title: translate(title),
              backgroundColor: secondaryContainer,
              expired,
              benefits: benefits || [],
              status: expired ? (
                <FormattedMessage
                  defaultMessage={"Expired (Already expired on {date})"}
                  values={{
                    date: <DateDisplay date={expireDate} />,
                  }}
                />
              ) : expireDate ? (
                <FormattedMessage
                  defaultMessage={"Valid (Expire on {date})"}
                  values={{
                    date: <DateDisplay date={expireDate} />,
                  }}
                />
              ) : (
                <FormattedMessage defaultMessage={"Never expires"} />
              ),
              ...(photoId && { badgeUrl: formatMediaUrl(photoId, realmId) }),
            };
          }),
        }
      : {
          notAMember: (
            <FormattedMessage
              defaultMessage={"Account {accountName} is not a member yet"}
              values={{
                accountName: <CustomerDisplay customer={customer} />,
              }}
            />
          ),
        }),
  };
}

export default useMainActionMembershipPanel;
