import { DeleteOutlined } from "@ant-design/icons";
import { useUpdateCheckoutItems } from "@easybiz/checkout";
import { useCheckoutEditItemSetter } from "@easybiz/component-shared";
import { Button, message } from "antd";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PopConfirm from "../PopConfirm";

export default ({ item }) => {
  const updateItems = useUpdateCheckoutItems();
  const setEditItem = useCheckoutEditItemSetter();
  const { product, itemIndex } = item || {};
  const itemName = `${product} #${itemIndex + 1}`;
  const intl = useIntl();

  return (
    <PopConfirm
      title={<FormattedMessage defaultMessage="Remove line item {itemName}?" values={{ itemName }} />}
      okButtonProps={{ danger: true }}
      okText={<FormattedMessage defaultMessage={"Remove"} />}
      onConfirm={() => {
        updateItems({ ...item, qty: 0 });
        message.success(intl.formatMessage({ defaultMessage: "{itemName} removed" }, { itemName }));
        setEditItem(null);
      }}
    >
      <Button icon={<DeleteOutlined />} type="text" danger>
        <FormattedMessage tagName={"span"} defaultMessage="Remove" />
      </Button>
    </PopConfirm>
  );
};
