import { useActionCallback } from "@easybiz/hook";
import { useFeedbackToast } from "@easybiz/component-shared";
import { OPERATION_GROUP_ORDER, ORDER_ACTION_PAYMENT, PAYMENT_ID_STRIPE } from "@easybiz/utils";
import { useState } from "react";
import { useIntl } from "react-intl";

function useOrderPayment(callback) {
  const intl = useIntl();
  const [browserPay, setBrowserPay] = useState();
  const toast = useFeedbackToast();

  return useActionCallback(
    ({ orderId, payment, isDeposit, signature }, browserPay) => {
      if (!payment?.option) {
        return intl.formatMessage({
          id: "please.select.payment.method",
          defaultMessage: "Please select payment method",
        });
      }
      if (typeof payment.amount !== "number" || payment.amount <= 0) {
        return intl.formatMessage({
          id: "please.enter.payment.amount",
          defaultMessage: `Please enter the payment amount`,
        });
      }

      if (payment?.noteRequired && !payment.note) {
        return intl.formatMessage({
          id: "please.leave.a.payment.note",
          defaultMessage: "Please leave a payment note",
        });
      }

      setBrowserPay(browserPay);

      return [
        OPERATION_GROUP_ORDER,
        ORDER_ACTION_PAYMENT,
        {
          orderId,
          amount: payment.amount,
          paymentOption: payment.option,
          ...(payment.option === PAYMENT_ID_STRIPE && { paymentMethodId: payment.id }),
          ...(payment.note && { note: payment.note }),
          ...(payment.date && { backDate: payment.date }),
          ...(payment.rounding && { rounding: payment.rounding }),
          ...(isDeposit && { isDeposit: true }),
          ...(browserPay && { confirm: true }),
          ...(signature && { signature }),
        },
      ];
    },
    (succeed, response, request) => {
      if (browserPay && typeof browserPay.complete === "function") {
        if (succeed) {
          browserPay.complete("success");
          setBrowserPay(null);
        } else {
          browserPay.complete("fail");
          setBrowserPay(null);
        }
      }
      callback(succeed, response, request);
    },
    (request, response) => {
      if (!response.paymentIntent) {
        toast.success(
          intl.formatMessage({
            id: "payment.succeed",
            defaultMessage: "Payment succeed",
          })
        );
      }
    }
  );
}

export default useOrderPayment;
