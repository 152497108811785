"use client";

import { useClientStateSetter, useUserRealmId } from "@easybiz/component-shared";
import { LazyImage } from "@easybiz/component-web-business-ui";
import { formatMediaUrl } from "@easybiz/lib-shared-utils";
import dayjs from "dayjs";
import { useMemo } from "react";

function PhotoGallery({ orderId, itemIndex, limit, size, orderOnly, photos }) {
  const setState = useClientStateSetter();
  const realmId = useUserRealmId();

  const displayPhotos = useMemo(() => {
    return (photos || [])
      .filter((photo) => (orderOnly ? !photo.l : typeof itemIndex !== "number" || itemIndex === photo.l))
      .map((photo) => {
        return {
          id: photo.i,
          thumbUrl: formatMediaUrl(photo.i, realmId, true),
          url: formatMediaUrl(photo.i, realmId),
          title: `${dayjs.unix(photo.t).format("DD/MM/YYYY HH:mm")} | ${photo.o}`,
          timestamp: photo.t,
        };
      });
  }, [photos, realmId]);

  return displayPhotos.map((photo, index) => {
    if (typeof limit === "number" && index >= limit) return null;

    return (
      <div key={photo.url} style={{ width: size || 70 }}>
        <div
          className="selectable"
          onClick={(e) => {
            e.stopPropagation();
            setState({ gallery: { photos: displayPhotos, viewIndex: index, orderId, itemIndex } });
          }}
        >
          <LazyImage src={photo.thumbUrl || photo.url} width={size || 70} height={size || 70} />
        </div>
      </div>
    );
  });
}

export default PhotoGallery;
