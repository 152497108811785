"use client";

import {
  DATA_ACCESS_SALES_DATA,
  PERMISSION_LEVEL_BLOCKED,
  PERMISSION_LEVEL_TIME_LIMITED,
  PERMISSION_TIME_SAME_DAY,
  PERMISSION_TIME_WITHIN_X_DAY,
} from "@easybiz/lib-shared-auth";
import { useAccessData } from "@easybiz/component-shared";
import dayjs from "dayjs";

export default function usePOSSalesAccessDate(date) {
  const [{ level, timeWindow, days }] = useAccessData(DATA_ACCESS_SALES_DATA);

  switch (level) {
    case PERMISSION_LEVEL_BLOCKED:
      return false;
    case PERMISSION_LEVEL_TIME_LIMITED:
      if (timeWindow === PERMISSION_TIME_SAME_DAY) {
        return dayjs().format("YYYY-MM-DD") === date ? date : false;
      } else if (timeWindow === PERMISSION_TIME_WITHIN_X_DAY) {
        return date >= dayjs().subtract(parseInt(days), "days").format("YYYY-MM-DD") ? date : false;
      } else {
        return false;
      }
    default:
      return date;
  }
}
