import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";
import {
  DISPLAY_STATUS_CANCELLED,
  DISPLAY_STATUS_PAID,
  DISPLAY_STATUS_PENDING_CONFIRM,
  DISPLAY_STATUS_REDO,
  DISPLAY_STATUS_UNPAID,
  DISPLAY_TYPE_HOME_DELIVERY,
  DISPLAY_TYPE_STORE_PICKUP,
  PLACEHOLDER_COMPANY_ADDRESS,
  PLACEHOLDER_COMPANY_BRAND_NAME,
  PLACEHOLDER_COMPANY_EMAIL,
  PLACEHOLDER_COMPANY_LEGAL_NAME,
  PLACEHOLDER_COMPANY_LOGO,
  PLACEHOLDER_COMPANY_PHONE_NUMBER,
  PLACEHOLDER_COMPANY_REGISTRATION_NUMBER,
  PLACEHOLDER_COMPANY_THEME,
  PLACEHOLDER_COMPANY_WEBSITE,
  PLACEHOLDER_CREDIT_BALANCE,
  PLACEHOLDER_CURRENT_YEAR,
  PLACEHOLDER_CUSTOMER_ADDRESS,
  PLACEHOLDER_CUSTOMER_EMAIL,
  PLACEHOLDER_CUSTOMER_ID,
  PLACEHOLDER_CUSTOMER_INDEX,
  PLACEHOLDER_CUSTOMER_NAME,
  PLACEHOLDER_CUSTOMER_PHONE_NUMBER,
  PLACEHOLDER_CUSTOMER_PRIVATE_NOTE,
  PLACEHOLDER_DATA_SET_TRANSACTION,
  PLACEHOLDER_HANDOVER_BY,
  PLACEHOLDER_HANDOVER_DATE,
  PLACEHOLDER_HANDOVER_ORDERS,
  PLACEHOLDER_HANDOVER_TIME,
  PLACEHOLDER_HANDOVER_TOTAL_AMOUNT,
  PLACEHOLDER_HANDOVER_TOTAL_ORDERS,
  PLACEHOLDER_HANDOVER_TOTAL_PCS,
  PLACEHOLDER_HANDOVER_TYPE,
  PLACEHOLDER_JOB_ADDRESS,
  PLACEHOLDER_JOB_ID,
  PLACEHOLDER_JOB_NOTE,
  PLACEHOLDER_JOB_SERVICE_TYPES,
  PLACEHOLDER_JOB_TIME,
  PLACEHOLDER_JOB_TYPE,
  PLACEHOLDER_JOB_WORKER,
  PLACEHOLDER_ORDER_ADJUSTMENT_ITEMS,
  PLACEHOLDER_ORDER_AMOUNT_DUE,
  PLACEHOLDER_ORDER_AMOUNT_DUE_RAW,
  PLACEHOLDER_ORDER_AMOUNT_PAID,
  PLACEHOLDER_ORDER_BOOKING_SURCHARGE,
  PLACEHOLDER_ORDER_BUSINESS_UNIT_CODE,
  PLACEHOLDER_ORDER_BUSINESS_UNIT_ID,
  PLACEHOLDER_ORDER_BUSINESS_UNIT_NAME,
  PLACEHOLDER_ORDER_CONFIRMED_AT,
  PLACEHOLDER_ORDER_CONFIRMED_BY,
  PLACEHOLDER_ORDER_DELIVERY_ADDRESS,
  PLACEHOLDER_ORDER_DELIVERY_COMPLETE_AT,
  PLACEHOLDER_ORDER_DELIVERY_DATE,
  PLACEHOLDER_ORDER_DELIVERY_LEAD_TIME,
  PLACEHOLDER_ORDER_DELIVERY_NOTE,
  PLACEHOLDER_ORDER_DELIVERY_OPTION_NAME,
  PLACEHOLDER_ORDER_DELIVERY_TIME,
  PLACEHOLDER_ORDER_DELIVERY_WEEKDAY,
  PLACEHOLDER_ORDER_DELIVERY_WORKER,
  PLACEHOLDER_ORDER_DUE_DATE,
  PLACEHOLDER_ORDER_ID,
  PLACEHOLDER_ORDER_INVOICE_DATE,
  PLACEHOLDER_ORDER_LAST_PAYMENT_DATE,
  PLACEHOLDER_ORDER_LINE_ITEMS,
  PLACEHOLDER_ORDER_MADE_BY,
  PLACEHOLDER_ORDER_MADE_DATE,
  PLACEHOLDER_ORDER_MADE_TIME,
  PLACEHOLDER_ORDER_MADE_WEEKDAY,
  PLACEHOLDER_ORDER_MADE_WEEKDAY_INDEX,
  PLACEHOLDER_ORDER_NO,
  PLACEHOLDER_ORDER_NOTE,
  PLACEHOLDER_ORDER_PACKAGES,
  PLACEHOLDER_ORDER_PAYMENTS,
  PLACEHOLDER_ORDER_PAYNOW_IMAGE_URL,
  PLACEHOLDER_ORDER_PAYNOW_QR_CONTENT,
  PLACEHOLDER_ORDER_PICKUP_ADDRESS,
  PLACEHOLDER_ORDER_PICKUP_COMPLETE_AT,
  PLACEHOLDER_ORDER_PICKUP_DATE,
  PLACEHOLDER_ORDER_PICKUP_NOTE,
  PLACEHOLDER_ORDER_PICKUP_STORE_ADDRESS,
  PLACEHOLDER_ORDER_PICKUP_STORE_NAME,
  PLACEHOLDER_ORDER_PICKUP_STORE_OPENING_HOURS,
  PLACEHOLDER_ORDER_PICKUP_STORE_PHONE_NUMBER,
  PLACEHOLDER_ORDER_PICKUP_TIME,
  PLACEHOLDER_ORDER_PICKUP_WEEKDAY,
  PLACEHOLDER_ORDER_PICKUP_WORKER,
  PLACEHOLDER_ORDER_REDO_ORDER_NO,
  PLACEHOLDER_ORDER_RETURN_TYPE,
  PLACEHOLDER_ORDER_SALES_CHANNEL_ID,
  PLACEHOLDER_ORDER_STATUS,
  PLACEHOLDER_ORDER_STORE_PICKUP_DATE,
  PLACEHOLDER_ORDER_STORE_PICKUP_WEEKDAY,
  PLACEHOLDER_ORDER_SUBTOTAL,
  PLACEHOLDER_ORDER_TAX_AMOUNT,
  PLACEHOLDER_ORDER_TAX_RATE,
  PLACEHOLDER_ORDER_TAX_TYPE,
  PLACEHOLDER_ORDER_TOTAL,
  PLACEHOLDER_ORDER_TOTAL_PACKAGES,
  PLACEHOLDER_ORDER_TOTAL_PCS,
  PLACEHOLDER_ORDER_TOTAL_UNITS,
  PLACEHOLDER_ORDER_TOTAL_WITH_OUT_TAX,
  PLACEHOLDER_OTP,
  PLACEHOLDER_OTP_PREFIX,
  PLACEHOLDER_REWARD_POINTS_BALANCE,
  PLACEHOLDER_SALES_CHANNEL_ADDRESS,
  PLACEHOLDER_SALES_CHANNEL_CODE,
  PLACEHOLDER_SALES_CHANNEL_DOMAIN,
  PLACEHOLDER_SALES_CHANNEL_NAME,
  PLACEHOLDER_SALES_CHANNEL_OPENING_HOURS,
  PLACEHOLDER_SALES_CHANNEL_PHONE_NUMBER,
  PLACEHOLDER_SIGNATURE,
  PLACEHOLDER_SUB_DATA_SET_ADJUSTMENT_ITEM,
  PLACEHOLDER_SUB_DATA_SET_LINE_ITEM,
  PLACEHOLDER_SUB_DATA_SET_PACKAGE,
  PLACEHOLDER_TRANSACTION_AMOUNT,
  PLACEHOLDER_TRANSACTION_BALANCE_AFTER,
  PLACEHOLDER_TRANSACTION_BALANCE_BEFORE,
  PLACEHOLDER_TRANSACTION_DATE,
  PLACEHOLDER_TRANSACTION_ID,
  PLACEHOLDER_TRANSACTION_METHOD,
} from "./constants/content.js";
import { TEMPLATE_CATEGORY_LABEL, TEMPLATE_CATEGORY_RECEIPT } from "./constants/template.js";
import formatDate from "./formatDate.js";
import formatNumber from "./formatNumber.js";
import formatOpeningHours from "./formatOpeningHours.js";
import formatScheduleTime from "./formatScheduleTime.js";
import formatString from "./formatString.js";
import formatTemplateAdjustmentItems from "./formatTemplateAdjustmentItems.js";
import formatTemplateLineItems from "./formatTemplateLineItems.js";
import formatTemplatePackages from "./formatTemplatePackages.js";
import formatTemplateTransactions from "./formatTemplateTransactions.js";
import formatTime from "./formatTime.js";
import formatTransaction from "./formatTransaction.js";
import generatePAYNOWQR from "./generatePAYNOWQR.js";
import getOrderTaxAmount from "./getOrderTaxAmount.js";
import getOrderTotal from "./getOrderTotal.js";
import toDisplayName from "./toDisplayName.js";
import toDisplayTaxType from "./toDisplayTaxType.js";
import toProcedureCompleteTitle from "./toProcedureCompleteTitle.js";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function formatTemplateRenderParams(
  { placeholders, placeholderParams, dataSet },
  templateParams,
  context
) {
  const {
    order,
    orders,
    customer,
    business,
    company,
    task,
    pickupTask,
    deliveryTask,
    creditWallet,
    rewardPoints,
    collectionPlace,
    signature,
    otp,
    payNowUrl,
    handover,
  } = dataSet;
  const { currencyDisplay, dateFormat, timeFormat, category, contentType } = templateParams || {};
  const { realmId, currency, serviceCodes, timeZone } = context;
  const transaction = formatTransaction(order, dataSet.transaction, timeZone, dateFormat);

  const renderParams = {};

  if (order && placeholders.some((placeholder) => `${placeholder}`.startsWith(PLACEHOLDER_SUB_DATA_SET_LINE_ITEM))) {
    renderParams[PLACEHOLDER_ORDER_LINE_ITEMS] = formatTemplateLineItems(
      order,
      realmId,
      placeholderParams[PLACEHOLDER_ORDER_LINE_ITEMS]?.dateFormat || dateFormat,
      placeholderParams[PLACEHOLDER_ORDER_LINE_ITEMS]?.currencyDisplay || currencyDisplay,
      placeholders,
      contentType
    );
  }

  if (order && placeholders.some((placeholder) => `${placeholder}`.startsWith(PLACEHOLDER_SUB_DATA_SET_PACKAGE))) {
    renderParams[PLACEHOLDER_ORDER_PACKAGES] = formatTemplatePackages(order, dateFormat);
  }

  if (order && placeholders.some((placeholder) => `${placeholder}`.startsWith(PLACEHOLDER_DATA_SET_TRANSACTION))) {
    renderParams[PLACEHOLDER_ORDER_PAYMENTS] = formatTemplateTransactions(
      order,
      context,
      currencyDisplay,
      dateFormat,
      placeholders
    );
  }

  if (
    order &&
    placeholders.some((placeholder) => `${placeholder}`.startsWith(PLACEHOLDER_SUB_DATA_SET_ADJUSTMENT_ITEM))
  ) {
    renderParams[PLACEHOLDER_ORDER_ADJUSTMENT_ITEMS] = formatTemplateAdjustmentItems(order, currencyDisplay);
  }

  if (orders && placeholders.includes(PLACEHOLDER_HANDOVER_ORDERS)) {
    renderParams[PLACEHOLDER_HANDOVER_ORDERS] = orders.map((order) =>
      formatTemplateRenderParams(
        { placeholders, placeholderParams, dataSet: { order } },
        { ...templateParams, ...placeholderParams?.[PLACEHOLDER_HANDOVER_ORDERS] },
        context
      )
    );
  }

  for (const dataKey of placeholders) {
    let value, rawDate, rawDateFormat;

    switch (dataKey) {
      // Handover
      case PLACEHOLDER_HANDOVER_DATE:
        value = handover?.date && formatDate(handover.date, dateFormat);
        break;
      case PLACEHOLDER_HANDOVER_TIME:
        value = handover?.timestamp && formatTime(handover.timestamp, timeZone, timeFormat);
        break;
      case PLACEHOLDER_HANDOVER_TYPE:
        value = handover?.procedure && toProcedureCompleteTitle(handover.procedure);
        break;
      case PLACEHOLDER_HANDOVER_BY:
        value = handover?.operator?.name;
        break;
      case PLACEHOLDER_HANDOVER_TOTAL_ORDERS:
        value = orders?.length;
        break;
      case PLACEHOLDER_HANDOVER_TOTAL_PCS:
        value =
          orders && orders.reduce((total, order) => total + (Number.isInteger(order.totalPcs) ? order.totalPcs : 0), 0);
        break;
      case PLACEHOLDER_HANDOVER_TOTAL_AMOUNT:
        value =
          orders &&
          formatNumber(
            orders.reduce((total, order) => total + (order.netSales || 0) - (order.totalWaived || 0), 0),
            currency,
            true,
            currencyDisplay
          );
        break;
      // Order
      case PLACEHOLDER_ORDER_ID:
        value = order?.id || task?.orderId;
        break;
      case PLACEHOLDER_ORDER_NO:
        value = order?.orderNo || task?.orderNo;
        break;
      case PLACEHOLDER_ORDER_BUSINESS_UNIT_ID:
        value = order?.service?.id || task?.serviceId;
        break;
      case PLACEHOLDER_ORDER_BUSINESS_UNIT_NAME:
        value = order?.service?.title;
        break;
      case PLACEHOLDER_ORDER_BUSINESS_UNIT_CODE:
        value = serviceCodes?.[order?.service?.id];
        break;
      case PLACEHOLDER_ORDER_SALES_CHANNEL_ID:
        value = order?.business?.id || task?.businessCode;
        break;
      case PLACEHOLDER_ORDER_TOTAL_UNITS:
        value = order?.totalUnit;
        break;
      case PLACEHOLDER_ORDER_TOTAL_PCS:
        value = order?.totalPcs;
        break;
      case PLACEHOLDER_ORDER_TOTAL_PACKAGES:
        value = order?.packs?.length;
        break;
      case PLACEHOLDER_ORDER_NOTE:
        value = order?.salesRemark;
        break;
      case PLACEHOLDER_ORDER_SUBTOTAL:
        value = order && formatNumber(order.subTotalAmount, currency, true, currencyDisplay);
        break;
      case PLACEHOLDER_ORDER_TOTAL:
        value = order && formatNumber(getOrderTotal(order), currency, true, currencyDisplay);
        break;
      case PLACEHOLDER_ORDER_TOTAL_WITH_OUT_TAX:
        if (order) {
          const total = getOrderTotal(order);
          const taxAmount = getOrderTaxAmount(order);
          value = taxAmount
            ? formatNumber(total - taxAmount, currency, true, currencyDisplay)
            : formatNumber(total, currency, true, currencyDisplay);
        }
        break;
      case PLACEHOLDER_ORDER_TAX_RATE: {
        const rate = order?.priceList ? order?.priceList?.taxRate : order?.gstRate;
        if (rate) {
          value = `${rate}%`;
        }
        break;
      }
      case PLACEHOLDER_ORDER_TAX_TYPE:
        if (order) {
          value = order.priceList
            ? toDisplayTaxType(order.priceList.taxType)
            : order.gstRate
            ? order.taxName || "Sales Tax"
            : null;
        }
        break;
      case PLACEHOLDER_ORDER_TAX_AMOUNT:
        if (order) {
          const taxAmount = getOrderTaxAmount(order);
          value = formatNumber(taxAmount || 0, currency, true, currencyDisplay);
        }
        break;
      case PLACEHOLDER_ORDER_AMOUNT_DUE:
        value = order && formatNumber(order.totalBalance, currency, true, currencyDisplay);
        break;
      case PLACEHOLDER_ORDER_AMOUNT_DUE_RAW:
        value = order?.totalBalance;
        break;
      case PLACEHOLDER_ORDER_STATUS:
        if (order) {
          value = order.voided
            ? DISPLAY_STATUS_CANCELLED
            : order.redoOrderId
            ? DISPLAY_STATUS_REDO
            : order.pricingTBD
            ? DISPLAY_STATUS_PENDING_CONFIRM
            : order.paid
            ? DISPLAY_STATUS_PAID
            : DISPLAY_STATUS_UNPAID;
        }
        break;
      case PLACEHOLDER_ORDER_AMOUNT_PAID:
        value = order && formatNumber(order.totalPaid, currency, true, currencyDisplay);
        break;
      case PLACEHOLDER_ORDER_MADE_DATE:
        value = order && formatDate(order.date, dateFormat);
        break;
      case PLACEHOLDER_ORDER_MADE_TIME:
        value = order && formatTime(order.timestamp, timeZone, timeFormat);
        break;
      case PLACEHOLDER_ORDER_MADE_WEEKDAY:
        value = order && formatDate(order.date, "ddd");
        break;
      case PLACEHOLDER_ORDER_MADE_WEEKDAY_INDEX:
        if (order?.date) {
          const day = dayjs(order.date).day();
          value = day === 0 ? 7 : day;
        }
        break;
      case PLACEHOLDER_ORDER_MADE_BY:
        value = order?.creator?.name;
        break;
      case PLACEHOLDER_ORDER_REDO_ORDER_NO:
        value = order?.redoOrderNo;
        break;
      case PLACEHOLDER_ORDER_STORE_PICKUP_DATE:
        value = order?.collections?.[0] && formatDate(order?.collections?.[0], dateFormat);
        rawDate = order?.collections?.[0];
        break;
      case PLACEHOLDER_ORDER_STORE_PICKUP_WEEKDAY:
        value = order?.collections?.[0] && formatDate(order?.collections?.[0], "ddd");
        rawDate = order?.collections?.[0];
        rawDateFormat = "ddd";
        break;
      case PLACEHOLDER_ORDER_INVOICE_DATE:
      case PLACEHOLDER_ORDER_DUE_DATE: {
        const date = order?.pricingConfirmDate?.date || order?.date;
        value = date && formatDate(date, dateFormat);
        break;
      }
      case PLACEHOLDER_ORDER_CONFIRMED_AT:
        if (order && !order.pricingTBD) {
          const confirmed = order?.pricingConfirmDate || order?.created;
          value =
            confirmed &&
            formatDate(`${confirmed.date}T${confirmed.time}`, `${timeFormat || "HH:mm"} ${dateFormat || "DD/MM/YYYY"}`);
        }
        break;
      case PLACEHOLDER_ORDER_CONFIRMED_BY:
        value = order?.pricingConfirmBy?.name;
        break;
      case PLACEHOLDER_ORDER_RETURN_TYPE:
        value = order && (order.homeDelivery ? DISPLAY_TYPE_HOME_DELIVERY : DISPLAY_TYPE_STORE_PICKUP);
        break;
      case PLACEHOLDER_ORDER_LAST_PAYMENT_DATE:
        value = order?.lastPayTime ? formatDate(dayjs.unix(order?.lastPayTime).tz(timeZone), dateFormat) : null;
        break;
      case PLACEHOLDER_ORDER_PICKUP_TIME:
        value = pickupTask && formatScheduleTime(pickupTask.schedule, timeZone, timeFormat);
        break;
      case PLACEHOLDER_ORDER_PICKUP_DATE:
        value = pickupTask && formatDate(pickupTask.schedule.date, dateFormat);
        rawDate = pickupTask?.schedule?.date;
        break;
      case PLACEHOLDER_ORDER_PICKUP_WEEKDAY:
        value = pickupTask && formatDate(pickupTask.schedule.date, "ddd");
        rawDate = pickupTask?.schedule?.date;
        rawDateFormat = "ddd";
        break;
      case PLACEHOLDER_ORDER_PICKUP_COMPLETE_AT:
        value =
          pickupTask?.completeDate &&
          formatDate(
            `${pickupTask.completeDate.date}T${pickupTask.completeDate.time}`,
            `${timeFormat || "HH:mm"} ${dateFormat || "DD/MM/YYYY"}`
          );
        break;
      case PLACEHOLDER_ORDER_PICKUP_ADDRESS:
        value = pickupTask?.address?.formatted;
        break;
      case PLACEHOLDER_ORDER_PICKUP_WORKER:
        value = pickupTask?.worker?.name;
        break;
      case PLACEHOLDER_ORDER_PICKUP_NOTE:
        value = pickupTask?.note;
        break;
      case PLACEHOLDER_ORDER_DELIVERY_TIME:
        value = deliveryTask && formatScheduleTime(deliveryTask.schedule, timeZone, dateFormat);
        break;
      case PLACEHOLDER_ORDER_DELIVERY_DATE:
        value = deliveryTask && formatDate(deliveryTask.schedule.date, dateFormat);
        rawDate = deliveryTask?.schedule?.date;
        break;
      case PLACEHOLDER_ORDER_DELIVERY_WEEKDAY:
        value = deliveryTask && formatDate(deliveryTask.schedule.date, "ddd");
        rawDate = deliveryTask?.schedule?.date;
        rawDateFormat = "ddd";
        break;
      case PLACEHOLDER_ORDER_DELIVERY_COMPLETE_AT:
        value =
          deliveryTask?.completeDate &&
          formatDate(
            `${deliveryTask.completeDate.date}T${deliveryTask.completeDate.time}`,
            `${timeFormat || "HH:mm"} ${dateFormat || "DD/MM/YYYY"}`
          );
        break;
      case PLACEHOLDER_ORDER_DELIVERY_LEAD_TIME:
        value = order?.delivery?.option?.processDay;
        break;
      case PLACEHOLDER_ORDER_BOOKING_SURCHARGE:
        value = order?.expressAmount;
        break;
      case PLACEHOLDER_ORDER_DELIVERY_OPTION_NAME:
        value = order?.delivery?.option?.title;
        break;
      case PLACEHOLDER_ORDER_DELIVERY_ADDRESS:
        value = deliveryTask?.address?.formatted;
        break;
      case PLACEHOLDER_ORDER_DELIVERY_WORKER:
        value = deliveryTask?.worker?.name;
        break;
      case PLACEHOLDER_ORDER_DELIVERY_NOTE:
        value = deliveryTask?.note;
        break;
      // Job
      case PLACEHOLDER_JOB_ID:
        value = task?.id;
        break;
      case PLACEHOLDER_JOB_TIME:
        value = task?.schedule && formatScheduleTime(task?.schedule, dateFormat);
        break;
      case PLACEHOLDER_JOB_ADDRESS:
        value = task?.address?.formatted;
        break;
      case PLACEHOLDER_JOB_TYPE:
        value = task?.type;
        break;
      case PLACEHOLDER_JOB_WORKER:
        value = task?.worker?.name;
        break;
      case PLACEHOLDER_JOB_SERVICE_TYPES:
        value = task?.serviceTypes
          ?.map((serviceType) => serviceType.title)
          .filter(Boolean)
          .join(", ");
        break;
      case PLACEHOLDER_JOB_NOTE:
        value = task?.note;
        break;
      // Credit
      case PLACEHOLDER_CREDIT_BALANCE:
        value = creditWallet && formatNumber(creditWallet.credit || 0, currency, true, currencyDisplay);
        break;
      case PLACEHOLDER_REWARD_POINTS_BALANCE:
        value = rewardPoints?.point;
        break;
      // Customer
      case PLACEHOLDER_CUSTOMER_ID:
        value = customer?.id;
        break;
      case PLACEHOLDER_CUSTOMER_NAME:
        value = customer?.name;
        break;
      case PLACEHOLDER_CUSTOMER_EMAIL:
        value = customer?.email;
        break;
      case PLACEHOLDER_CUSTOMER_INDEX:
        value = customer?.index;
        break;
      case PLACEHOLDER_CUSTOMER_PRIVATE_NOTE:
        value = customer?.remarks;
        break;
      case PLACEHOLDER_CUSTOMER_ADDRESS:
        value =
          customer?.defaultAddress?.formatted || (typeof customer?.address === "string" ? customer.address : null);
        break;
      case PLACEHOLDER_CUSTOMER_PHONE_NUMBER:
        value = customer?.tels?.[0]?.intl;
        break;
      // Collection place
      case PLACEHOLDER_ORDER_PICKUP_STORE_NAME:
        value = collectionPlace?.name;
        break;
      case PLACEHOLDER_ORDER_PICKUP_STORE_ADDRESS:
        value = collectionPlace?.address;
        break;
      case PLACEHOLDER_ORDER_PICKUP_STORE_PHONE_NUMBER:
        value = collectionPlace?.telFormatted || collectionPlace?.tel;
        break;
      case PLACEHOLDER_ORDER_PICKUP_STORE_OPENING_HOURS:
        value = Array.isArray(collectionPlace?.openingHours)
          ? formatOpeningHours(collectionPlace?.openingHours)
          : collectionPlace.workingHours;
        break;
      case PLACEHOLDER_TRANSACTION_BALANCE_BEFORE:
        value =
          typeof transaction?.balanceBefore === "number"
            ? formatNumber(transaction.balanceBefore, currency, true, currencyDisplay)
            : null;
        break;
      case PLACEHOLDER_TRANSACTION_BALANCE_AFTER:
        value =
          typeof transaction?.balanceAfter === "number"
            ? formatNumber(transaction.balanceAfter, currency, true, currencyDisplay)
            : null;
        break;
      // Sales channel
      case PLACEHOLDER_SALES_CHANNEL_NAME:
        value = business?.name;
        break;
      case PLACEHOLDER_SALES_CHANNEL_CODE:
        value = business?.code;
        break;
      case PLACEHOLDER_SALES_CHANNEL_ADDRESS:
        value = business?.address;
        break;
      case PLACEHOLDER_SALES_CHANNEL_PHONE_NUMBER:
        value = business?.telFormatted || business?.tel || business?.phoneNumber?.intl;
        break;
      case PLACEHOLDER_SALES_CHANNEL_OPENING_HOURS:
        value = formatOpeningHours(business?.openingHours || []);
        break;
      case PLACEHOLDER_SALES_CHANNEL_DOMAIN:
        value = business?.customDomain || business?.domain;
        break;
      // Payment
      case PLACEHOLDER_TRANSACTION_ID:
        value = transaction?.id;
        break;
      case PLACEHOLDER_TRANSACTION_DATE:
        value = transaction?.date;
        break;
      case PLACEHOLDER_TRANSACTION_METHOD:
        value = transaction?.option && toDisplayName(transaction.option, context);
        break;
      case PLACEHOLDER_TRANSACTION_AMOUNT:
        value =
          typeof transaction?.amount === "number"
            ? formatNumber(transaction.amount, currency, true, currencyDisplay)
            : null;
        break;
      // Company
      case PLACEHOLDER_COMPANY_BRAND_NAME:
      case "brand_name":
        value = business?.brand?.name || company?.brandName;
        break;
      case PLACEHOLDER_COMPANY_LEGAL_NAME:
      case "legal_name":
        value = business?.brand?.name || company?.name;
        break;
      case PLACEHOLDER_COMPANY_ADDRESS:
        value = business?.brand?.address || company?.address;
        break;
      case PLACEHOLDER_COMPANY_REGISTRATION_NUMBER:
        value = company?.registerNo;
        break;
      case PLACEHOLDER_COMPANY_LOGO:
        value = [TEMPLATE_CATEGORY_RECEIPT, TEMPLATE_CATEGORY_LABEL].includes(category)
          ? business?.brand?.logoUrl || company?.logoDataUrl
          : company?.logo;
        break;
      case PLACEHOLDER_COMPANY_THEME:
        value = company?.brandTheme;
        break;
      case PLACEHOLDER_COMPANY_EMAIL:
        value = company?.email;
        break;
      case PLACEHOLDER_COMPANY_WEBSITE:
        value = company?.website;
        break;
      case PLACEHOLDER_COMPANY_PHONE_NUMBER:
        value = company?.phoneNumber?.intl;
        break;
      // Others
      case PLACEHOLDER_ORDER_PAYNOW_QR_CONTENT:
        if (company?.registerNo) {
          value = generatePAYNOWQR(company.registerNo, order?.totalBalance, order?.orderNo);
        }
        break;
      case PLACEHOLDER_ORDER_PAYNOW_IMAGE_URL:
        // For Stripe
        value = payNowUrl;
        break;
      case PLACEHOLDER_SIGNATURE:
        value = signature;
        break;
      case PLACEHOLDER_OTP:
        value = otp?.value;
        break;
      case PLACEHOLDER_OTP_PREFIX:
        value = otp?.prefix;
        break;
      case PLACEHOLDER_CURRENT_YEAR:
        value = new Date().getFullYear();
        break;
    }

    if (value !== undefined) {
      renderParams[dataKey] = typeof value === "string" ? formatString(value) : value;

      if (rawDate && Array.isArray(placeholderParams.offsetDays?.[dataKey])) {
        placeholderParams.offsetDays[dataKey].forEach((offset) => {
          renderParams[`${dataKey}${offset}`] = formatDate(rawDate, rawDateFormat || dateFormat, offset);
        });
      }
    }
  }

  return renderParams;
}
