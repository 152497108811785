"use client";

import { useCheckoutEditItem, useCheckoutEditItemSetter, usePriceListCategories, usePriceListProducts } from "@easybiz/component-shared";
import { CATEGORY_OTHERS, safeSearchRegExp } from "@easybiz/utils";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

function useGridSelectionPanel(search, onSearch) {
  const products = usePriceListProducts();
  const categories = usePriceListCategories();
  const setEditItem = useCheckoutEditItemSetter();
  const item = useCheckoutEditItem();
  const defaultActiveKey = Array.isArray(categories) && categories[0]?.key;
  const [filter, setFilter] = useState();
  const [selectActiveKey, setSelectActiveKey] = useState(null);
  const activeKey = selectActiveKey || defaultActiveKey;

  useEffect(() => {
    if (filter) {
      if (item) {
        setEditItem(null);
      }

      if (search) {
        onSearch(null);
      }
    }
  }, [filter]);

  useEffect(() => {
    if (search) {
      if (item) {
        setEditItem(null);
      }
      if (filter) {
        setFilter(null);
      }
    }
  }, [search]);

  useEffect(() => {
    if (selectActiveKey && !categories?.find((category) => category.key == activeKey)) {
      setSelectActiveKey(null);
    }
  }, [defaultActiveKey, selectActiveKey, categories]);

  // Auto change category base on edit item
  useEffect(() => {
    if (Array.isArray(categories) && item) {
      const selectCategory = categories.find((category) =>
        category.products?.find((product) => product.key === item.productId)
      );

      if (selectCategory) {
        setSelectActiveKey(selectCategory.key);
      }
    }
  }, [item]);

  return useMemo(() => {
    let formatProducts;

    if (Array.isArray(categories)) {
      formatProducts = [].concat.apply(
        [],
        categories.map((category) => category.products || [])
      );
      formatProducts.filter(
        (product, index) => formatProducts.findIndex((other) => other.productId === product.productId) === index
      );
    } else if (Array.isArray(products)) {
      formatProducts = products;
    }

    let allIndexes;

    if (formatProducts?.length >= 10) {
      formatProducts = formatProducts?.map((product) => {
        const indexes = [].concat
          .apply(
            [],
            `${product.title || ""}`
              .split(" ")
              .map((content) =>
                `${content || ""}`.split("/").map((data) => `${data || ""}`.replace(/\W+/g, " ").trim())
              )
          )
          .filter((data) => data && `${data}`.length > 2)
          .map((data) => `${data}`.trim().slice(0, 1).toUpperCase());

        return {
          ...product,
          indexes: indexes.filter((data, index) => indexes.indexOf(data) === index),
        };
      });

      allIndexes = [].concat.apply(
        [],
        formatProducts.map((product) => product.indexes)
      );
      allIndexes = allIndexes.filter((data, index) => allIndexes.indexOf(data) === index).sort();

      if (formatProducts.find((indexes) => indexes.length === 0)) {
        allIndexes.push("?");
      }

      if (search) {
        const regX = safeSearchRegExp(search);
        formatProducts = formatProducts.filter((product) => regX.test(product.title));
      } else if (filter) {
        formatProducts = formatProducts.filter(({ indexes }) =>
          filter === "?" ? indexes.length === 0 : indexes.indexOf(filter) >= 0
        );
      } else if (activeKey) {
        formatProducts = categories?.find((category) => category.key === activeKey)?.products;
      }
    }

    return {
      filter: search || filter,
      activeKey: filter || search ? "index" : Array.isArray(categories) ? activeKey : null,
      menus: filter
        ? [
            {
              key: "index",
              label: <FormattedMessage defaultMessage={"Index: {index}"} values={{ index: filter }} />,
            },
          ]
        : (categories || []).map((category) => ({
            key: category.key,
            label: category.key === CATEGORY_OTHERS ? <FormattedMessage defaultMessage={"Others"} /> : category.title,
          })),
      products: formatProducts,
      indexes: allIndexes,
      setActiveKey: setSelectActiveKey,
      setFilter,
    };
  }, [categories, products, filter, search, activeKey]);
}

export default useGridSelectionPanel;
