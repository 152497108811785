"use client";

import { useUserCountryCode } from "@easybiz/component-shared";
import usePlacesAutocomplete from "use-places-autocomplete";

function useGooglePlaces(defaultValue) {
  const countryCode = useUserCountryCode();

  const result = usePlacesAutocomplete({
    defaultValue,
    initOnMount: true,
    requestOptions: {
      locationBias: "IP_BIAS",
      ...(countryCode && {
        componentRestrictions: { country: `${countryCode}`.toLowerCase() },
      }),
    },
    debounce: 300,
  });
  const {
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = result;

  return {
    value,
    setValue,
    clear: clearSuggestions,
    places: data,
  };
}

export default useGooglePlaces;
