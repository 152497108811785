import { NameDisplay } from "@easybiz/component";
import { useWorkflowSignOut } from "@easybiz/hook";
import { useAccessShiftManagement, useUserBusinessCode } from "@easybiz/component-shared";
import { Alert } from "antd";
import { getAuth, signOut } from "firebase/auth";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import Dialog from "../Dialog";

export default function ({ open, onCancel }) {
  const businessCode = useUserBusinessCode();
  const onSignOut = useWorkflowSignOut(signOut, getAuth);
  const shiftEnabled = useAccessShiftManagement();
  const [processing, setProcessing] = useState(false);

  return (
    <Dialog
      title={<FormattedMessage tagName="span" defaultMessage="Sign out?" />}
      open={open}
      onCancel={onCancel}
      onOk={() => {
        setProcessing(true);
        onSignOut()
          .then(onCancel)
          .finally(() => setProcessing(false));
      }}
      okText={<FormattedMessage tagName="span" defaultMessage="Sign out" />}
      okType="primary"
      okDanger
      confirmLoading={processing}
      disabled
    >
      <div className="padding-vertical">
        {businessCode && (
          <Alert
            type="info"
            message={
              <FormattedMessage
                tagName="b"
                defaultMessage="Your are signing out POS from store {storeName}"
                values={{ storeName: <NameDisplay nameKey={businessCode} /> }}
              />
            }
            description={
              shiftEnabled ? (
                <FormattedMessage
                  tagName="span"
                  defaultMessage="<b>This is not for clock out</b>, to proceed clock out, please click on your name at the top left of the shop home page"
                  values={{ b: (t) => <b style={{ color: "red" }}>{t}</b> }}
                />
              ) : null
            }
          />
        )}
      </div>
    </Dialog>
  );
}
