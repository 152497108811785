"use client";

import {
  useAccessReady,
  useClientInstallId,
  useClientVersion,
  useLicenseClientAccessVersion,
  useUser,
  useUserRealmId,
  useUserUID,
} from "@easybiz/component-shared";
import { useEffect } from "react";
import useServerActionRegisterDevice from "./useServerActionRegisterDevice";

// Init device register (Grant permissions)
function DeviceRegister({ getDeviceInfo, signInWithCustomToken, getAuth }) {
  const user = useUser();
  const accessVersion = user?.accessVersion;
  const uid = useUserUID();
  const realmId = useUserRealmId();
  const installId = useClientInstallId();
  const clientVersion = useClientVersion();
  const registeredClientVersion = user?.clientVersion;
  const latestAccessVersion = useLicenseClientAccessVersion();
  const accessReady = useAccessReady();
  const { onSubmit } = useServerActionRegisterDevice(signInWithCustomToken, getAuth, getDeviceInfo);
  const pendingRegister =
    !accessReady || latestAccessVersion > accessVersion || clientVersion !== registeredClientVersion;

  useEffect(() => {
    if (
      installId &&
      uid &&
      realmId &&
      pendingRegister // Submit initial device registration or permission updated after auth
    ) {
      onSubmit();
    }
  }, [uid, realmId, installId, pendingRegister]);

  return null;
}

export default DeviceRegister;
