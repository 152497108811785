import { WifiOutlined } from "@ant-design/icons";
import { useClientHasNetwork } from "@easybiz/component-shared";
import { Title } from "@easybiz/web-admin";
import { useNetworkStatus } from "@easybiz/component-web-business";
import { Badge, Button, List, Popover } from "antd";
import React, { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
export default () => {
  const networkStatus = useNetworkStatus();
  const online = useClientHasNetwork();
  const { effectiveType, rtt, downlink } = networkStatus || {};
  const [open, setVisible] = useState(false);
  const intl = useIntl();

  const { color, type, status } = useMemo(() => {
    if (online) {
      if (rtt < 100) {
        return {
          color: "green",
          type: "success",
          status: <FormattedMessage defaultMessage={"Good"} />,
        };
      } else if (rtt < 500) {
        return {
          color: "orange",
          type: "warning",
          status: <FormattedMessage defaultMessage={"Your network is delayed"} />,
        };
      } else {
        return {
          color: "red",
          type: "danger",
          status: <FormattedMessage defaultMessage={"Your network is delayed"} />,
        };
      }
    } else {
      return { color: "gray" };
    }
  }, [online, rtt]);

  return (
    <Popover
      open={open}
      onOpenChange={setVisible}
      trigger={"click"}
      title={
        <Title>
          <FormattedMessage defaultMessage={"Network Information"} />
        </Title>
      }
      content={
        <div>
          <List
            dataSource={[
              {
                title: <FormattedMessage defaultMessage={"Type: {type}"} values={{ type: effectiveType }} />,
              },
              {
                title: (
                  <FormattedMessage
                    defaultMessage={"Status: {status}"}
                    values={{
                      status: (
                        <Title type={online ? "success" : "secondary"}>
                          {online ? (
                            <FormattedMessage defaultMessage={"Online"} />
                          ) : (
                            <FormattedMessage defaultMessage={"Offline"} />
                          )}
                        </Title>
                      ),
                    }}
                  />
                ),
              },
              {
                title: (
                  <span>
                    <FormattedMessage defaultMessage={"Bandwidth"} />:{" "}
                    <Title type={type}>
                      <FormattedMessage defaultMessage={"{count} Mb/second"} values={{ count: downlink }} />
                    </Title>
                  </span>
                ),
              },
              {
                title: (
                  <span>
                    <FormattedMessage defaultMessage={"Latency"} />:{" "}
                    <Title type={type}>
                      {rtt} ({status})
                    </Title>
                  </span>
                ),
              },
            ]}
            renderItem={({ title }) => {
              return (
                <List.Item>
                  <List.Item.Meta title={<Title>{title}</Title>} />
                </List.Item>
              );
            }}
          />
        </div>
      }
      overlayStyle={{ width: 300 }}
    >
      <Badge count={online ? null : intl.formatMessage({ defaultMessage: "No Connection" })} offset={[-15, 0]}>
        <Button
          size="large"
          icon={<WifiOutlined style={{ color, fontSize: 22 }} />}
          type="text"
          onClick={() => setVisible(true)}
        />
      </Badge>
    </Popover>
  );
};
