import { NameDisplay } from "@easybiz/component";
import {
  useCashDrawerIsPendingStart,
  useCheckoutEditOrder,
  useClientState,
  useClientStateSetter,
  useUser,
  useUserBusinessCode,
} from "@easybiz/component-shared";
import {
  ACTION_STORE_OUT,
  DISPLAY_MODE_CASH_DRAWER,
  DISPLAY_MODE_CHECK_IN,
  DISPLAY_MODE_CHECK_OUT,
  DISPLAY_MODE_COLLECTION,
  DISPLAY_MODE_PRODUCTION,
  DISPLAY_MODE_REDO,
  DISPLAY_MODE_SALES,
} from "@easybiz/utils";
import { ProductionCalendar, QuickSwitchCalendar, Title } from "@easybiz/web-admin";
import { Badge, Tabs } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import CashDrawer from "./CashDrawer";
import CollectionMonitorTag from "./CollectionMonitorTag";
import DailyOrderStateTag from "./DailyOrderStateTag";
import DailySalesTag from "./DailySalesTag";
import EditOrderPanel from "./EditOrderPanel";
import MainActionTabs from "./MainActionTabs";
import POSOrderDetailPanel from "./POSOrderDetailPanel";
import RedoOrderOverviewPanel from "./RedoOrderOverviewPanel";

export default function POSHomeRightPanel() {
  const editOrder = useCheckoutEditOrder();
  const pendingStartDrawer = useCashDrawerIsPendingStart();
  const user = useUser();
  const businessCode = useUserBusinessCode();
  const { displayMode } = useClientState();
  const setState = useClientStateSetter();

  useEffect(() => {
    if (pendingStartDrawer && user?.checkInStaff && displayMode?.key !== DISPLAY_MODE_CASH_DRAWER) {
      setState({ displayMode: { key: DISPLAY_MODE_CASH_DRAWER } });
    }
  }, [pendingStartDrawer, user]);

  switch (displayMode?.key) {
    case DISPLAY_MODE_SALES:
      return (
        <Tabs
          activeKey={displayMode?.orderId || "calendar"}
          items={[
            {
              key: "calendar",
              children: (
                <div className="flex flex-fill self-scroll">
                  <QuickSwitchCalendar
                    value={displayMode.date}
                    onSelect={(date) => setState({ displayMode: { ...displayMode, date } })}
                    dateCellRender={(selectDate) => {
                      if (selectDate && selectDate.format("YYYY-MM-DD") <= dayjs().format("YYYY-MM-DD")) {
                        return <DailySalesTag businessCode={businessCode} date={selectDate.format("YYYY-MM-DD")} />;
                      }
                    }}
                  />
                </div>
              ),
            },
            ...(displayMode?.orderId
              ? [
                  {
                    key: displayMode.orderId,
                    children: (
                      <POSOrderDetailPanel
                        orderId={displayMode.orderId}
                        onClose={() => setState({ displayMode: { ...displayMode, orderId: null } })}
                      />
                    ),
                  },
                ]
              : []),
          ]}
          renderTabBar={() => null}
        />
      );
    case DISPLAY_MODE_CASH_DRAWER:
      return <CashDrawer onClose={() => setState({ displayMode: null })} />;
    case DISPLAY_MODE_COLLECTION:
    case DISPLAY_MODE_CHECK_IN:
    case DISPLAY_MODE_CHECK_OUT:
      return (
        <QuickSwitchCalendar
          value={displayMode.date}
          onSelect={(date) => setState({ displayMode: { ...displayMode, date } })}
          dateCellRender={(selectDate, sameMonth) => {
            if (selectDate && sameMonth) {
              return (
                <Badge
                  count={
                    displayMode.key !== DISPLAY_MODE_COLLECTION
                      ? displayMode.selects?.filter((select) => select.date === selectDate.format("YYYY-MM-DD")).length
                      : null
                  }
                  offset={[-25, 25]}
                  size="small"
                >
                  {displayMode.key === DISPLAY_MODE_CHECK_OUT ? (
                    <DailyOrderStateTag
                      businessCode={businessCode}
                      date={selectDate.format("YYYY-MM-DD")}
                      awaitAction={ACTION_STORE_OUT}
                    />
                  ) : (
                    <CollectionMonitorTag
                      date={selectDate.format("YYYY-MM-DD")}
                      readyForCollection={displayMode.key === DISPLAY_MODE_CHECK_IN}
                    />
                  )}
                </Badge>
              );
            }
          }}
        />
      );
    case DISPLAY_MODE_PRODUCTION:
      return (
        <ProductionCalendar
          title={
            <Title type={"success"}>
              <NameDisplay nameKey={businessCode} />
            </Title>
          }
          supplierId={displayMode.supplierId}
          value={displayMode.date}
          onSelect={(date) => setState({ displayMode: { ...displayMode, date } })}
        />
      );
    case DISPLAY_MODE_REDO:
      return (
        <RedoOrderOverviewPanel displayMode={displayMode} setDisplayMode={(displayMode) => setState({ displayMode })} />
      );
    default:
      if (editOrder) {
        return <EditOrderPanel />;
      } else {
        return <MainActionTabs displayMode={displayMode} setDisplayMode={(displayMode) => setState({ displayMode })} />;
      }
  }
}
