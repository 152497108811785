import { CUSTOMER_INTENT_PAYMENT } from "./constants/customer.js";
import { OPERATION_GROUP_ORDER } from "./constants/index.js";
import { ORDER_ACTION_PAYMENT } from "./constants/order.js";
import { STRIPE_PAYMENT_METHOD_TYPE_PAYNOW } from "./constants/payment.js";
import getTimestamp from "./getTimestamp.js";
import toDisplayOrderNo from "./toDisplayOrderNo.js";

export default async function generatePayNowUrl(realm, orderId, stripeAccountId, stripe, getFirestore) {
  const idempotencyKey = getFirestore().collection("idempotencyKeys").doc().id;

  return getFirestore().runTransaction(async (transaction) => {
    const orderDoc = await transaction.get(getFirestore().doc(`realms/${realm.realmId}/orders/${orderId}`));
    const totalBalance = orderDoc.get("totalBalance");

    if (typeof totalBalance !== "number" || totalBalance <= 0) {
      // Ignore
      return null;
    }

    const amount = parseInt(totalBalance * 100);

    if (orderDoc.get("paynowPaymentId")) {
      let paymentIntent = await stripe.paymentIntents.retrieve(orderDoc.get("paynowPaymentId"), {
        stripeAccount: stripeAccountId,
      });

      if (paymentIntent.status === "requires_action") {
        if (amount !== paymentIntent.amount) {
          paymentIntent = await stripe.paymentIntents.update(
            orderDoc.get("paynowPaymentId"),
            {
              amount,
            },
            {
              stripeAccount: stripeAccountId,
            }
          );
        }

        return paymentIntent.next_action.paynow_display_qr_code.image_url_png;
      }
    }

    const paymentIntent = await stripe.paymentIntents.create(
      {
        payment_method_types: [STRIPE_PAYMENT_METHOD_TYPE_PAYNOW],
        payment_method_data: {
          type: STRIPE_PAYMENT_METHOD_TYPE_PAYNOW,
        },
        amount: parseInt(totalBalance * 100),
        currency: orderDoc.get("currency"),
        confirm: true,
        error_on_requires_action: false,
        statement_descriptor: `Order ${toDisplayOrderNo(
          orderDoc.get("orderNo"),
          orderDoc.get("business.id"),
          realm
        )}`.slice(0, 22), // Maximum length
        metadata: {
          realm_id: realm.realmId,
          customer_id: orderDoc.get("customer.id"),
          order_id: orderDoc.id,
        },
      },
      {
        stripeAccount: stripeAccountId,
        idempotencyKey,
      }
    );

    transaction.update(orderDoc.ref, {
      paynowPaymentId: paymentIntent.id,
    });

    transaction.set(getFirestore().doc(`realms/${realm.realmId}/customer_intents/${paymentIntent.id}`), {
      group: OPERATION_GROUP_ORDER,
      type: ORDER_ACTION_PAYMENT,
      data: {
        orderId: orderDoc.id,
      },
      authToken: {
        realmId: realm.realmId,
        isSystemAccess: true,
      },
      paymentIntent,
      intentType: CUSTOMER_INTENT_PAYMENT,
      timestamp: getTimestamp(),
    });

    return paymentIntent.next_action.paynow_display_qr_code.image_url_png;
  });
}
