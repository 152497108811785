"use client";

import { useKeyboardDialPadListenerRef, useKeyboardReturnListenerRef } from "@easybiz/component-shared";
import { useEffect } from "react";

export default function WebKeyboardListener() {
  const dialPadListenerRef = useKeyboardDialPadListenerRef();
  const returnListenerRef = useKeyboardReturnListenerRef();

  useEffect(() => {
    const onInputListener = ({ key }) => {
      if (!key) return;

      // Invoke latest listener
      const dialPadListener = Array.isArray(dialPadListenerRef.current)
        ? dialPadListenerRef.current[dialPadListenerRef.current.length - 1]
        : null;
      const returnListener = Array.isArray(returnListenerRef.current)
        ? returnListenerRef.current[returnListenerRef.current.length - 1]
        : null;

      if (key === "Enter") {
        if (returnListener) {
          returnListener();
        }
      } else if (dialPadListener) {
        if ((key.length === 1 && !isNaN(key)) || key === "Backspace" || key === ".") {
          dialPadListener(key);
        }
      }
    };

    window.addEventListener("keydown", onInputListener);
    return () => window.removeEventListener("keydown", onInputListener);
  }, []);
}
