import { usePrinter } from "@easybiz/hook";
import {
  useClientRegisterDevice,
  useClientType,
  useKVStorageAutoCusReceipt,
  useKVStorageAutoInternalReceipt,
  useKVStorageAutoLabel,
  useRealmContext,
  useRealmServices,
  useUserRealmId,
} from "@easybiz/component-shared";
import {
  asyncPrepareTemplateData,
  CLIENT_POS,
  formatTemplateRenderParams,
  formatTSPLCommands,
  PAYMENT_ID_CASH,
  PLACEHOLDER_ORDER_LINE_ITEMS,
  TEMPLATE_CATEGORY_LABEL,
} from "@easybiz/utils";
import * as firestore from "firebase/firestore";
import { useIntl } from "react-intl";
import useCreate from "../useOrderCreate";

const { doc, getDoc, getFirestore } = firestore;

function useOrderAdminCreate(callback, createImageData) {
  const realmId = useUserRealmId();
  const context = useRealmContext();
  const printer = usePrinter();
  const client = useClientType();
  const services = useRealmServices();
  const registerDevice = useClientRegisterDevice();
  const intl = useIntl();
  const { receiptPrinter, labelPrinter } = registerDevice?.data() || {};
  const autoCusReceipt = useKVStorageAutoCusReceipt();
  const autoInternalReceipt = useKVStorageAutoInternalReceipt();
  const autoTagging = useKVStorageAutoLabel();

  return useCreate(async (success, response, request) => {
    callback(success, response, request);

    // Auto printing
    if (success) {
      const autoReceipt = Number.isInteger(autoCusReceipt) && autoCusReceipt > 0;
      const autoInternal = Number.isInteger(autoInternalReceipt) && autoInternalReceipt > 0;

      if (receiptPrinter && (autoReceipt || autoInternal)) {
        const { receipt } = response;
        // Auto printing
        if (autoReceipt && client === CLIENT_POS && request.checkoutParams?.payment?.id === PAYMENT_ID_CASH) {
          await printer.openDrawer(true);
        }

        let customerLines, internalReceipts;

        if (Array.isArray(receipt.mutiLines)) {
          customerLines = receipt.mutiLines[0]?.lines;
          internalReceipts = receipt.mutiLines.slice(1);
        } else {
          customerLines = receipt.lines;
        }

        if (Array.isArray(customerLines) && autoReceipt) {
          for (let index = 0; index < autoCusReceipt; index++) {
            await printer.print({ lines: customerLines });
          }
        }

        if (Array.isArray(internalReceipts) && autoInternal) {
          for (const { lines } of internalReceipts) {
            if (Array.isArray(lines)) {
              for (let index = 0; index < autoInternalReceipt; index++) {
                await printer.print({ lines });
              }
            }
          }
        }
      }

      if (labelPrinter && autoTagging && response.newOrder) {
        const service = services?.find((service) => service.id === response.newOrder.service.id);

        if (service?.itemLabelId) {
          getDoc(
            doc(
              getFirestore(),
              `realms/${realmId}/templates/${TEMPLATE_CATEGORY_LABEL}/published/${service.itemLabelId}`
            )
          ).then((templateDoc) => {
            const template = templateDoc.get("data");

            if (template) {
              return asyncPrepareTemplateData(
                template,
                {
                  order: response.newOrder,
                },
                context,
                firestore
              ).then((data) => {
                const renderParams = formatTemplateRenderParams(
                  data,
                  { ...template.content, category: TEMPLATE_CATEGORY_LABEL },
                  context
                );
                const encodeLabels = (renderParams?.[PLACEHOLDER_ORDER_LINE_ITEMS] || []).map((item) => {
                  const [command] = formatTSPLCommands(
                    template,
                    { ...renderParams, ...item },
                    null,
                    intl,
                    createImageData
                  );

                  return command;
                });

                printer.print({ encodeLabels });
              });
            }
          });
        } else {
          printer.print({ labelOrder: response.newOrder });
        }
      }
    }
  });
}

export default useOrderAdminCreate;
