"use client";

import { useKeyboardAddReturnListener, useKeyboardRemoveReturnListener } from "@easybiz/component-shared";
import { useEffect } from "react";

function ReturnListener({ disabled, callback }) {
  const addEventListener = useKeyboardAddReturnListener();
  const removeEventListener = useKeyboardRemoveReturnListener();

  useEffect(() => {
    if (!disabled) {
      addEventListener(callback);
      return () => removeEventListener(callback);
    }
  }, [disabled, callback]);

  return null;
}

export default ReturnListener;
