import { DateDisplay } from "@easybiz/component";
import { useBusinessDateCollectionDoc } from "@easybiz/web-firebase";
import React from "react";
import { FormattedMessage } from "react-intl";
import DeliveryMontiorPanel from "./DeliveryMontiorPanel";
import PanelHeader from "./PanelHeader";
import { useUserBusinessCode } from "@easybiz/component-shared";

export default ({ date, onClose }) => {
  const businessCode = useUserBusinessCode();
  const statusDoc = useBusinessDateCollectionDoc(businessCode, date?.format("YYYY-MM-DD"));

  return (
    <>
      <PanelHeader
        title={
          <span>
            <FormattedMessage defaultMessage={"Collection Monitor"} />
            {` `}(<DateDisplay date={date?.format("YYYY-MM-DD")} />)
          </span>
        }
        onClose={onClose}
      />
      <DeliveryMontiorPanel businessCode={businessCode} statusDoc={statusDoc} />
    </>
  );
};
