"use client";

import { useUserRealmId } from "@easybiz/component-shared";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

export default (supplierId, date) => {
  const realmId = useUserRealmId();
  const [stateDoc, setStateDoc] = useState(null);

  useEffect(() => {
    setStateDoc(null);
    if (realmId && date && supplierId) {
      return onSnapshot(
        doc(getFirestore(), `realms/${realmId}/statuses/agent_${supplierId}/keys/${date}`),
        setStateDoc
      );
    }
  }, [realmId, date, supplierId]);

  return stateDoc;
};
