"use client";

import { useUserRealmId } from "@easybiz/component-shared";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

export default (orderId) => {
  const realmId = useUserRealmId();
  const [receipt, setReceipt] = useState(null);

  useEffect(() => {
    setReceipt(null);
    if (realmId && orderId) {
      return onSnapshot(doc(getFirestore(), `realms/${realmId}/receipts/${orderId}`), setReceipt);
    }
  }, [realmId, orderId]);

  return receipt;
};
