"use client";

import { useUserRealmId } from "@easybiz/component-shared";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

export default (businessCode, date) => {
  const realmId = useUserRealmId();
  const [statusDoc, setStatusDoc] = useState();

  useEffect(() => {
    setStatusDoc(null);
    if (realmId && date && businessCode) {
      return onSnapshot(
        doc(getFirestore(), `realms/${realmId}/statuses/collect_${businessCode}/keys/${date}`),
        setStatusDoc
      );
    }
  }, [realmId, date, businessCode]);

  return statusDoc;
};
