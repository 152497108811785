import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Space } from "antd";
import React from "react";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import IconButton from "../IconButton";
import { useUserDialCode } from "@easybiz/component-shared";

export default function ({ tels, disabled, onChange }) {
  const defaultDialCode = useUserDialCode();
  const datas = Array.isArray(tels) && tels.length > 0 ? tels : [{ dialCode: defaultDialCode }];
  const canDelete = datas.length > 1;

  return (
    <Space className="full-width" direction="vertical">
      {datas.map(({ number, dialCode }, index) => {
        const isLast = index === datas.length - 1;

        return (
          <div key={`${dialCode}${index}`} className="flex flex-row">
            <div className="flex-fill">
              <PhoneInput
                disabled={disabled}
                inputStyle={{ width: "100%", height: 40 }}
                preferredCountries={[defaultDialCode]}
                country={dialCode === "1" ? "us" : dialCode}
                countryCodeEditable={false}
                enableSearch
                value={`${dialCode || ""}${number || ""}`}
                onChange={(value, { dialCode }) => {
                  onChange([
                    ...datas.slice(0, index),
                    { number: value.slice(dialCode.length), dialCode },
                    ...datas.slice(index + 1),
                  ]);
                }}
              />
            </div>
            {canDelete && (
              <div className="padding-left-half">
                <IconButton
                  icon={<DeleteOutlined />}
                  disabled={disabled}
                  onClick={() => onChange([...datas.slice(0, index), ...datas.slice(index + 1)])}
                />
              </div>
            )}
            {isLast && index < 1 && (
              <div className="padding-left-half">
                <IconButton
                  icon={<PlusOutlined />}
                  disabled={disabled}
                  onClick={() => onChange([...datas, { dialCode: defaultDialCode }])}
                />
              </div>
            )}
          </div>
        );
      })}
    </Space>
  );
}
