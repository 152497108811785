import { ROUNDING_METHOD_HALF_DOWN, ROUNDING_METHOD_HALF_TO_EVEN, ROUNDING_METHOD_HALF_UP } from "./constants.js";
import safeFloating from "./safeFloating.js";

export default function precisionRounding(amount, rounding, returnRoundingAmount = false) {
  const { precision = 2, method = ROUNDING_METHOD_HALF_UP } = rounding || {};

  // ----------------------
  // DECIMAL ROUNDING
  // ----------------------
  //
  // We'll multiply the amount by 10^precision, store it in `scaled`.
  // Then we look at `floorScaled` (the integer part) and `fraction`
  // (the decimal part after removing the integer).
  //
  // Based on `method`, we decide whether to round up, down, or to even.

  const factor = Math.pow(10, precision);
  const scaled = amount * factor;

  // integer part below scaled
  const floorScaled = Math.floor(scaled);
  // fractional part = scaled - floorScaled
  const fraction = scaled - floorScaled;

  let decimalRoundedAmount;

  switch (method) {
    case ROUNDING_METHOD_HALF_DOWN: {
      // If fraction > 0.5 => round up
      // If fraction < 0.5 => round down
      // If fraction == 0.5 => round DOWN (this is the key difference from half-up)
      if (fraction > 0.5) {
        decimalRoundedAmount = (floorScaled + 1) / factor;
      } else if (fraction < 0.5) {
        decimalRoundedAmount = floorScaled / factor;
      } else {
        // exactly .5 => round down
        decimalRoundedAmount = floorScaled / factor;
      }
      break;
    }

    case ROUNDING_METHOD_HALF_TO_EVEN: {
      // Banker's rounding:
      // If fraction > 0.5 => round up
      // If fraction < 0.5 => round down
      // If fraction == 0.5 => round to the nearest EVEN integer
      if (fraction > 0.5) {
        decimalRoundedAmount = (floorScaled + 1) / factor;
      } else if (fraction < 0.5) {
        decimalRoundedAmount = floorScaled / factor;
      } else {
        // fraction == 0.5
        // Check whether floorScaled is even or odd
        if (floorScaled % 2 === 0) {
          // Already even => stay
          decimalRoundedAmount = floorScaled / factor;
        } else {
          // Odd => round up by 1
          decimalRoundedAmount = (floorScaled + 1) / factor;
        }
      }
      break;
    }

    case ROUNDING_METHOD_HALF_UP:
    default: {
      // Default half-up:
      // If fraction > 0.5 => round up
      // If fraction < 0.5 => round down
      // If fraction == 0.5 => round UP
      if (fraction > 0.5) {
        decimalRoundedAmount = (floorScaled + 1) / factor;
      } else if (fraction < 0.5) {
        decimalRoundedAmount = floorScaled / factor;
      } else {
        // exactly .5 => round up
        decimalRoundedAmount = (floorScaled + 1) / factor;
      }
      break;
    }
  }

  // ----------------------
  // STEP 3: CALCULATE THE ROUNDING AMOUNT
  // ----------------------
  //
  // The difference between final result and original
  // (use toBeCloseTo in tests to handle tiny floating differences)
  const roundedAmountNumeric = parseFloat(decimalRoundedAmount.toFixed(precision));
  const roundingAmountNumeric = safeFloating(roundedAmountNumeric - amount);

  return returnRoundingAmount
    ? {
        roundedAmount: roundedAmountNumeric,
        roundingAmount: roundingAmountNumeric,
      }
    : roundedAmountNumeric;
}
