import { useRealmServices, useUserRealmId } from "@easybiz/component-shared";
import { formatMediaUrl } from "@easybiz/lib-shared-utils";
import { Avatar } from "antd";
import React from "react";

export default function ({ serviceId, serviceName }) {
  const realmId = useUserRealmId();
  const services = useRealmServices();
  const found = services?.find((service) => service.id === serviceId);

  return (
    <Avatar shape="square" src={found?.photoId ? formatMediaUrl(found?.photoId, realmId) : null}>
      {`${found?.title || serviceName || ""}`.slice(0, 2)}
    </Avatar>
  );
}
