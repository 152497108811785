export const HTTP_ERROR_INTERNAL = "internal";
export const HTTP_ERROR_INVALID_ARGUMENT = "invalid-argument";
export const HTTP_ERROR_PERMISSION_DENIED = "permission-denied";
export const HTTP_ERROR_NOT_FOUND = "not-found";
export const HTTP_ERROR_ALREADY_EXISTS = "already-exists";
export const HTTP_ERROR_DEADLINE_EXCEEDED = "deadline-exceeded";
export const HTTP_ERROR_UNAVAILABLE = "unavailable";
export const HTTP_ERROR_RESOURCE_EXHAUSTED = "resource-exhausted";

export const REALM_RESOURCES_PREFIX_ROLE = "rl";
export const REALM_CONFIG_ROLES = 'roles';
export const REALM_CONFIG_POS = 'pos';

export const STATUSES_KEY_CHAT_UNREAD = "chat_unread";
export const STATUSES_KEY_CONVERSATION_COUNTS = "conversation_counts";

export const SYSTEM_CLIENT_POS = "pos";
export const SYSTEM_CLIENT_MOBILE = "mobile";
export const SYSTEM_CLIENT_CONSOLE = "console";
export const SYSTEM_CLIENT_CUSTOMER_APP = "customer_app";
export const SYSTEM_CLIENT_CUSTOMER_WEBSITE = "customer_website";
export const SYSTEM_CLIENT_PLATFORM_DASHBOARD = "dashboard";
export const SYSTEM_CLIENT_WHATSAPP = "whatsapp";
export const SYSTEM_CLIENT_EMAIL = "email";

export const STATUSES_KEY_STAFF_HOURS = "staff_hours";

export const PLATFORM_WEB = "web";
export const PLATFORM_IOS = "ios";
export const PLATFORM_ANDROID = "android";

export const PLATFORM_TEMPLATE_REALM_ID = "template";

export const SALES_CHANNEL_POS = "pos";
export const SALES_CHANNEL_ONLINE = "online";
export const SALES_CHANNEL_B2B = "b2b";