import { DateDisplay } from "@easybiz/component";
import { useUser } from "@easybiz/component-shared";
import { Title } from "@easybiz/web-admin";
import { Descriptions, Modal, Space } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import CashDrawerEndAlert from "./CashDrawerEndAlert";
import StaffCheckoutButton from "./StaffCheckoutButton";

export default ({ open, onCancel }) => {
  const user = useUser();

  return (
    <Modal title={user?.checkInStaff?.name} open={open} onCancel={onCancel} footer={false} destroyOnClose>
      <Space direction="vertical" className="full-width" size={"middle"}>
        <CashDrawerEndAlert />
        <Descriptions bordered column={1}>
          <Descriptions.Item label={<FormattedMessage defaultMessage={"Clock in"} />}>
            <Title>
              <DateDisplay timestamp={user?.checkInStaff?.clockInTime} />
            </Title>
          </Descriptions.Item>
          <Descriptions.Item label={<FormattedMessage defaultMessage={"Clock out"} />}>
            <Space direction="vertical" className="full-width">
              {user?.checkInStaff && <StaffCheckoutButton onCancel={onCancel} />}
            </Space>
          </Descriptions.Item>
        </Descriptions>
      </Space>
    </Modal>
  );
};
