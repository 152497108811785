import { CameraOutlined, UploadOutlined } from "@ant-design/icons";
import { useClientRegisterDevice, useUser, useUserName, useUserRealmId } from "@easybiz/component-shared";
import { getStorageUpload, STORAGE_FOLDER_ORDER } from "@easybiz/lib-shared-cms";
import { Alert, Button, Col, message, Row, Switch, Upload } from "antd";
import dayjs from "dayjs";
import { arrayUnion, collection, doc, getFirestore, updateDoc } from "firebase/firestore";
import { lazy, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import AdminSuspense from "../AdminSuspense";
import OrderPhotoGallery from "../OrderPhotoGallery";
import uploadImageWithThumb from "./uploadImageWithThumb";

const Webcamera = lazy(() => import("../Webcamera"));

export default ({ order, videoWidth, sortIndex, permissions }) => {
  const user = useUser();
  const realmId = useUserRealmId();
  const userName = useUserName();
  const deviceDoc = useClientRegisterDevice();
  const [loading, setLoading] = useState(false);
  const cameraRef = useRef();

  const onCapture = async (content) => {
    try {
      setLoading(true);

      const { bucket, path, resourceId } = getStorageUpload(
        STORAGE_FOLDER_ORDER,
        { doc, collection, getFirestore },
        user
      );

      await uploadImageWithThumb(bucket, path, content, realmId, resourceId);

      await updateDoc(order.ref, {
        photos: arrayUnion({
          i: resourceId,
          o: userName || null,
          t: dayjs().unix(),
          ...(typeof sortIndex === "number" && { l: sortIndex }),
        }),
      });
    } catch (err) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const onTakephoto = () => {
    cameraRef.current && onCapture(cameraRef.current.getScreenshot());
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Alert
            type={deviceDoc?.get("webCamera") ? "success" : "info"}
            icon={<CameraOutlined />}
            showIcon
            message={
              deviceDoc?.get("webCamera") ? (
                <FormattedMessage
                  defaultMessage={"Web camera is enabled, <b>touch/click on camera below to capture image</b>"}
                  values={{ b: (t) => <b>{t}</b> }}
                />
              ) : (
                <FormattedMessage
                  defaultMessage={
                    "Enable web camera for photo capture <b>You will be ask for camera permission by enabling</b>"
                  }
                  values={{ b: (t) => <b>{t}</b> }}
                />
              )
            }
            action={
              <Switch
                checkedChildren={<FormattedMessage defaultMessage={"Enabled"} />}
                unCheckedChildren={<FormattedMessage defaultMessage={"Disabled"} />}
                checked={deviceDoc?.get("webCamera")}
                onChange={(checked) => {
                  if (deviceDoc?.exists()) {
                    updateDoc(deviceDoc.ref, { webCamera: checked });
                  }
                }}
              />
            }
          />
        </Col>
        <Col span={24}>
          {deviceDoc?.get("webCamera") && (
            <AdminSuspense>
              <Webcamera camRef={cameraRef} videoWidth={videoWidth} onTakephoto={onTakephoto} />
            </AdminSuspense>
          )}
        </Col>
        <Col span={24}>
          <OrderPhotoGallery
            extra={
              <Upload
                disabled={!order || !permissions?.canNote}
                accept="image/*"
                fileList={[]}
                multiple
                action={onCapture}
                style={{ width: "100%" }}
              >
                <Button icon={<UploadOutlined />} loading={loading} block disabled={!order || !permissions?.canNote}>
                  <FormattedMessage tagName="span" defaultMessage="Upload" />
                </Button>
              </Upload>
            }
            order={order}
            itemIndex={sortIndex}
            monitoring
            wrap
          />
        </Col>
      </Row>
    </>
  );
};
