"use client";

import { useRealmToDisplayName } from "@easybiz/component-shared";
import {
  PAYMENT_ID_ALIPAY,
  PAYMENT_ID_BANK_DEPOSIT,
  PAYMENT_ID_CASH,
  PAYMENT_ID_CREDIT,
  PAYMENT_ID_GRABPAY,
  PAYMENT_ID_PAYNOW,
  PAYMENT_ID_PAYNOW_STRIPE,
  PAYMENT_ID_POINTS,
  PAYMENT_ID_STRIPE,
  PAYMENT_ID_WECHATPAY,
  STRIPE_PAYMENT_METHOD_TYPE_ACSS_DEBIT,
  STRIPE_PAYMENT_METHOD_TYPE_AFFIRM,
  STRIPE_PAYMENT_METHOD_TYPE_AFTERPAY_CLEARPAY,
  STRIPE_PAYMENT_METHOD_TYPE_ALIPAY,
  STRIPE_PAYMENT_METHOD_TYPE_AU_BECS_DEBIT,
  STRIPE_PAYMENT_METHOD_TYPE_BACS_DEBIT,
  STRIPE_PAYMENT_METHOD_TYPE_BANCONTACT,
  STRIPE_PAYMENT_METHOD_TYPE_BLIK,
  STRIPE_PAYMENT_METHOD_TYPE_BOLETO,
  STRIPE_PAYMENT_METHOD_TYPE_CARD,
  STRIPE_PAYMENT_METHOD_TYPE_CARD_PRESENT,
  STRIPE_PAYMENT_METHOD_TYPE_CARTES_BANCAIRES,
  STRIPE_PAYMENT_METHOD_TYPE_CASHAPP,
  STRIPE_PAYMENT_METHOD_TYPE_EPS,
  STRIPE_PAYMENT_METHOD_TYPE_FPX,
  STRIPE_PAYMENT_METHOD_TYPE_GIROPAY,
  STRIPE_PAYMENT_METHOD_TYPE_GRABPAY,
  STRIPE_PAYMENT_METHOD_TYPE_IDEAL,
  STRIPE_PAYMENT_METHOD_TYPE_INTERAC_PRESENT,
  STRIPE_PAYMENT_METHOD_TYPE_KLARNA,
  STRIPE_PAYMENT_METHOD_TYPE_KONBINI,
  STRIPE_PAYMENT_METHOD_TYPE_LINK,
  STRIPE_PAYMENT_METHOD_TYPE_OXXO,
  STRIPE_PAYMENT_METHOD_TYPE_P24,
  STRIPE_PAYMENT_METHOD_TYPE_PAYNOW,
  STRIPE_PAYMENT_METHOD_TYPE_PIX,
  STRIPE_PAYMENT_METHOD_TYPE_PROMTPAY,
  STRIPE_PAYMENT_METHOD_TYPE_SEPA_DEBIT,
  STRIPE_PAYMENT_METHOD_TYPE_SOFORT,
  STRIPE_PAYMENT_METHOD_TYPE_US_BANK_ACCOUNT,
  STRIPE_PAYMENT_METHOD_TYPE_WECHAT_PAY,
} from "@easybiz/utils";
import { useIntl } from "react-intl";

function usePaymentMethodDisplay(option, title) {
  const intl = useIntl();
  const toDisplayName = useRealmToDisplayName();

  switch (option) {
    case STRIPE_PAYMENT_METHOD_TYPE_ACSS_DEBIT:
      return `Pre-authorized debit`;
    case STRIPE_PAYMENT_METHOD_TYPE_AFFIRM:
      return `Affirm`;
    case STRIPE_PAYMENT_METHOD_TYPE_AFTERPAY_CLEARPAY:
      return `Afterpay/Clearpay`;
    case STRIPE_PAYMENT_METHOD_TYPE_ALIPAY:
      return `Alipay`;
    case STRIPE_PAYMENT_METHOD_TYPE_AU_BECS_DEBIT:
    case STRIPE_PAYMENT_METHOD_TYPE_BACS_DEBIT:
      return `Bacs Direct Debit`;
    case STRIPE_PAYMENT_METHOD_TYPE_BANCONTACT:
      return `Bancontact`;
    case STRIPE_PAYMENT_METHOD_TYPE_BLIK:
      return `BLIK`;
    case STRIPE_PAYMENT_METHOD_TYPE_BOLETO:
      return `Boleto`;
    case STRIPE_PAYMENT_METHOD_TYPE_CARD:
      return `Credit/Debit Card`;
    case STRIPE_PAYMENT_METHOD_TYPE_CARD_PRESENT:
      return `Card Present`;
    case STRIPE_PAYMENT_METHOD_TYPE_CASHAPP:
      return `Cash App`;
    case STRIPE_PAYMENT_METHOD_TYPE_CARTES_BANCAIRES:
      return `Cartes Bancaires`;
    case STRIPE_PAYMENT_METHOD_TYPE_EPS:
      return `EPS`;
    case STRIPE_PAYMENT_METHOD_TYPE_FPX:
      return `FPX`;
    case STRIPE_PAYMENT_METHOD_TYPE_GIROPAY:
      return `Giropay`;
    case STRIPE_PAYMENT_METHOD_TYPE_GRABPAY:
      return `GrabPay`;
    case STRIPE_PAYMENT_METHOD_TYPE_IDEAL:
      return `iDEAL`;
    case STRIPE_PAYMENT_METHOD_TYPE_INTERAC_PRESENT:
      return `Interac`;
    case STRIPE_PAYMENT_METHOD_TYPE_KLARNA:
      return `Klarna`;
    case STRIPE_PAYMENT_METHOD_TYPE_KONBINI:
      return `Konbini`;
    case STRIPE_PAYMENT_METHOD_TYPE_LINK:
      return `Link`;
    case STRIPE_PAYMENT_METHOD_TYPE_OXXO:
      return `OXXO`;
    case STRIPE_PAYMENT_METHOD_TYPE_P24:
      return `Przelewy24`;
    case STRIPE_PAYMENT_METHOD_TYPE_PAYNOW:
      return `PayNow`;
    case STRIPE_PAYMENT_METHOD_TYPE_PIX:
      return `Pix`;
    case STRIPE_PAYMENT_METHOD_TYPE_PROMTPAY:
      return `PromptPay`;
    case STRIPE_PAYMENT_METHOD_TYPE_SEPA_DEBIT:
      return `SEPA Direct Debit`;
    case STRIPE_PAYMENT_METHOD_TYPE_SOFORT:
      return `SOFORT`;
    case STRIPE_PAYMENT_METHOD_TYPE_US_BANK_ACCOUNT:
      return `ACH Direct Debit`;
    case STRIPE_PAYMENT_METHOD_TYPE_WECHAT_PAY:
      return `WeChat Pay`;
    case PAYMENT_ID_ALIPAY:
      return intl.formatMessage({
        id: "alipay",
        defaultMessage: "Alipay",
      });
    case PAYMENT_ID_CASH:
      return intl.formatMessage({
        id: "cash",
        defaultMessage: "Cash",
      });
    case PAYMENT_ID_CREDIT:
      return intl.formatMessage({
        id: "advance.credit",
        defaultMessage: "Advance credit",
      });
    case PAYMENT_ID_GRABPAY:
      return intl.formatMessage({
        id: "grabpay",
        defaultMessage: "GrabPay",
      });
    case PAYMENT_ID_PAYNOW:
      return "PAYNOW";
    case PAYMENT_ID_PAYNOW_STRIPE:
      return "PAYNOW (Stripe)";
    case PAYMENT_ID_POINTS:
      return intl.formatMessage({
        id: "points",
        defaultMessage: "Points",
      });
    case PAYMENT_ID_STRIPE:
      return `${intl.formatMessage({
        id: "credit.card",
        defaultMessage: "Credit card",
      })}${title ? ` (${title})` : ""}`;
    case PAYMENT_ID_WECHATPAY:
      return intl.formatMessage({
        id: "wechat.pay",
        defaultMessage: "Wechat Pay",
      });
    case PAYMENT_ID_BANK_DEPOSIT:
      return intl.formatMessage({ id: "bank.deposit", defaultMessage: "Bank deposit" });
    default:
      return toDisplayName(option) || "";
  }
}

export default usePaymentMethodDisplay;
