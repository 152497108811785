import { useClientOfflineEnabled } from "@easybiz/component-shared";
import { Dialog } from "@easybiz/web-admin";
import { Alert } from "antd";
import { FormattedMessage } from "react-intl";

export default function ({ open, onCancel }) {
  const offlineEnabled = useClientOfflineEnabled();

  return (
    <Dialog
      title={
        offlineEnabled ? (
          <FormattedMessage defaultMessage={"Disable Offline Support?"} />
        ) : (
          <FormattedMessage defaultMessage={"Enable Offline Support?"} />
        )
      }
      open={open}
      onCancel={onCancel}
      okText={
        offlineEnabled ? (
          <FormattedMessage defaultMessage={"Disable & Reload App"} />
        ) : (
          <FormattedMessage defaultMessage={"Enable & Reload App"} />
        )
      }
      onOk={() => {
        if (offlineEnabled) {
          window.localStorage.setItem("disableoffline", "true");
        } else {
          window.localStorage.removeItem("disableoffline");
        }

        window.location.reload(true);
      }}
    >
      <Alert
        type="info"
        message={
          offlineEnabled ? (
            <FormattedMessage
              defaultMessage={
                "We recommend keeping offline support enabled, unless your device has low memory or performance issues, as disabling it will result in the system no longer saving data locally and you won't be able to access your data offline."
              }
              values={{ b: (t) => <b>{t}</b> }}
            />
          ) : (
            <FormattedMessage
              defaultMessage={
                "Once enabled, the system will save your most recently accessed data locally on your device, enabling you to access and perform actions like receipt or label printing even when offline."
              }
            />
          )
        }
      />
    </Dialog>
  );
}
