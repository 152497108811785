import { getApp } from "firebase/app";
import { getStorage, ref, uploadBytes, uploadString } from "firebase/storage";

/**
 * Read a File/Blob as a data URL (base64 string).
 */
function readFileAsDataURL(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => resolve(e.target.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

/**
 * Returns the width and height of an image from a data URL.
 */
function measureImage(dataURL) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = reject;
    img.src = dataURL;
  });
}

/**
 * Always produces a WebP data URL (i.e., "image/webp").
 * Preserves aspect ratio, scaling down if above maxWidth or maxHeight.
 * @param {string} dataURL   The original image in base64 format
 * @param {number} maxWidth  Max width
 * @param {number} maxHeight Max height
 * @param {number} quality   Image quality (0-1)
 * @returns {Promise<string>} Resized WebP data URL
 */
function resizeDataURLToWebp(dataURL, maxWidth, maxHeight, quality = 0.7) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
      const newWidth = Math.floor(img.width * ratio);
      const newHeight = Math.floor(img.height * ratio);

      canvas.width = newWidth;
      canvas.height = newHeight;
      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      // Produce WebP output
      const webpDataURL = canvas.toDataURL("image/webp", quality);
      resolve(webpDataURL);
    };
    img.onerror = reject;
    img.src = dataURL;
  });
}

/**
 * Convert a data URL (base64) to a File object.
 * We'll detect the MIME from dataURL (it will be "image/webp" if we used WebP output).
 */
function dataURLToFile(dataURL, fileName) {
  const arr = dataURL.split(",");
  const mimeMatch = arr[0].match(/:(.*?);/);
  if (!mimeMatch) {
    throw new Error("Invalid data URL");
  }
  const mime = mimeMatch[1]; // "image/webp"
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime });
}

/**
 * Upload an image to Firebase as:
 *  1) A "full" (original) WebP (with max dimension)
 *  2) A thumbnail (WebP) at 160x160
 *
 * If the user provided a File/Blob, it will be converted to data URL, resized, then uploaded.
 * If the user provided a data URL, it's used directly.
 *
 * @param {string} bucket       Firebase storage bucket name
 * @param {string} path         The folder/path within your bucket (should end in "/")
 * @param {File|string} content A File or data URL string
 * @param {string} realmId      Metadata (optional)
 * @param {string} resourceId   Metadata (optional)
 */
export default async function uploadImageWithThumb(bucket, path, content, realmId, resourceId) {
  // Initialize storage
  const storage = getStorage(getApp(), `gs://${bucket}`);

  // We will create two refs:
  //  1) /<path>original.webp
  //  2) /<path>thumb.webp
  const originalRef = ref(storage, `${path}.webp`);
  const thumbRef = ref(storage, `${path}-thumb.webp`);

  // For final filenames
  let originalFileName = "original.webp";
  let thumbFileName = "thumb.webp";

  // If content is a File/Blob, read it as data URL.
  let dataURL;
  if (typeof content === "string") {
    // Already a base64 data URL
    dataURL = content;
  } else {
    // File or Blob -> convert
    dataURL = await readFileAsDataURL(content);
    // We can reuse the original file’s name if you like:
    if (content.name) {
      // Remove file extension, since we'll produce .webp anyway
      originalFileName = content.name.replace(/\.\w+$/, "") + ".webp";
      thumbFileName = originalFileName.replace(".webp", "_thumb.webp");
    }
  }

  // Measure the image
  const { width, height } = await measureImage(dataURL);

  // (1) Create the "full" image WebP (with a max dimension if you want)
  const MAX_WIDTH = 1080;
  const MAX_HEIGHT = 720;
  let fullDataURL;
  if (width > MAX_WIDTH || height > MAX_HEIGHT) {
    // Resize
    fullDataURL = await resizeDataURLToWebp(dataURL, MAX_WIDTH, MAX_HEIGHT);
  } else {
    // Even if smaller, ensure it's WebP
    fullDataURL = await resizeDataURLToWebp(dataURL, width, height);
  }

  // (2) Create the thumbnail (160x160) WebP
  const THUMB_WIDTH = 160;
  const THUMB_HEIGHT = 160;
  const thumbDataURL = await resizeDataURLToWebp(dataURL, THUMB_WIDTH, THUMB_HEIGHT);

  // Convert data URLs back to File (if original was a File/Blob).
  // Otherwise, you could just upload the base64 (using uploadString).
  let finalOriginal;
  let finalThumb;
  if (typeof content === "string") {
    // The original was already base64, so keep them as base64
    finalOriginal = fullDataURL;
    finalThumb = thumbDataURL;
  } else {
    // Convert data URLs to actual File objects
    finalOriginal = dataURLToFile(fullDataURL, originalFileName);
    finalThumb = dataURLToFile(thumbDataURL, thumbFileName);
  }

  // Now upload the "original" WebP
  if (typeof finalOriginal === "string") {
    // Upload as base64
    await uploadString(originalRef, finalOriginal, "data_url", {
      cacheControl: "public,max-age=31536000",
      customMetadata: { realmId, photoId: resourceId },
    });
  } else {
    // Upload as File
    await uploadBytes(originalRef, finalOriginal, {
      cacheControl: "public,max-age=31536000",
      customMetadata: { realmId, photoId: resourceId },
    });
  }

  // Then upload the "thumb" WebP
  if (typeof finalThumb === "string") {
    await uploadString(thumbRef, finalThumb, "data_url", {
      cacheControl: "public,max-age=31536000",
      customMetadata: { realmId, photoId: resourceId },
    });
  } else {
    await uploadBytes(thumbRef, finalThumb, {
      cacheControl: "public,max-age=31536000",
      customMetadata: { realmId, photoId: resourceId },
    });
  }

  // Done. You may return any info you need here, or the storage refs.
  return {
    originalPath: originalRef.fullPath,
    thumbPath: thumbRef.fullPath,
  };
}
