"use client";

import { useClientType, useFeedbackToast } from "@easybiz/component-shared";
import { CLIENT_POS } from "@easybiz/utils";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { useEffect } from "react";
import { useIntl } from "react-intl";

export default function QueryTokenAutoSignIn() {
  const toast = useFeedbackToast();
  const intl = useIntl();
  const client = useClientType();

  useEffect(() => {
    const urlParams = new URLSearchParams(window?.location.search);
    const token = urlParams.get("token");

    if (token) {
      signInWithCustomToken(getAuth(), token)
        .then(() => {
          // Sign in success
          window.location.replace(client === CLIENT_POS ? "/" : window.location.pathname);
        })
        .catch(() => {
          toast.error(intl.formatMessage({ id: "token.expired", defaultMessage: "Token expired" }));
        });
    }
  }, []);
}
