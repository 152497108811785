import { useCheckoutEditItemSetter, useCheckoutItems, useCheckoutService } from "@easybiz/component-shared";
import { BUSINESS_SECTOR_ALTERATION, BUSINESS_SECTOR_BAG_SERVICE, BUSINESS_SECTOR_LAUNDRY } from "@easybiz/utils";
import { Empty, List, Popover, Space } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import ProductActionList from "../ProductActionList";
import ProductCard from "../ProductCard";
import Title from "../Title";

export default ({ products, onAddOther }) => {
  const service = useCheckoutService();
  const items = useCheckoutItems();
  const setEditItem = useCheckoutEditItemSetter();

  if (!Array.isArray(products) || products.length === 0) {
    return (
      <List
        dataSource={[]} loading={!products}
        locale={{ emptyText: <Empty description={<FormattedMessage defaultMessage={"Products not found"} />} /> }}
      />
    );
  } else {
    return (
      <Space className="scroll-body" direction="horizontal" size="small" wrap>
        {products.map(({ key, title, photoId, count, options }) => {
          const select = { productId: key, product: title, ...(photoId && { photoId }) };

          if (
            [BUSINESS_SECTOR_ALTERATION, BUSINESS_SECTOR_BAG_SERVICE].indexOf(service?.sector) >= 0 &&
            items?.find(({ productId }) => productId === key)
          ) {
            return (
              <Popover
                key={key}
                title={
                  <Title>
                    <FormattedMessage defaultMessage={"Select action"} />
                  </Title>
                }
                placement="right"
                trigger={"click"}
                content={
                  <ProductActionList
                    title={title}
                    items={[].concat.apply(
                      [],
                      options.map(({ prices }) => prices || [])
                    )}
                    onAdd={() => setEditItem(select)}
                  />
                }
              >
                <ProductCard title={title} photoId={photoId} count={count} width={76} />
              </Popover>
            );
          } else {
            return (
              <ProductCard
                key={key}
                title={title}
                photoId={photoId}
                count={count}
                onClick={() => setEditItem(select)}
                width={76}
              />
            );
          }
        })}
        {service?.sector === BUSINESS_SECTOR_LAUNDRY && <ProductCard other onClick={() => onAddOther({})} width={76} />}
      </Space>
    );
  }
};
