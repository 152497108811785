import { LoginOutlined } from "@ant-design/icons";
import {
  useAccessShiftManagement,
  useClientRegisterDevice,
  useUser,
  useUserDebugMode,
  useUserRealmId,
} from "@easybiz/component-shared";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import ClockInDialog from "./ClockInDialog";
import ClockOutDialog from "./ClockOutDialog";

export default () => {
  const user = useUser();
  const [checkInVisible, setCheckInVisible] = useState(false);
  const [checkOutVisible, setCheckOutVisible] = useState(false);
  const shiftEnabled = useAccessShiftManagement();
  const realmId = useUserRealmId();
  const device = useClientRegisterDevice();
  const isDebugMode = useUserDebugMode();

  useEffect(() => {
    if (user?.checkInStaff && checkInVisible) {
      setCheckInVisible(false);
    }

    if (!user?.checkInStaff && checkOutVisible) {
      setCheckOutVisible(false);
    }
  }, [user]);

  return (
    <>
      <Button
        size="large"
        icon={<LoginOutlined />}
        type={user?.checkInStaff ? "primary" : "default"}
        ghost={Boolean(user?.checkInStaff)}
        danger={!user?.checkInStaff}
        loading={Boolean(realmId && !device)}
        onClick={() => {
          if (user?.checkInStaff) {
            setCheckOutVisible(true);
          } else {
            setCheckInVisible(true);
          }
        }}
        style={{ maxWidth: 180, overflow: "hidden" }}
      >
        {user?.checkInStaff ? (
          <span>
            <b>{user?.checkInStaff.name}</b>
          </span>
        ) : (
          <FormattedMessage tagName="span" defaultMessage="Clock in" />
        )}
      </Button>
      <ClockInDialog
        open={isDebugMode || user?.checkInStaff || !device ? false : checkInVisible || Boolean(shiftEnabled) || false}
        onCancel={() => setCheckInVisible(false)}
      />
      <ClockOutDialog
        open={!user?.checkInStaff || !device ? false : checkOutVisible}
        onCancel={() => setCheckOutVisible(false)}
      />
    </>
  );
};
