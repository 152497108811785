import { useClientType } from "@easybiz/component-shared";
import { CLIENT_POS } from "@easybiz/utils";
import { ReturnListener } from "@easybiz/component-web-business";
import { Button, Modal, Space } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

export default function ({
  children,
  value,
  okIcon,
  disabled,
  okType,
  confirmLoading,
  cancelButtonProps,
  okButtonProps,
  okDanger,
  okText,
  closable,
  footer,
  returnListener,
  onOk,
  enableReturn,
  maskClosable,
  ...props
}) {
  const client = useClientType();
  const isPOS = client === CLIENT_POS;
  const [okFocused, setOkFocused] = useState(false);

  return (
    <Modal
      closable={closable}
      destroyOnClose
      centered
      maskClosable={confirmLoading ? false : maskClosable}
      {...props}
      bodyStyle={{ padding: "16px 0px" }}
      focusTriggerAfterClose={false}
      footer={
        footer ?? (
          <Space>
            <Button
              style={isPOS ? { minWidth: 150 } : undefined}
              icon={okIcon}
              danger={okDanger}
              type={okType || "primary"}
              {...okButtonProps}
              onClick={onOk}
              loading={confirmLoading}
              onFocus={() => setOkFocused(true)}
              onBlur={() => setOkFocused(false)}
            >
              {okText || <FormattedMessage defaultMessage={"OK"} />}
            </Button>
          </Space>
        )
      }
    >
      {children}
      <ReturnListener disabled={okFocused || !enableReturn} callback={onOk} />
    </Modal>
  );
}
