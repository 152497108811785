import { PlusOutlined } from "@ant-design/icons";
import { useAdminPrinterRegister } from "@easybiz/api-admin";
import {
  useClientPrinterServiceId,
  useFeedbackToast,
  usePrintingBleLabelPrinterSetter,
  usePrintingBleReceiptPrinterSetter,
} from "@easybiz/component-shared";
import { PRINTER_IO_BLUETOOTH, PRINTER_TYPE_LABEL, PRINTER_TYPE_RECEIPT } from "@easybiz/utils";
import { Button } from "antd";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default function ({ printerType }) {
  const toast = useFeedbackToast();
  const intl = useIntl();
  const serviceId = useClientPrinterServiceId();
  const setBleReceiptPrinter = usePrintingBleReceiptPrinterSetter();
  const setBleLabelPrinter = usePrintingBleLabelPrinterSetter();
  const [connecting, setConnecting] = useState(false);
  const { onSubmit, loading } = useAdminPrinterRegister();

  const onRequest = async () => {
    try {
      setConnecting(true);
      const printer = await navigator.bluetooth.requestDevice({
        acceptAllDevices: true,
        optionalServices: [serviceId],
      });

      const server = await printer.gatt.connect();
      const service = await server.getPrimaryService(serviceId);
      const characteristics = await service.getCharacteristics();
      const characteristic = characteristics.find((item) => item.properties.write);

      if (!characteristic) {
        throw new Error(intl.formatMessage({ defaultMessage: "Can not found printer IO" }));
      }

      onSubmit({
        printer,
        printerType,
        io: PRINTER_IO_BLUETOOTH,
      });

      if (printerType === PRINTER_TYPE_RECEIPT) {
        setBleReceiptPrinter(characteristic);
      } else if (printerType === PRINTER_TYPE_LABEL) {
        setBleLabelPrinter(characteristic);
      }
    } catch (error) {
      switch (error.code) {
        case 8:
          toast.info(intl.formatMessage({ defaultMessage: "Printer pairing cancelled" }));
          break;
        default:
          toast.error(error.message);
      }
    } finally {
      setConnecting(false);
    }
  };

  return (
    <Button icon={<PlusOutlined />} onClick={onRequest} loading={connecting || loading}>
      <FormattedMessage tagName="span" defaultMessage="Connect printer" />
    </Button>
  );
}
