"use client";

import { useCustomerProfileSave } from "@easybiz/api-customer";
import { useRealmPOSSettings, useUserBusinessCode } from "@easybiz/component-shared";
import { ACCOUNT_FIELD_REFERRAL_SOURCE } from "@easybiz/utils";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

function useCustomerForm(customer, onSuccess, orderId, customerMode, initProfile) {
  const businessCode = useUserBusinessCode();
  const { accountFields } = useRealmPOSSettings();
  const whereFindUsOptions = accountFields?.find((field) => field.id === ACCOUNT_FIELD_REFERRAL_SOURCE)?.options;
  const [profile, setProfile] = useState(initProfile || null);
  const intl = useIntl();
  const customerDoc = typeof customer?.data === "function" ? customer : null;
  const { onSubmit, loading } = useCustomerProfileSave((succeed, response) => {
    if (succeed) {
      onSuccess && onSuccess(response.customer);
    }
  });

  useEffect(() => {
    if (customerDoc) {
      setProfile(customerDoc.data());
    }
  }, [customerDoc]);

  const onChange = useCallback(
    (newChange) => {
      setProfile((profile) => ({
        ...profile,
        ...newChange,
        ...(typeof newChange?.name === "string" && { name: newChange?.name.slice(0, 100) }),
      }));
    },
    [setProfile]
  );

  return {
    profile,
    onChange,
    loading: loading || typeof customer === "string",
    onSubmit: () =>
      onSubmit(
        {
          ...profile,
          ...(orderId && { orderId }),
          ...(businessCode && { businessCode }),
          customerId: typeof customer === "string" ? customer : customerDoc?.id,
        },
        true
      ),
    ...(Boolean(profile?.name && customerDoc?.get("name") && profile?.name !== customerDoc.get("name")) && {
      nameChangeAlert: customerMode ? (
        <FormattedMessage
          id="you.are.changing.your.account.name.from.x.to.y"
          defaultMessage="You are changing your account name from {oldName} to {newName}"
          values={{
            oldName: customerDoc?.get("name"),
            newName: profile.name,
          }}
        />
      ) : (
        <FormattedMessage
          id="you.are.changing.existing.customer.account.name.from.x.to.y"
          defaultMessage="You are changing EXISTING CUSTOMER account name from {oldName} to {newName}"
          values={{
            oldName: customerDoc?.get("name"),
            newName: profile.name,
          }}
        />
      ),
    }),
    name: {
      label: <FormattedMessage id="customer.name" defaultMessage="Name" description="Customer name" />,
    },
    tel: {
      label: <FormattedMessage id="phone.number" defaultMessage="Tel" description="Telephone number" />,
    },
    email: {
      label: <FormattedMessage id="email" defaultMessage="Email" />,
      placeholder: "example@gamil.com",
      verified: Boolean(customerDoc?.get("verifiedEmail") && customerDoc.get("verifiedEmail") === profile?.email),
    },
    address: {
      label: <FormattedMessage id="address" defaultMessage="Address" />,
    },
    privateNote: customerMode
      ? null
      : {
          label: <FormattedMessage id="private.note" defaultMessage="Private note" />,
          placeholder: intl.formatMessage({
            id: "for.internal.display.only",
            defaultMessage: "For internal display only",
          }),
        },
    birthday: {
      label: <FormattedMessage id="birthday" defaultMessage="Birthday" />,
    },
    whereFindUs:
      !customer && Array.isArray(whereFindUsOptions) && whereFindUsOptions.length > 0
        ? {
            label: <FormattedMessage id="found.us.on" defaultMessage="Found us on" />,
            options: whereFindUsOptions,
          }
        : null,
    registerPlace:
      !customer && !businessCode
        ? {
            label: <FormattedMessage id="register.place" defaultMessage={"Register place"} />,
          }
        : null,
    submitButton: customer ? (
      <FormattedMessage id="save" defaultMessage={"Save"} />
    ) : (
      <FormattedMessage id="add.account" defaultMessage={"Add account"} />
    ),
  };
}

export default useCustomerForm;
