import { useCheckoutBusiness, useCheckoutCustomer, useCheckoutEditOrder, useCheckoutService } from "@easybiz/component-shared";
import { useCustomerPromoCodeCheck } from "@easybiz/api-customer";
import { ReturnListener } from "@easybiz/component-web-business";
import { Button, Input, Space } from "antd";
import React, { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default ({ onApplied }) => {
  const [code, setCode] = useState("");
  const [focused, setFocused] = useState(false);
  const editOrder = useCheckoutEditOrder();
  const businessCode = useCheckoutBusiness();
  const customer = useCheckoutCustomer();
  const service = useCheckoutService();
  const intl = useIntl();

  const { loading, onSubmit } = useCustomerPromoCodeCheck((succeed, response) => {
    if (succeed) {
      if (onApplied) {
        onApplied(response.promoCode);
      }
    }
  });

  const onCheck = useCallback(() => {
    onSubmit({
      customerId: customer.id,
      serviceId: service?.id,
      businessCode,
      code,
      ...(editOrder && { orderId: editOrder.id }),
    });
  }, [customer?.id, service?.id, businessCode, code, editOrder?.id]);

  return (
    <Space>
      <Input
        autoFocus
        value={code}
        placeholder={intl.formatMessage({ defaultMessage: "enter promo code" })}
        onChange={(e) => setCode(e.target.value.toUpperCase())}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      <ReturnListener disabled={!focused} callback={onCheck} />
      <Button loading={loading} type={"primary"} onClick={onCheck} onFailed={() => setCode("")}>
        <FormattedMessage defaultMessage={"Use"} />
      </Button>
    </Space>
  );
};
