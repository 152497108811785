"use client";

import { OWNER_LICENSE_ID } from "@easybiz/lib-shared-auth";
import { createContext, useContext, useMemo, useState } from "react";
import { useRealmContext } from "./RealmContext";
import { useUser } from "./UserContext";

const AccessContext = createContext();

export function AccessProvider({ children, accessReady }) {
  const user = useUser();
  const realm = useRealmContext();
  const [mockUser, setMockUser] = useState();
  const [homeAppPage, setHomeAppPage] = useState();

  const context = useMemo(() => {
    const onlineLicenses = realm?.onlineLicences;
    const storeLicenses = realm?.storeLicences;
    const plantLicense = realm?.plantLicence;
    const { clientAccess, limitBusinesses, procedures, limitAgents, storePlants, specialists, licenseId } =
      mockUser || user || {};
    const businessLimited = Array.isArray(limitBusinesses) && limitBusinesses.length > 0;

    return {
      accessReady,
      licenseId,
      homeAppPage: clientAccess
        ? (clientAccess.appsAndPages || []).some((item) => item.id === homeAppPage?.id)
          ? homeAppPage
          : clientAccess.appsAndPages?.[0] || null
        : undefined,
      appsAndPages: clientAccess?.appsAndPages,
      limitBusinesses,
      limitProcedures: procedures,
      limitAgents,
      storePlants,
      shiftManagement: user?.shiftManagement,
      agentId: Array.isArray(specialists) ? user?.uid : null,
      ...(realm && {
        onlineLicenses: businessLimited
          ? (onlineLicenses || []).filter((code) => limitBusinesses.indexOf(code) >= 0)
          : onlineLicenses || [],
      }),
      ...(realm && {
        storeLicenses: businessLimited
          ? (storeLicenses || []).filter((code) => limitBusinesses.indexOf(code) >= 0)
          : storeLicenses || [],
      }),
      plantLicense,
      mockUser,
      setMockUser,
      setHomeAppPage,
    };
  }, [user, realm, mockUser, homeAppPage, accessReady]);

  return <AccessContext.Provider value={context}>{children}</AccessContext.Provider>;
}

function useAccess(accessMap, defaultGranted, ...keys) {
  const { licenseId } = useContext(AccessContext);
  const isOwner = licenseId === OWNER_LICENSE_ID;

  return keys.map((key) => (isOwner ? {} : accessMap?.[key] || (defaultGranted ? {} : null)));
}

export const useAccessReady = () => useContext(AccessContext).accessReady;
export const useAccessClient = (...scopes) => {
  const user = useUser();
  return useAccess(user?.clientAccess, false, ...scopes);
};
export const useAccessData = (...dataTypes) => {
  const user = useUser();
  // Default have full data access
  return useAccess(user?.dataAccess, true, ...dataTypes);
};
export const useAccessAction = (...actions) => {
  const user = useUser();
  // Default have full action access
  return useAccess(user?.actionAccess, true, ...actions);
};
export const useAccessMain = () => useContext(AccessContext).licenseId === OWNER_LICENSE_ID;
export const useAccessOnlineIds = () => useContext(AccessContext).onlineLicenses;
export const useAccessStoreIds = () => useContext(AccessContext).storeLicenses;
export const useAccessB2B = () => useContext(AccessContext).plantLicense;
export const useAccessLimitBusinessIds = () => useContext(AccessContext).limitBusinesses;
export const useAccessLimitProcedures = () => useContext(AccessContext).limitProcedures;
export const useAccessLimitAgents = () => useContext(AccessContext).limitAgents;
export const useAccessStorePlants = () => useContext(AccessContext).storePlants;
export const useAccessShiftManagement = () => useContext(AccessContext).shiftManagement;
export const useAccessMockUser = () => useContext(AccessContext).mockUser;
export const useAccessMockUserSetter = () => useContext(AccessContext).setMockUser;
export const useAccessHomeAppPage = () => useContext(AccessContext).homeAppPage;
export const useAccessHomeAppPageSetter = () => useContext(AccessContext).setHomeAppPage;
export const useAccessAppsAndPages = () => useContext(AccessContext).appsAndPages;
// TODO: IMPROVE
export const useAccessSelfAgentId = () => useContext(AccessContext).agentId;
