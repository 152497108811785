import { CreditCardFilled } from "@ant-design/icons";
import { useAdminInvoiceSubmit } from "@easybiz/api-admin";
import { INVOICE_STATUS_DRAFT, INVOICE_STATUS_PAID } from "@easybiz/utils";
import { AdminSuspense, PaymentDrawer, SendInvoiceButton } from "@easybiz/web-admin";
import { Button, Checkbox, Input, Space } from "antd";
import React, { lazy, useState } from "react";
import { FormattedMessage } from "react-intl";
import PanelHeader from "./PanelHeader";
import { usePOSIsDayClosed } from "@easybiz/hook";

const InvoicePDFViewer = lazy(() => import("@easybiz/component-web-business/src/InvoicePDF/InvoicePDFViewer"));

export default function ({ invoice, onClose, setDisplayMode }) {
  const [paymentVisible, setPaymentVisible] = useState();
  const [hideDetails, setHideDetails] = useState(false);
  const [email, setEmail] = useState("");
  const dayClosed = usePOSIsDayClosed();
  const drafting = invoice?.status === INVOICE_STATUS_DRAFT;
  const { onSubmit, loading } = useAdminInvoiceSubmit((succeed, response) => {
    if (succeed) {
      setDisplayMode({ invoice: response.invoice });
    }
  });

  return (
    <>
      <PanelHeader
        title={
          drafting ? (
            <FormattedMessage defaultMessage={"Invoice Preview"} />
          ) : (
            <FormattedMessage
              defaultMessage={"Invoice {invoiceNo}"}
              values={{
                invoiceNo: `#${invoice?.invoiceNo}`,
              }}
            />
          )
        }
        onClose={onClose}
      />
      {drafting ? (
        <div className="flex flex-row flex-align-center flex-end padding-half">
          <Checkbox
            checked={hideDetails}
            disabled={loading}
            onChange={(e) => setHideDetails(e.target.checked)}
            style={{ whiteSpace: "nowrap" }}
          >
            <FormattedMessage defaultMessage="Hide order details" />
          </Checkbox>
          <Button
            loading={loading}
            type={"primary"}
            onClick={() => {
              onSubmit({
                hideDetails,
                invoice,
              });
            }}
          >
            <FormattedMessage defaultMessage={"Create invoice"} />
          </Button>
        </div>
      ) : (
        <div className="flex flex-row flex-space-between padding-half">
          <Space>
            <Input
              prefix={<FormattedMessage tagName={"b"} defaultMessage="Send to" />}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <SendInvoiceButton invoice={invoice} hideDetails={hideDetails} sendTo={email} />
          </Space>
          {invoice?.status !== INVOICE_STATUS_PAID && (
            <Button
              type="primary"
              disabled={dayClosed}
              icon={<CreditCardFilled />}
              onClick={() => setPaymentVisible(true)}
            >
              <FormattedMessage tagName="span" defaultMessage="Paid" />
            </Button>
          )}
        </div>
      )}
      {invoice && (
        <div className="flex flex-align-center padding-horizontal-half">
          <AdminSuspense>
            <InvoicePDFViewer
              width={"100%"}
              invoice={invoice}
              hideDetails={hideDetails}
              height={window.innerHeight - 80}
            />
          </AdminSuspense>
        </div>
      )}
      <PaymentDrawer
        fullAmount={invoice?.totalAmount}
        invoiceId={invoice?.id}
        open={paymentVisible}
        onCancel={() => setPaymentVisible(false)}
        onSucceed={(response) => {
          setPaymentVisible(false);
          setDisplayMode({ invoice: response.invoice });
        }}
      />
    </>
  );
}
